import api from '../utils/api';
import { setAlert } from './alert';
import {
  GET_POSTS,
  POST_ERROR,
  UPDATE_POST_LIKES,
  DELETE_POST,
  ADD_POST,
  ADD_PET_POST,
  GET_POST,
  GET_CONTINUED_POSTS,
  CLEAR_POST,
  RESET_POSTS,
  GET_PET_POSTS,
  CLEAR_PET_POSTS,
  UPDATE_POST_VISIBILITY,
} from './types';

/*
  NOTE: we don't need a config object for axios as the
 default headers in axios are already Content-Type: application/json
 also axios stringifies and parses JSON for you, so no need for 
 JSON.stringify or JSON.parse
*/

// Get posts
export const getPosts =
  (type, time = '') =>
  async (dispatch) => {
    try {
      const body = { type: type };
      if (time) {
        body.time = time;
      }
      const res = await api.post('/posts/get-posts', body);
      dispatch({
        type: GET_POSTS,
        payload: res.data,
      });
      return res.data;
    } catch (err) {
      const errors = err?.response?.data?.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }
      dispatch({
        type: POST_ERROR,
        payload: {},
      });
      return [];
    }
  };

export const loadMorePosts =
  (type, time = '') =>
  async (dispatch) => {
    try {
      let res;
      const body = { type: type };
      if (time) {
        body.time = time;
      }
      res = await api.post('/posts/get-posts', body);
      dispatch({
        type: GET_CONTINUED_POSTS,
        payload: { posts: res.data },
      });
      return res.data;
    } catch (err) {
      const errors = err?.response?.data?.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }
      dispatch({
        type: GET_CONTINUED_POSTS,
        payload: { posts: [] },
      });
      return [];
    }
  };

export const loadMoreUserPosts =
  (type, time = '', userId) =>
  async (dispatch) => {
    try {
      let res;
      const body = { type: type, userId: userId };
      if (time) {
        body.time = time;
      }
      res = await api.post('/posts/get-user-posts', body);
      return res.data;
    } catch (err) {
      const errors = err?.response?.data?.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }
      return [];
    }
  };

export const loadMorePetPosts =
  (type, time = '', userId) =>
  async (dispatch) => {
    try {
      let res;
      const body = { type: type, userId: userId };
      if (time) {
        body.time = time;
      }
      res = await api.post('/posts/get-posts', body);

      dispatch({
        payload: { posts: res.data },
        type: GET_PET_POSTS,
      });

      return res.data;
    } catch (err) {
      const errors = err?.response?.data?.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }
      dispatch({
        payload: { posts: [] },
        type: GET_PET_POSTS,
      });
      return [];
    }
  };

export const loadMorePostsGuest =
  (pageNum = 1, pageSize = 12, title = '') =>
  async (dispatch) => {
    try {
      let res;
      if (title === '') {
        res = await api.get('/posts/guest', {
          params: {
            pageNum: pageNum,
            pageSize: pageSize,
            title: title,
          },
        });
      } else {
        res = await api.get('/posts/embedding/search', {
          params: {
            pageNum: pageNum,
            pageSize: pageSize,
            title: title,
          },
        });
      }
      dispatch({
        type: GET_CONTINUED_POSTS,
        payload: { posts: res.data, pageNum: pageNum, pageSize: pageSize, title: title },
      });
    } catch (err) {
      const errors = err?.response?.data?.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }
      dispatch({
        type: POST_ERROR,
        payload: {},
      });
    }
  };
// Get user posts
export const getUserPostsById =
  (pageNum = 1, pageSize = 4, title = '', userId) =>
  async (dispatch) => {
    try {
      const res = await api.get(`/posts/user/${userId}`, {
        params: {
          pageNum: pageNum,
          pageSize: pageSize,
          title: title,
        },
      });
      dispatch({
        type: GET_POSTS,
        payload: res.data,
      });
    } catch (err) {
      const errors = err?.response?.data?.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }
      dispatch({
        type: POST_ERROR,
        payload: {},
      });
    }
  };

// Add like
export const addLike = (id) => async (dispatch) => {
  try {
    const res = await api.post(`/likes/post/${id}`);
    dispatch({
      type: UPDATE_POST_LIKES,
      payload: { id, updateLikes: res.data, isLiked: true },
    });
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: POST_ERROR,
      payload: {},
    });
  }
};

// Remove like
export const removeLike = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/likes/post/${id}`);
    dispatch({
      type: UPDATE_POST_LIKES,
      payload: { id, updateLikes: res.data, isLiked: false },
    });
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: POST_ERROR,
      payload: {},
    });
  }
};

// Delete post
export const deletePost = (postId) => async (dispatch) => {
  try {
    await api.post(`/posts/delete-post`, {
      postId: postId,
    });

    dispatch({
      type: DELETE_POST,
      payload: postId,
    });

    dispatch(setAlert('已删除', 'success'));
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: POST_ERROR,
      payload: {},
    });
    return false;
  }
};

// Add post
export const addPost = (formData) => async (dispatch) => {
  try {
    const res = await api.post('/posts', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch({
      type: ADD_POST,
      payload: res.data,
    });

    dispatch(setAlert('发布成功', 'success'));
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: POST_ERROR,
      payload: {},
    });
    return false;
  }
};

// Add post
export const addPetPost = (formData) => async (dispatch) => {
  try {
    const res = await api.post('/posts/create-post-for-pet', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch({
      type: ADD_PET_POST,
      payload: res.data,
    });

    dispatch(setAlert('发布成功', 'success'));
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: POST_ERROR,
      payload: {},
    });
    return false;
  }
};

// Get post
export const getPost = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/posts/one-post/${id}`);

    dispatch({
      type: GET_POST,
      payload: res.data,
    });
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: POST_ERROR,
      payload: {},
    });
  }
};

// set post on top
export const setPostOnTop = (id) => async (dispatch) => {
  try {
    await api.post(`/posts/set-post-top`, {
      postId: id,
    });
    dispatch(setAlert('置顶成功', 'success'));
    return true;
  } catch (err) {
    dispatch(setAlert('置顶失败', 'danger'));
    return false;
  }
};

// set post visibility
export const setPostVisibility = (id, visible) => async (dispatch) => {
  try {
    const res = await api.post(`/posts/set-post-visible`, {
      postId: id,
      visible: visible,
    });
    dispatch({
      type: GET_POST,
      payload: res.data,
    });
    dispatch({
      type: UPDATE_POST_VISIBILITY,
      payload: { id, visible },
    });
    dispatch(setAlert('修改成功', 'success'));
    return true;
  } catch (err) {
    dispatch(setAlert('修改失败', 'danger'));
    return false;
  }
};

// Edit post
export const editPost = (formData) => async (dispatch) => {
  try {
    const res = await api.post(`/posts/edit-post`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    dispatch({
      type: GET_POST,
      payload: res.data,
    });

    dispatch(setAlert('修改成功', 'success'));
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

//clear post
export const clearPost = () => async (dispatch) => {
  dispatch({
    type: CLEAR_POST,
  });
};

//reset posts
export const resetPosts = () => async (dispatch) => {
  dispatch({
    type: RESET_POSTS,
  });
};

//clear pet posts
export const clearPetPosts = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PET_POSTS,
  });
};
