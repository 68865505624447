import {
  GET_COMMENTS,
  ADD_COMMENT,
  REMOVE_COMMENT,
  ADD_PET_COMMENT,
  REMOVE_PET_COMMENT,
  UPDATE_COMMENT_LIKES,
  GET_SUBCOMMENT,
  ADD_SUB_COMMENT,
  COMMENT_ERROR,
  CLEAR_COMMENTS,
} from '../actions/types';
const initialState = {
  comments: [],
  totalComments: 0,
  loading: true,
  error: {},
};

function commentReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_COMMENTS:
      return {
        ...state,
        comments: payload.resComments,
        totalComments: payload.totalComments,
        loading: false,
        error: {},
      };
    case ADD_COMMENT: {
      const updatedComments = state.comments.slice();

      if (payload.rootId) {
        const existingCommentIndex = updatedComments.findIndex(
          (comment) => comment._id === payload.rootId,
        );
        if (existingCommentIndex !== -1) {
          const updatedComment = { ...updatedComments[existingCommentIndex] };
          if (updatedComment.subcomments) {
            updatedComment.subcomments.push(payload);
            updatedComment.subcommentsNum = updatedComment.subcommentsNum + 1;
          } else {
            updatedComment.subcomments = [payload];
            updatedComment.subcommentsNum = updatedComment.subcommentsNum + 1;
          }
          updatedComments[existingCommentIndex] = updatedComment;
        }
      } else {
        updatedComments.unshift(payload);
      }
      return {
        ...state,
        comments: updatedComments,
        totalComments: state.totalComments + 1,
        loading: false,
      };
    }
    case REMOVE_COMMENT: {
      const updatedComments = state.comments.slice();
      let newComments;
      if (payload.data.rootId) {
        const existingCommentIndex = updatedComments.findIndex(
          (comment) => comment._id === payload.data.rootId,
        );
        if (existingCommentIndex !== -1) {
          const updatedComment = { ...updatedComments[existingCommentIndex] };
          updatedComment.subcomments = updatedComment.subcomments.filter(
            (subcomment) => subcomment._id !== payload.commentId,
          );
          updatedComment.subcommentsNum = updatedComment.subcommentsNum - 1;
          updatedComments[existingCommentIndex] = updatedComment;
        }
        newComments = updatedComments;
      } else {
        newComments = updatedComments.filter((comment) => comment._id !== payload.commentId);
      }
      return {
        ...state,
        comments: newComments,
        totalComments: state.totalComments - 1,
        loading: false,
      };
    }
    case ADD_PET_COMMENT: {
      const updatedComments = state.comments.slice();

      if (payload.rootId) {
        const existingCommentIndex = updatedComments.findIndex(
          (comment) => comment._id === payload.rootId,
        );
        if (existingCommentIndex !== -1) {
          const updatedComment = { ...updatedComments[existingCommentIndex] };
          if (updatedComment.subcomments) {
            updatedComment.subcomments.push(payload);
            updatedComment.subcommentsNum = updatedComment.subcommentsNum + 1;
          } else {
            updatedComment.subcomments = [payload];
            updatedComment.subcommentsNum = updatedComment.subcommentsNum + 1;
          }
          updatedComments[existingCommentIndex] = updatedComment;
        }
      } else {
        updatedComments.unshift(payload);
      }
      return {
        ...state,
        comments: updatedComments,
        totalComments: state.totalComments + 1,
        loading: false,
      };
    }
    case REMOVE_PET_COMMENT: {
      const updatedComments = state.comments.slice();
      let newComments;
      if (payload.data.rootId) {
        const existingCommentIndex = updatedComments.findIndex(
          (comment) => comment._id === payload.data.rootId,
        );
        if (existingCommentIndex !== -1) {
          const updatedComment = { ...updatedComments[existingCommentIndex] };
          updatedComment.subcomments = updatedComment.subcomments.filter(
            (subcomment) => subcomment._id !== payload.commentId,
          );
          updatedComment.subcommentsNum = updatedComment.subcommentsNum - 1;
          updatedComments[existingCommentIndex] = updatedComment;
        }
        newComments = updatedComments;
      } else {
        newComments = updatedComments.filter((comment) => comment._id !== payload.commentId);
      }
      return {
        ...state,
        comments: newComments,
        totalComments: state.totalComments - 1,
        loading: false,
      };
    }
    case UPDATE_COMMENT_LIKES: {
      const updatedComments = state.comments.slice();
      if (payload.rootId !== '') {
        const existingCommentIndex = updatedComments.findIndex(
          (comment) => comment._id === payload.rootId,
        );
        if (existingCommentIndex !== -1) {
          const updatedComment = { ...updatedComments[existingCommentIndex] };
          const subcommentIndex = updatedComment.subcomments.findIndex(
            (subcomment) => subcomment._id === payload.commentId,
          );
          if (subcommentIndex !== -1) {
            const updatedSubcomment = { ...updatedComment.subcomments[subcommentIndex] };
            updatedSubcomment.likesCount += payload.isLiked ? 1 : -1;
            updatedSubcomment.isLiked = payload.isLiked;
            updatedComment.subcomments[subcommentIndex] = updatedSubcomment;
          }
          updatedComments[existingCommentIndex] = updatedComment;
        }
      } else {
        const existingCommentIndex = updatedComments.findIndex(
          (comment) => comment._id === payload.commentId,
        );

        if (existingCommentIndex !== -1) {
          const updatedComment = { ...updatedComments[existingCommentIndex] };
          updatedComment.likesCount += payload.isLiked ? 1 : -1;
          updatedComment.isLiked = payload.isLiked;
          updatedComments[existingCommentIndex] = updatedComment;
        }
      }
      return {
        ...state,
        comments: updatedComments,
        loading: false,
      };
    }
    case GET_SUBCOMMENT: {
      const updatedComments = state.comments.slice();
      const existingCommentIndex = updatedComments.findIndex(
        (comment) => comment._id === payload.commentId,
      );
      if (existingCommentIndex !== -1) {
        const updatedComment = { ...updatedComments[existingCommentIndex] };
        updatedComment.subcomments = payload.subcomments;
        updatedComments[existingCommentIndex] = updatedComment;
      }
      return {
        ...state,
        comments: updatedComments,
        loading: false,
      };
    }
    case ADD_SUB_COMMENT: {
      const updatedComments = state.comments.slice();
      const existingCommentIndex = updatedComments.findIndex(
        (comment) => comment._id === payload.commentId,
      );
      if (existingCommentIndex !== -1) {
        const updatedComment = { ...updatedComments[existingCommentIndex] };
        updatedComment.subcomments = payload.subcomments;
        updatedComments[existingCommentIndex] = updatedComment;
      }
      return {
        ...state,
        comments: updatedComments,
        loading: false,
      };
    }
    case CLEAR_COMMENTS:
      return {
        ...state,
        comments: [],
        totalComments: 0,
        loading: true,
        error: {},
      };
    case COMMENT_ERROR:
      return {
        ...state,
        error: payload,
      };
    default:
      return state;
  }
}

export default commentReducer;
