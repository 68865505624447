import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'antd';

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  max-width: 100%;
  background: #000;
  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const TutorialModal = ({
  forceOpen,
  onOkCallBack,
  countDown = 10,
  title = '',
  text = '',
  videoUrl = '',
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [countdown, setCountdown] = useState(countDown);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const videoRef = useRef(null);
  const countdownRef = useRef(null);

  useEffect(() => {
    if (forceOpen) {
      setIsModalVisible(true);
    }
  }, [forceOpen]);

  useEffect(() => {
    if (isModalVisible) {
      countdownRef.current = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(countdownRef.current);
            setIsButtonDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(countdownRef.current);
    };
  }, [isModalVisible]);

  const handleOk = () => {
    setIsModalVisible(false);

    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
    if (onOkCallBack && typeof onOkCallBack === 'function') {
      onOkCallBack();
    }
  };

  return (
    <Modal
      title={title}
      open={isModalVisible}
      closable={false}
      footer={[
        <Button
          key="ok"
          type="primary"
          onClick={handleOk}
          disabled={isButtonDisabled}
          style={{ width: '100%' }}
        >
          {isButtonDisabled ? `请耐心看完教程哦 (${countdown}s)` : '看完了!'}
        </Button>,
      ]}
      width="100%"
    >
      <p>{text}</p>
      <VideoWrapper>
        <video ref={videoRef} controls>
          <source src={videoUrl} type="video/mp4" />
        </video>
      </VideoWrapper>
    </Modal>
  );
};

export default TutorialModal;
