import React, { useState, useEffect } from 'react';
import MobileInnerNavbar from '../general/navbar/MobileNavbar';
import PetProfiles from './PetProfiles';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import FriendList from '../friend/FriendList';

const ProfilesHome = ({
  friend: { friendsRequest },
  message: { messages, unreadCounts, messageUserpairs },
}) => {
  const [activeLink, setActiveLink] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  let hasUnread = false;
  // unreadCounts是一个对象，键是用户id，值是未读消息数量
  for (const key in unreadCounts) {
    if (unreadCounts[key] > 0) {
      hasUnread = true;
      break;
    }
  }
  useEffect(() => {
    const hash = location.hash;
    if (hash === '') {
      setActiveLink('right');
    } else if (hash === '#right') {
      setActiveLink('right');
    } else {
      setActiveLink('left');
    }
  }, [location]);
  const onClickLeftTab = () => {
    navigate('/pet-profiles#left');
  };
  const onClickRightTab = () => {
    navigate('/pet-profiles#right');
  };
  return (
    <>
      <MobileInnerNavbar
        leftName="崽崽广场"
        rightName="好友消息"
        onClickLeftTab={onClickLeftTab}
        onClickRightTab={onClickRightTab}
        defaultActive={activeLink}
        isRightBadge={friendsRequest?.length > 0 || hasUnread}
      />
      {activeLink === 'left' ? <PetProfiles /> : <FriendList />}
    </>
  );
};

ProfilesHome.propTypes = {
  friend: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  friend: state.friend,
  message: state.message,
});

export default connect(mapStateToProps, {})(ProfilesHome);
