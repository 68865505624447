import React from 'react';

const NotFound = () => {
  return (
    <section className="container">
      <div style={{ width: '90vw' }}>
        <h1 className="x-large text-light">
          <i className="fas fa-exclamation-triangle" /> Page Not Found
        </h1>
        <p className="large">Sorry, this page does not exist</p>
      </div>
    </section>
  );
};

export default NotFound;
