import React from 'react';
import styled from 'styled-components';

const Section = styled.nav`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 1000;
    opacity: 0.9;
    height: 80px; /* Increased height to accommodate title and buttons */
  }
`;

const Container = styled.div`
  width: 90%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  margin-top: 30px;
  font-weight: bold;
  font-size: 24px; /* Adjust the size as needed */
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;
`;

const PageLink = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
  font-size: 18px;
  &:hover {
    color: #333;
  }
`;

const SliderLeft = styled.div`
  width: 50%;
  height: 2px;
  margin-top: 5px;
  background-color: #000;
  transition: transform 0.3s ease-in-out;
`;

const NotificationBubble = styled.span`
  position: absolute;
  top: 2px; // Adjust this value as needed
  right: 30px; // Adjust this value as needed
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5px; // Ensure it's round even if there's only one digit
  height: 5px; // Make it a perfect circle
`;

const MobileInnerNavbar = ({
  pageTitle = '',
  leftName,
  rightName,
  onClickLeftTab,
  onClickRightTab,
  defaultActive,
  isLeftBadge = false,
  isRightBadge = false,
}) => {
  const onClickLeftTabHandler = () => {
    if (onClickLeftTab && typeof onClickLeftTab === 'function') {
      onClickLeftTab();
    }
  };

  const onClickRightTabHandler = () => {
    if (onClickRightTab && typeof onClickRightTab === 'function') {
      onClickRightTab();
    }
  };

  return (
    <Section>
      <Container>
        {pageTitle && <Title>{pageTitle}</Title>}
        {leftName && rightName && (
          <>
            <LinkContainer>
              <PageLink
                onClick={onClickLeftTabHandler}
                style={{
                  color: defaultActive === 'left' ? '#000' : '#999',
                  fontWeight: defaultActive === 'left' ? 'bold' : 'bold',
                }}
              >
                {leftName}
                {isLeftBadge && <NotificationBubble></NotificationBubble>}
              </PageLink>
              <PageLink
                onClick={onClickRightTabHandler}
                style={{
                  color: defaultActive === 'right' ? '#000' : '#999',
                  fontWeight: defaultActive === 'right' ? 'bold' : 'bold',
                }}
              >
                {rightName}
                {isRightBadge && <NotificationBubble></NotificationBubble>}
              </PageLink>
            </LinkContainer>
            <SliderLeft
              style={{
                transform: defaultActive === 'left' ? 'translateX(-50%)' : 'translateX(50%)',
              }}
            />
          </>
        )}
      </Container>
    </Section>
  );
};

export default MobileInnerNavbar;
