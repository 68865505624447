import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useThrottle } from '../../../utils/debounce';

const LikeContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 1.5rem;
  color: #666;
`;

const LikeCount = styled.span`
  margin-left: 8px;
`;

const DefaultLikeIcon = ({ isLiked, fontSize }) => (
  <>
    {isLiked ? (
      <i
        className="fa-solid fa-heart"
        style={{ color: 'var(--primary-color)', fontSize: `${fontSize}rem` }}
      ></i>
    ) : (
      <i className="fa-regular fa-heart" style={{ fontSize: `${fontSize}rem` }}></i>
    )}
  </>
);

const DefaultLikeCount = ({ likeNum, fontSize }) => (
  <LikeCount style={{ fontSize: `${fontSize}rem` }}>{likeNum}</LikeCount>
);

const LikeButton = ({
  isLiked,
  likeNum,
  onLike,
  onUnlike,
  text,
  fontSize = '1.5',
  LikeContainerComponent = LikeContainer,
  LikeIconComponent = DefaultLikeIcon,
  LikeCountComponent = DefaultLikeCount,
}) => {
  const handleClick = useThrottle(() => {
    if (isLiked) {
      onUnlike();
    } else {
      onLike();
    }
  }, 500);

  return (
    <LikeContainerComponent onClick={handleClick}>
      <span>{text}</span>
      <LikeIconComponent isLiked={isLiked} fontSize={fontSize} />
      <LikeCountComponent likeNum={likeNum} fontSize={fontSize} />
    </LikeContainerComponent>
  );
};

LikeButton.propTypes = {
  isLiked: PropTypes.bool.isRequired,
  likeNum: PropTypes.number.isRequired,
  onLike: PropTypes.func.isRequired,
  onUnlike: PropTypes.func.isRequired,
  LikeContainerComponent: PropTypes.elementType,
  LikeIconComponent: PropTypes.elementType,
  LikeCountComponent: PropTypes.elementType,
  fontSize: PropTypes.string,
};

export default LikeButton;
