import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import styled from 'styled-components';
import { getFriends } from '../../actions/friend';
import PetCard from './message/MessagePetCard';
import { Link } from 'react-router-dom';

// A banner for the top of the page, notifying the user that they have friend requests

const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

const Banner = styled(Link)`
  background-color: #f9f9f9;
  width: 70%;
  margin-bottom: 10px;
  border-radius: 10px;
  gap: 10px;
  background-color: #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BackLink = styled.div`
  width: 10px;
  height: 10px;
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  &:hover {
    border-color: grey;
  }
`;

const Profiles = ({ friend: { friends, friendsRequest }, getFriends }) => {
  useEffect(() => {
    getFriends();
  }, [getFriends]);
  useEffect(() => {
    document.title = '好友列表';
    return () => {
      document.title = 'Zaisland';
    };
  }, []);
  return (
    <>
      <section className="container">
        {friendsRequest?.length > 0 && (
          <Banner to="/friend-requests">
            <div style={{ color: 'black' }}>你有{friendsRequest.length}个新的好友请求</div>
            <BackLink />
          </Banner>
        )}
        {friends === null ? (
          <>
            <Spinner />
          </>
        ) : (
          <Fragment>
            <Container>
              {friends?.length > 0 ? (
                friends.map((friend) => {
                  const {
                    friend: { pet },
                  } = friend;
                  return <PetCard key={pet._id} pet={pet} />;
                })
              ) : (
                <></>
              )}
            </Container>
          </Fragment>
        )}
        <div className="my-2"></div>
      </section>
    </>
  );
};

Profiles.propTypes = {
  friend: PropTypes.object.isRequired,
  getFriends: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  friend: state.friend,
});

export default connect(mapStateToProps, { getFriends })(Profiles);
