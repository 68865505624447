import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { getAdventure } from '../../../actions/event';
import styled from 'styled-components';

import FormLoaderSpinner from '../../general/loading-spinner/FormLoaderSpinner';
import Avatar from '../../general/avatar/Avatar';
import MobileHeader from '../../general/navbar/MobileHeader';
import timeSince from '../../../utils/calculateDate';
import AdventureFeedback from './AdventureFeedback';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const EventCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 95%;
  padding: 5px 5px;
  background-color: #fff;
  border-radius: 5px;
  background-color: #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding-bottom: 30px;
`;

const EventCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
  width: 100%;
`;

const EventCardHeaderTitle = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
`;

const EventCardHeaderTitleHighlight = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ff5d33;
  font-size: 1em;
`;

const EventCardHeaderSelfAvtar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`;

const EventCardHeaderDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8em;
  color: #999;
  margin-bottom: 5px;
  margin-right: auto;
  margin-left: 30px;
`;

// 遇到换行要换行
const EventContent = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  color: #333;
  font-size: 0.9em;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
`;

const EventRetrievedCompleteStory = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  font-size: 0.8em;
  padding: 3px 5px;
  border: 1px solid #ddd;
  background-color: #ccc;
  margin-left: 30px;
  margin-bottom: 10px;
`;

const InputBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;

const Tag = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const Profiles = ({ auth, event: { adventure }, getAdventure }) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    document.title = '冒险故事';
    return () => {
      document.title = 'Zaisland';
    };
  }, []);
  useEffect(() => {
    const handleGetAdventureById = async (id) => {
      setIsLoading(true);
      const response = await getAdventure(id);
      if (response) {
        setIsLoading(false);
      }
    };
    handleGetAdventureById(id);
  }, [getAdventure, id]);

  if (!adventure) {
    return (
      <>
        <FormLoaderSpinner loading={isLoading} text={'正在加载最新冒险...'} />
        <MobileHeader backLink={true} pageTitle={'冒险故事'} />
      </>
    );
  }

  const {
    _id,
    user1,
    user2,
    round,
    topic,
    isContinued,
    user1Story,
    user2Story,
    pet1Tag,
    pet2Tag,
    user1Feedback,
    user2Feedback,
    isSuccessful,
    createdAt,
  } = adventure;
  const { pet: pet1 } = user1;
  const { pet: pet2 } = user2;
  const { checkpoint1_type, checkpoint2_type } = topic;
  let checkpoint_type = round === 0 ? checkpoint1_type : checkpoint2_type;
  let isUser1 = auth?.user?._id === user1?._id;
  let userStory = isUser1 ? user1Story : user2Story;
  let userFeedback = isUser1 ? user1Feedback : user2Feedback;
  let pet = isUser1 ? pet1 : pet2;
  let petTag = isUser1 ? pet1Tag : pet2Tag;
  let anotherPet = isUser1 ? pet2 : pet1;
  let isWaitingForFeedback = userFeedback.length < round + 1;
  let isDuringGeneratingStory = userStory.length < round + 1;
  let isStoryEnd = round === 2 && userStory?.length === 3;
  const status =
    !isContinued || isStoryEnd
      ? '冒险已结束'
      : isDuringGeneratingStory
      ? '正在生成故事'
      : isWaitingForFeedback
      ? '是否回应'
      : '已回应';
  const date = userStory?.length > 0 ? userStory[userStory.length - 1].createdAt : createdAt;
  const lastStory =
    userStory?.length > 0 ? userStory[userStory.length - 1]?.content : '还没有故事更新哦~';

  return (
    <>
      <FormLoaderSpinner loading={isLoading} text={'正在更新冒险...'} />
      <MobileHeader
        backLink={true}
        pageTitle={`和${anotherPet?.name.slice(0, 4)}${
          anotherPet?.name.length > 4 ? '...' : ''
        }的冒险`}
      />
      <section className="container">
        <Fragment>
          <Container>
            <EventCardContainer>
              <EventCardHeader>
                <Avatar src={pet?.avatar} size={40} to={`/pet-profile/${pet?._id}`} />
                <EventCardHeaderTitle to={`/adventure-complete/${_id}`}>
                  {`和  `}
                  {''}
                  <EventCardHeaderTitleHighlight>
                    {anotherPet?.name}
                  </EventCardHeaderTitleHighlight>{' '}
                  {` 的冒险 - 第 ${round + 1} 轮`}
                </EventCardHeaderTitle>
                <EventCardHeaderSelfAvtar>
                  <Avatar
                    src={anotherPet?.avatar}
                    size={50}
                    to={`/pet-profile/${anotherPet?._id}`}
                  />
                </EventCardHeaderSelfAvtar>
              </EventCardHeader>
              <EventCardHeaderDate>{timeSince(date)}</EventCardHeaderDate>
              <EventRetrievedCompleteStory to={`/adventure-complete/${_id}`}>
                查看完整冒险
              </EventRetrievedCompleteStory>
              <EventContent to={`/adventure/${_id}`}>{lastStory}</EventContent>
            </EventCardContainer>
            <InputBoxContainer>
              {status === '冒险已结束' ? (
                <>
                  <p>冒险已结束，敬请期待下个冒险哦！</p>
                  {petTag && (
                    <Tag>
                      恭喜！您获得了新的徽章：
                      <EventCardHeaderTitleHighlight>{petTag}</EventCardHeaderTitleHighlight>！
                    </Tag>
                  )}
                  {isSuccessful === false && (
                    <>
                      {pet1Tag === '临阵脱逃' || pet2Tag === '临阵脱逃' ? (
                        <>
                          <Tag>很遗憾，你们因为一方临阵脱逃而冒险失败了</Tag>
                          {pet1Tag === '临阵脱逃' && <Tag>{pet1?.name} 临阵脱逃</Tag>}
                          {pet2Tag === '临阵脱逃' && <Tag>{pet2?.name} 临阵脱逃</Tag>}
                        </>
                      ) : (
                        <>
                          <Tag>很遗憾，你们的暗号不一致哦～</Tag>
                          <Tag>这次冒险因为没有默契失败了，再接再厉哦！</Tag>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : status === '正在生成故事' ? (
                <>今日来信送达中，请耐心等待～</>
              ) : status === '是否回应' ? (
                <AdventureFeedback
                  id={_id}
                  isUser1={isUser1}
                  user1Feedback={user1Feedback[round]}
                  user2Feedback={user2Feedback[round]}
                  setIsLoading={() => {
                    setIsLoading(true);
                  }}
                  setNotLoading={() => {
                    setIsLoading(false);
                  }}
                  feedbackPlaceholder={
                    checkpoint_type === 1
                      ? '给崽崽传送脑电波，输入对崽的指示吧！两只崽崽需要在这个事件中做出共同的决策哦'
                      : '给崽崽传送脑电波，输入对崽的指示吧！两只崽崽可以在这个事件中做出不同的决策哦'
                  }
                />
              ) : (
                <>冒险未完待续，请静候崽崽新的来信哦～</>
              )}
            </InputBoxContainer>
          </Container>
        </Fragment>
        <div className="my-2"></div>
      </section>
    </>
  );
};

Profiles.propTypes = {
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  getAdventure: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  event: state.event,
});

export default connect(mapStateToProps, {
  getAdventure,
})(Profiles);
