import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import styles from '../auth.module.css'; // Ensure this path is correct
import { sendEmailToResetPassword } from '../../../actions/auth';
import icon from '../../../img/logo/logo.png'; // Ensure this path is correct

const ResetPwdEmailSubmission = ({ sendEmailToResetPassword, isAuthenticated }) => {
  const [email, setEmail] = useState('');

  const [isValid, setIsValid] = useState(false);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(re.test(email));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return;
    }
    const emailSendResponse = await sendEmailToResetPassword(email);
    if (!emailSendResponse) {
      return;
    }
    sessionStorage.setItem('registrationStage', 'resetVerify');
    setTimeout(() => {
      window.location.href = `/reset-password-verify-email?email=${encodeURIComponent(
        email,
      )}&step=verify`;
    }, 500);
  };
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }
  return (
    <section className="container">
      <section className={styles.authContainer}>
        <img className={styles.icon} src={icon} alt=""></img>
        <h1 className={styles.largeText}>输入你的邮箱</h1>
        <p className={styles.infoText}>
          <p>
            输入你的邮箱地址，我们会发送一封邮件给你，帮助你重置密码，如果你没有收到邮件，请检查你的垃圾邮件箱。
          </p>
        </p>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <input
              type="email"
              placeholder="邮箱地址"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                validateEmail(e.target.value);
              }}
              className={styles.inputField}
              required
            />
            {/* {error && <p className={styles.errorMessage}>{error}</p>} */}
          </div>
          <div
            className={`${styles.errorMessage} ${
              email && email.length > 0 && !isValid ? styles.visible : ''
            }`}
          >
            邮箱格式不正确
          </div>
          <input
            type="submit"
            className={`${styles.loginButton} ${!isValid ? styles.disabledButton : ''}`}
            value="继续"
            disabled={!isValid}
          />
        </form>
      </section>
    </section>
  );
};
ResetPwdEmailSubmission.propTypes = {
  sendEmailToResetPassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { sendEmailToResetPassword })(ResetPwdEmailSubmission);
