import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MobileHeader from '../general/navbar/MobileHeader';
import styled from 'styled-components';
import { logout } from '../../actions/auth';
import ToggleSubscription from './ToggleSubscription';
import { updateUserInfo } from '../../actions/profile';
import FormLoaderSpinner from '../general/loading-spinner/FormLoaderSpinner';
import AvatarEdit from '../general/avatar/AvatarEdit';
import Avatar from '../general/avatar/Avatar';

const LogoutButton = styled.button`
  margin-top: 20px;
  padding: 10px;
  background-color: #f00;
  border-radius: 5px;
  border: none;
  color: #fff;
  width: 80%;
`;

const SettingContainer = styled.section`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
`;

const EditButton = styled.button`
  width: 100%;
  height: 40px;
  padding: 2px 2px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  gap: 5px;
  color: #fff;
  font-size: 1.5em;
  cursor: pointer;
  background-color: #666;
  z-index: 1;
  span {
    font-size: 0.7em;
  }
`;

const FormWrapper = styled.div`
  position: relative;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const FormGroup = styled.div`
  display: flex;
  align-items: center;
`;

const FormLabel = styled.label`
  width: 50px;
  font-weight: bold;
  margin-right: 10px;
`;

const FormInput = styled.input`
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
`;

const FormTextArea = styled.textarea`
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const UserInfoItem = styled.div`
  display: flex;
  align-items: flex-start;
`;

const UserInfoLabel = styled.strong`
  width: 50px;
  margin-right: 10px;
`;

const UserInfoValue = styled.span`
  flex: 1;
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Setting = ({ user, logout, updateUserInfo }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    biography: user.biography,
    location: user.location,
    ownerDescription: user.ownerDescription,
    contact: user.contact,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const success = await updateUserInfo(formData);
    setLoading(false);
    if (success) {
      setIsEditing(false);
    }
  };

  return (
    <div>
      <MobileHeader pageTitle="设置" backLink={true} />
      <section className="container">
        <SettingContainer>
          {isEditing ? (
            <FormWrapper>
              {loading && <FormLoaderSpinner loading={loading} />}
              <AvatarWrapper>
                <AvatarEdit isUser={true} src={user?.avatar} size={70} />
              </AvatarWrapper>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <FormLabel>简介:</FormLabel>
                  <FormTextArea
                    placeholder="主人自己的简介，不要超过100个字"
                    rows={3}
                    name="biography"
                    value={formData.biography}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>留言:</FormLabel>
                  <FormTextArea
                    placeholder="主人的留言，不要超过100个字"
                    rows={3}
                    name="ownerDescription"
                    value={formData.ownerDescription}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>联系方式:</FormLabel>
                  <FormInput
                    type="text"
                    name="contact"
                    value={formData.contact}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>位置:</FormLabel>
                  <FormInput
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </FormGroup>
                <div className="my"></div>
                <button type="submit" disabled={loading} className="btn btn-primary">
                  提交
                </button>
              </Form>
            </FormWrapper>
          ) : (
            <UserInfo>
              <UserInfoItem>
                <AvatarWrapper>
                  <Avatar src={user.avatar} size={70} to="" alt={''} isUser={true} />
                </AvatarWrapper>
              </UserInfoItem>
              <UserInfoItem>
                <UserInfoLabel>简介:</UserInfoLabel>
                <UserInfoValue>{user.biography}</UserInfoValue>
              </UserInfoItem>
              <UserInfoItem>
                <UserInfoLabel>留言:</UserInfoLabel>
                <UserInfoValue>{user.ownerDescription}</UserInfoValue>
              </UserInfoItem>
              <UserInfoItem>
                <UserInfoLabel>联系方式:</UserInfoLabel>
                <UserInfoValue>{user.contact}</UserInfoValue>
              </UserInfoItem>
              <UserInfoItem>
                <UserInfoLabel>位置:</UserInfoLabel>
                <UserInfoValue>{user.location}</UserInfoValue>
              </UserInfoItem>
            </UserInfo>
          )}
          <div className="my-2"></div>
          <EditButton onClick={() => setIsEditing(!isEditing)}>
            <i className="fa-solid fa-gear"></i> <span>{isEditing ? '取消' : '编辑'}</span>
          </EditButton>
        </SettingContainer>

        <div className="my-2"></div>
        <div className="my-2"></div>
        <ToggleSubscription user={user} />
        <LogoutButton onClick={logout}>退出登录</LogoutButton>
      </section>
    </div>
  );
};

Setting.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout, updateUserInfo })(Setting);
