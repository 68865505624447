import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import timeSince from '../../utils/calculateDate';
import LikeButton from '../general/like/LikeButton';
import CommentButton from '../general/comment/CommentButton';
import {
  addLike,
  removeLike,
  setPostOnTop,
  setPostVisibility,
  deletePost,
} from '../../actions/post';
import FormLoaderSpinner from '../general/loading-spinner/FormLoaderSpinner';
import Avatar from '../general/avatar/Avatar';
import Picture from '../general/picture/Picture';
import { Modal } from 'antd';
import PostformEdit from './PostformEdit';

const Card = styled.div`
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled(Link)`
  font-weight: bold;
  color: black;
  text-decoration: none;
`;

const Time = styled.div`
  color: gray;
  font-size: 0.9rem;
`;

const Content = styled(Link)`
  margin-top: 10px;
  margin-left: 60px;
`;

const ContentText = styled.div`
  margin-top: 10px;
  margin-left: 60px;
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  color: black;
`;

const TitleInnerContainer = styled.div`
  position: relative;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
  color: black;
  left: -30px;
`;

const Text = styled.div`
  color: gray;
`;

const TextInnerContainer = styled.div`
  position: relative;
  left: -30px;
  white-space: pre-wrap;
  color: gray;
`;

const ButtonsContainer = styled.div`
  display: flex;
  width: 40%;
  margin-left: 60px;
  justify-content: space-between;
  margin-top: 10px;
`;

const PictureContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  left: -30px;
`;
// 创建一个省略图片的容器，若图片高度大于200px，则显示200px高度，并且图片以下的一半部分渐变消失
const PictureOutsideContainer = styled.div`
  position: relative;
  display: flex;
  width: 60%;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  margin-top: 10px;
  max-height: 150px;
  overflow: hidden;
`;

const EditButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const EditButton = styled.button`
  background-color: #f4f4f4;
  color: #333;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
`;

const PostCard = ({
  post,
  displayFullText = false,
  isPetPost = false,
  addLike,
  removeLike,
  setPostOnTop,
  setPostVisibility,
  deletePost,
  auth: { user },
}) => {
  const { pet, updatedAt, title, text, likeCount, commentCount, isLiked, type, picture, visible } =
    post;
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const avatar = pet.avatar;
  const postTime = timeSince(updatedAt);

  const handleVisibility = async () => {
    setLoading(true);
    await setPostVisibility(post._id, !visible);
    setLoading(false);
  };

  const handleOnTop = async () => {
    setLoading(true);
    await setPostOnTop(post._id);
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    await deletePost(post._id);
    setLoading(false);
  };

  const handleLike = () => {
    if (isLiked) {
      removeLike(post._id);
    } else {
      addLike(post._id);
    }
  };

  return (
    <>
      <Modal
        title="图片"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <PostformEdit post={post} />
      </Modal>
      <Card>
        {loading && <FormLoaderSpinner loading={loading} text={'正在更新帖子...'} />}
        <Header>
          <Avatar
            src={avatar}
            size={50}
            isUser={type === 'User'}
            alt="avatar"
            to={`/pet-profile/${pet._id}`}
          />
          <div>
            {isPetPost ? (
              <>
                <Name to={`/pet-profile/${pet._id}`}>{`${pet.name}`}</Name>
              </>
            ) : (
              <>
                {' '}
                <Name to={`/pet-profile/${pet._id}`}>{`${pet.name}的主人`}</Name>
              </>
            )}
            <Time>{postTime}</Time>
          </div>
        </Header>
        {/* displayFullText: link else no, isPetPost: pet-post/id, else post/id */}
        {displayFullText ? (
          <>
            <ContentText>
              {!isPetPost && <TitleInnerContainer>{title}</TitleInnerContainer>}
              <TextInnerContainer>{text}</TextInnerContainer>
              {picture && (
                <PictureContainer>
                  <Picture src={picture} />
                </PictureContainer>
              )}
            </ContentText>
          </>
        ) : (
          <>
            <Content to={isPetPost ? `/post/${post._id}` : `/post/${post._id}`}>
              {!isPetPost && <Title>{title}</Title>}
              <Text>{displayFullText ? text : <>{text.slice(0, 50)}...</>}</Text>
              {picture && (
                <PictureOutsideContainer>
                  <Picture src={picture} />
                </PictureOutsideContainer>
              )}
            </Content>
          </>
        )}
        <ButtonsContainer>
          <LikeButton
            likeNum={likeCount}
            fontSize="1"
            onLike={() => {
              handleLike();
            }}
            onUnlike={() => {
              handleLike();
            }}
            isLiked={isLiked}
          />
          <CommentButton CommentNum={commentCount} fontSize="1" iconSize="16" />
        </ButtonsContainer>
        {user?.role === 'admin' && (
          <EditButtonContainer>
            <EditButton onClick={handleVisibility}>{visible ? '隐藏' : '显示'}</EditButton>
            <EditButton onClick={handleDelete}>删除</EditButton>
            <EditButton onClick={handleOnTop}>置顶</EditButton>
            {displayFullText && (
              <>
                <EditButton
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                >
                  编辑
                </EditButton>
              </>
            )}
          </EditButtonContainer>
        )}
      </Card>
    </>
  );
};

PostCard.propTypes = {
  post: PropTypes.object.isRequired,
  displayFullText: PropTypes.bool,
  isPetPost: PropTypes.bool,
  addLike: PropTypes.func,
  removeLike: PropTypes.func,
  auth: PropTypes.object,
  setPostOnTop: PropTypes.func,
  setPostVisibility: PropTypes.func,
  deletePost: PropTypes.func,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  addLike,
  removeLike,
  setPostOnTop,
  setPostVisibility,
  deletePost,
})(PostCard);
