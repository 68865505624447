import React, { useState, useEffect } from 'react';
import MobileInnerNavbar from '../general/navbar/MobileNavbar';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CurrentEvent from './CurrentEvent';
import PastEvent from './PastEvent';
import PropTypes from 'prop-types';

const PostHome = ({ event: { events, passiveEvents } }) => {
  const [activeLink, setActiveLink] = useState('');
  const [isWaitingForFeedback, setIsWaitingForFeedback] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const hash = location.hash;
    if (hash === '') {
      setActiveLink('left');
    } else if (hash === '#right') {
      setActiveLink('right');
    } else {
      setActiveLink('left');
    }
  }, [location]);

  useEffect(() => {
    if (events && events.length > 0) {
      events.forEach((event) => {
        if (event?.isWaitingForResponse === true) {
          setIsWaitingForFeedback(true);
          return;
        }
      });
      if (passiveEvents && passiveEvents.length > 0) {
        passiveEvents.forEach((event) => {
          if (event?.isWaitingForResponse === true) {
            setIsWaitingForFeedback(true);
            return;
          }
        });
      }
    }
  }, [events, passiveEvents]);

  const onClickLeftTab = () => {
    navigate('/stories#left');
  };
  const onClickRightTab = () => {
    navigate('/stories#right');
  };
  return (
    <>
      <MobileInnerNavbar
        leftName="今日故事"
        rightName="历史故事"
        onClickLeftTab={onClickLeftTab}
        onClickRightTab={onClickRightTab}
        defaultActive={activeLink}
        isLeftBadge={isWaitingForFeedback}
      />
      {activeLink === 'right' ? (
        <>
          <PastEvent />
        </>
      ) : (
        <>
          <CurrentEvent />
        </>
      )}
    </>
  );
};

PostHome.propTypes = {
  event: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  event: state.event,
});

export default connect(mapStateToProps, {})(PostHome);
