import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// 样式部分
const Section = styled.nav`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: 1000;
    opacity: 0.9;
    height: 60px; /* 调整高度以适应内容 */
  }
`;

const Container = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* 添加相对定位以便定位返回箭头 */
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 20px; /* 根据需要调整大小 */
  position: absolute; /* 绝对定位标题 */
  left: 50%;
  transform: translateX(-50%); /* 水平居中 */
`;

const BackLink = styled.div`
  position: absolute;
  left: 20px;
  width: 16px;
  height: 16px;
  border: solid black;
  border-width: 0 5px 5px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  &:hover {
    border-color: grey;
  }
`;

// 组件部分
const MobileHeader = ({ pageTitle = '', backLink }) => {
  const navigate = useNavigate();

  const onClickBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <Section>
      <Container>
        {backLink && <BackLink onClick={onClickBack} />}
        {pageTitle && <Title>{pageTitle}</Title>}
      </Container>
    </Section>
  );
};

MobileHeader.propTypes = {
  pageTitle: PropTypes.string,
  backLink: PropTypes.bool,
};

export default MobileHeader;
