import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { getPetById } from '../../actions/pet';
import { connect } from 'react-redux';
import { likePet, unlikePet } from '../../actions/pet';
import PostList from './PostList';
import CommentList from './CommentList';
import Pet from './Pet';
import PetSetting from './PetSetting';
import UserProfile from './UserProfile';
import LikeCommnetSwitch from '../general/toggleButton/LikeCommentSwitch';
import MobileHeader from '../general/navbar/MobileHeader';
import { clearComments } from '../../actions/comment';
import { clearPet } from '../../actions/pet';
import { clearFriend } from '../../actions/friend';
import store from '../../store';

const Profile = ({ getPetById, auth: { user }, pet: { pet, loading }, likePet, unlikePet }) => {
  const { id } = useParams();
  const [showPosts, setShowPosts] = useState(false);

  useEffect(() => {
    getPetById(id);
    return () => {
      store.dispatch(clearComments());
      store.dispatch(clearPet());
      store.dispatch(clearFriend());
    };
  }, [getPetById, id]);

  const handleOnSetPosts = () => {
    setShowPosts(true);
  };

  const handleOnSetComments = () => {
    setShowPosts(false);
  };

  return (
    <>
      <MobileHeader pageTitle="崽崽主页" backLink={true} />
      <section className="container">
        {pet === null ? (
          <Pet pet={pet} />
        ) : (
          <>
            {user && user.role !== 'admin' ? <Pet pet={pet} /> : <PetSetting pet={pet} />}
            <UserProfile user={pet.user} />
            <div className="my"></div>
            <LikeCommnetSwitch
              onSetComments={handleOnSetComments}
              onSetPosts={handleOnSetPosts}
              showPosts={showPosts}
            />
            {showPosts ? (
              <>{pet?.user?._id ? <PostList userId={pet.user._id} /> : <></>}</>
            ) : (
              <>
                <div style={{ width: '95%' }}>
                  <CommentList entityId={pet._id} commentType={'pet'} />
                </div>

                <div className="my-2"></div>
              </>
            )}
          </>
        )}
      </section>
    </>
  );
};

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
  pet: PropTypes.object.isRequired,
  getPetById: PropTypes.func.isRequired,
  likePet: PropTypes.func.isRequired,
  unlikePet: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  pet: state.pet,
  auth: state.auth,
});

export default connect(mapStateToProps, { getPetById, likePet, unlikePet })(Profile);
