import { io } from 'socket.io-client';

const socket = io(process.env.REACT_APP_WEBSOCKET, {
  reconnection: true, // 自动重连
  reconnectionAttempts: Infinity, // 无限次重连尝试
  reconnectionDelay: 1000, // 每次尝试重连的间隔时间
  reconnectionDelayMax: 5000, // 最大重连间隔时间
});

export default socket;
