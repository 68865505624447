import {
  GET_EVENTS,
  GET_PASSIVE_EVENTS,
  GET_LONG_STORY,
  CLEAR_EVENT,
  GET_ADVENTURE,
  GET_SHORT_STORY,
  GET_INTERACTION,
  GET_PAST_EVENTS,
  CLEAR_EVENTS,
} from './types';
import api from '../utils/api';
import { setAlert } from './alert';

// Get events

export const getEvents = (date) => async (dispatch) => {
  try {
    const body = {};
    if (date) {
      body.date = date;
    }
    const res = await api.post('/events/get-events', body);
    dispatch({
      type: GET_EVENTS,
      payload: res.data,
    });
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

export const getEventsContinued = () => async (dispatch) => {
  try {
    const res = await api.post('/events/get-events-continued');
    dispatch({
      type: GET_EVENTS,
      payload: res.data,
    });
  } catch (err) {
    // const errors = err?.response?.data?.errors;
    // if (errors) {
    //   errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    // }
    dispatch({
      type: GET_EVENTS,
      payload: [],
    });
  }
};

export const getPassiveEvents = () => async (dispatch) => {
  try {
    const res = await api.post('/events/get-passive-events-continued');
    dispatch({
      type: GET_PASSIVE_EVENTS,
      payload: res.data,
    });
  } catch (err) {
    // const errors = err?.response?.data?.errors;
    // if (errors) {
    //   errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    // }
  }
};

export const getLongStoryById = (id) => async (dispatch) => {
  try {
    const res = await api.post(`/events/get-long-story-by-id`, {
      longStoryId: id,
    });
    dispatch({
      type: GET_LONG_STORY,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

export const setLongStoryUser1Interest = (longStoryId, interest) => async (dispatch) => {
  try {
    const res = await api.post(`/events/long-story-use1-interest`, {
      longStoryId: longStoryId,
      user1Interest: interest,
    });
    dispatch({
      type: GET_LONG_STORY,
      payload: res.data.longStory,
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, 'success'));
    }
    return res.data.longStory;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

export const setLongstoryIfWaitingForUser2Feedback =
  (longStoryId, isWaitingForUser2Feedback) => async (dispatch) => {
    try {
      const res = await api.post(`/events/long-story-is-waiting-for-user2-feedback`, {
        longStoryId: longStoryId,
        isWaitingForUser2Feedback: isWaitingForUser2Feedback,
      });
      dispatch({
        type: GET_LONG_STORY,
        payload: res.data.longStory,
      });
      if (res.data.msg) {
        dispatch(setAlert(res.data.msg, 'success'));
      }
      return res.data.longStory;
    } catch (err) {
      const errors = err?.response?.data?.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }
    }
  };

export const setLongStoryUser1Feedback = (longStoryId, feedback) => async (dispatch) => {
  try {
    const res = await api.post(`/events/long-story-user1-gave-feedback`, {
      longStoryId: longStoryId,
      user1Feedback: feedback,
    });
    dispatch({
      type: GET_LONG_STORY,
      payload: res.data.longStory,
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, 'success'));
    }
    return res.data.longStory;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

export const setLongStoryUser2Feedback = (longStoryId, feedback) => async (dispatch) => {
  try {
    const res = await api.post(`/events/long-story-user2-gave-feedback`, {
      longStoryId: longStoryId,
      user2Feedback: feedback,
    });
    dispatch({
      type: GET_LONG_STORY,
      payload: res.data.longStory,
    });
    if (res.data.msg) {
      dispatch(setAlert(res.data.msg, 'success'));
    }
    return res.data.longStory;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

export const adventureSignup = () => async (dispatch) => {
  try {
    const res = await api.post('/events/adventure-signup');
    dispatch(setAlert(res.data.msg, 'success'));
    return res.data;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

export const isAdventureSignup = () => async (dispatch) => {
  try {
    const res = await api.post('/events/is-adventure-signup');
    return res.data;
  } catch (err) {
    return false;
  }
};

export const checkAdventureRequest = (receiverId) => async (dispatch) => {
  try {
    const res = await api.post('/events/adventure-check-request', {
      receiverId,
    });
    return res.data;
  } catch (err) {
    return false;
  }
};

export const sendAdventureRequest = (receiverId) => async (dispatch) => {
  try {
    const res = await api.post('/events//adventure-send-request', {
      receiverId,
    });
    dispatch(setAlert(res.data.msg, 'success'));
    return res.data;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

export const acceptAdventureRequest = (requestId) => async (dispatch) => {
  try {
    const res = await api.post('/events/adventure-accept-request', {
      requestId,
    });
    dispatch(setAlert(res.data.msg, 'success'));
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

export const getAdventure = (adventureId) => async (dispatch) => {
  try {
    const res = await api.post('/events/get-adventure-by-id', {
      adventureId,
    });
    dispatch({
      type: GET_ADVENTURE,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

export const updateAdventureUserFeedback =
  (adventureId, userFeedback, userSecret) => async (dispatch) => {
    try {
      const res = await api.post('/events/adventure-user-gave-feedback', {
        adventureId,
        userFeedback,
        userSecret,
      });
      dispatch({
        type: GET_ADVENTURE,
        payload: res.data.adventure,
      });
      dispatch(setAlert(res.data?.msg, 'success'));
      return res.data;
    } catch (err) {
      const errors = err?.response?.data?.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }
      return false;
    }
  };

export const getShortStoryById = (id) => async (dispatch) => {
  try {
    const res = await api.post(`/events/get-short-story-by-id`, {
      shortStoryId: id,
    });
    dispatch({
      type: GET_SHORT_STORY,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

export const setShortStoryRead = (shortStoryId) => async (dispatch) => {
  try {
    const res = await api.post(`/events/short-story-read-by-user`, {
      shortStoryId,
    });
    dispatch({
      type: GET_SHORT_STORY,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

export const isExistInteraction = (user2Id) => async (dispatch) => {
  try {
    const res = await api.post('/events/interaction-is-exist', {
      user2Id,
    });
    return res.data;
  } catch (err) {
    return false;
  }
};

export const generateInteraction = (user2Id, user1Input) => async (dispatch) => {
  try {
    const res = await api.post('/events/interaction-initialize', {
      user2Id,
      user1Input,
    });
    dispatch(setAlert(res.data?.msg, 'success'));
    return res.data;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

export const getInteraction = (id) => async (dispatch) => {
  try {
    const res = await api.post('/events/get-interaction-by-id', {
      interactionId: id,
    });
    dispatch({
      type: GET_INTERACTION,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    return false;
  }
};
export const setInteractionRead = (interactionId) => async (dispatch) => {
  try {
    const res = await api.post('/events/interaction-set-read', {
      interactionId,
    });
    return res.data;
  } catch (err) {
    return false;
  }
};

export const getPastEvents = (date) => async (dispatch) => {
  try {
    const body = {};
    if (date) {
      body.date = date;
    }
    const res = await api.post('/events/get-events', body);
    dispatch({
      type: GET_PAST_EVENTS,
      payload: { date, events: res.data },
    });

    return res.data;
  } catch (err) {
    return false;
  }
};

export const clearEvent = () => (dispatch) => {
  dispatch({ type: CLEAR_EVENT });
};

export const clearEvents = () => (dispatch) => {
  dispatch({ type: CLEAR_EVENTS });
};
