import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getPosts, loadMorePetPosts, clearPetPosts } from '../../actions/post';
import PostCard from './PostCard';
import { useThrottle } from '../../utils/debounce';
import SpinnerObserver from '../general/loading-spinner/SpinnerObserver';
import ClickToLoad from '../general/loading-spinner/ClickToLoad';

const Container = styled.section`
  padding: 0 20px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
`;

const CreatePostButton = styled.button`
  background-color: #fff;
  border: 1px solid #ddd;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 14px;
  color: #555;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: calc(100% - 40px);
  max-width: 600px;
  &:hover {
    background-color: #f9f9f9;
  }
`;

const Profiles = ({
  post: { petPosts, lastPost, petPostsLoading },
  getPosts,
  loadMorePetPosts,
  clearPetPosts,
  auth: { user },
}) => {
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(true);
  useEffect(() => {
    document.title = '崽崽微博';
    return () => {
      document.title = 'Zaisland';
    };
  }, []);
  const handleLoadmorePosts = useThrottle(async () => {
    if (isFetching) return;
    setIsFetching(true);
    if (petPosts && petPosts.length > 0) {
      const res = await loadMorePetPosts('pet', petPosts[petPosts.length - 1].updatedAt);
      if (!res || (res && res.length === 0)) {
        setIsFetchingMore(false);
      }
    } else {
      const res = await loadMorePetPosts('pet');
      if (!res || (res && res.length === 0)) {
        setIsFetchingMore(false);
      }
    }
    setIsFetching(false);
  }, 500);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await clearPetPosts();
    setIsFetchingMore(true);
    setIsRefreshing(false);
  };

  return (
    <>
      <section className="container">
        {petPosts && (
          <Fragment>
            {petPosts.length <= 0 && petPostsLoading ? (
              <SpinnerObserver isFetching={isRefreshing} onIntersect={handleLoadmorePosts} />
            ) : (
              <>
                {petPosts.length <= 0 && !petPostsLoading ? (
                  <>
                    <ClickToLoad
                      onClick={handleRefresh}
                      text="点击刷新"
                      isLoading={isRefreshing}
                      size={0.8}
                    />
                  </>
                ) : (
                  <>
                    <Container>
                      <ClickToLoad
                        onClick={handleRefresh}
                        text="点击刷新"
                        isLoading={isRefreshing}
                        size={0.8}
                      />
                      {petPosts.map((post) => (
                        <PostCard key={post._id} post={post} isPetPost={true} />
                      ))}
                      <div className="my-2"></div>
                      <div className="my-2"></div>
                      {isFetchingMore && (
                        <SpinnerObserver
                          onIntersect={handleLoadmorePosts}
                          isFetching={isFetching}
                        />
                      )}
                    </Container>
                  </>
                )}
              </>
            )}

            <div className="my-2"></div>
            <div className="my-2"></div>
          </Fragment>
        )}
      </section>
      {user && user.role === 'admin' && (
        <ButtonContainer>
          <CreatePostButton onClick={() => navigate('/create-pet-post')}>
            发布崽崽帖子
          </CreatePostButton>
        </ButtonContainer>
      )}
    </>
  );
};

Profiles.propTypes = {
  post: PropTypes.object.isRequired,
  getPosts: PropTypes.func.isRequired,
  loadMorePetPosts: PropTypes.func.isRequired,
  clearPetPosts: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getPosts,
  loadMorePetPosts,
  clearPetPosts,
})(Profiles);
