import React, { Fragment } from 'react';
// import spinner from './spinner.gif';
import styles from './Spinner.module.css';
import PropTypes from 'prop-types';

const Spinner = ({ width = 60 }) => (
  <Fragment>
    {/* <img
      src={spinner}
      style={{ width: '200px', margin: 'auto', display: 'block' }}
      alt="Loading..."
    /> */}
    <div
      style={{ width: `${width}px`, height: `${width}px`, margin: 'auto', display: 'block' }}
      className={styles.loader}
    ></div>
  </Fragment>
);

Spinner.propTypes = {
  width: PropTypes.number,
};

export default Spinner;
