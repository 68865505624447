import React, { useState, useEffect } from 'react';
import MobileInnerNavbar from '../general/navbar/MobileNavbar';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getNotification,
  clearNotification,
  ReadAllNotification,
  regetNotification,
} from '../../actions/notification';
import PropTypes from 'prop-types';
import timeSince from '../../utils/calculateDate';
import styled from 'styled-components';
import Avatar from '../general/avatar/Avatar';
import SpinnerObserver from '../general/loading-spinner/SpinnerObserver';
import { useThrottle } from '../../utils/debounce';

const BorderLine = styled.div`
  width: 80%;
  border-bottom: 1px solid #ddd;
  align-self: center;
  justify-self: center;
  margin-bottom: 10px;
`;

const PassiveEventCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  border-radius: 5px;
  background-color: #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #eee;
  margin-bottom: 10px;
`;

const EventCardHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 3px;
  width: 100%;
  max-width: 100%; /* 确保子元素不超过父容器的宽度 */
  overflow: hidden; /* 隐藏超出部分 */
`;

const EventCardHeaderImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const EventCardHeaderTitle = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 70%;
  justify-content: flex-start;
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
`;

const EventCardHeaderDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8em;
  color: #999;
  margin-bottom: 5px;
  margin-left: auto;
`;

const PostHome = ({
  notification: { notifications, notificationLoading },
  getNotification,
  clearNotification,
  ReadAllNotification,
  regetNotification,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isMore, setIsMore] = useState(true);
  useEffect(() => {
    document.title = '消息中心';
    return () => {
      document.title = 'Zaisland';
    };
  }, []);

  useEffect(() => {
    ReadAllNotification();
    const regeAllNotification = async () => {
      setIsFetching(true);
      const res = await regetNotification();
      if (res && res?.length > 0) {
        setIsMore(true);
      } else {
        setIsMore(false);
      }
      setIsFetching(false);
    };
    regeAllNotification();
  }, [ReadAllNotification, regetNotification]);

  const handleLoadmoreEvents = useThrottle(async () => {
    if (isFetching) return;
    setIsFetching(true);
    if (notifications && notifications.length > 0) {
      let lastDate = notifications[notifications.length - 1].createdAt;
      const date = new Date(lastDate);
      date.setDate(date.getDate() - 1);
      const res = await getNotification(date);
      if (res && res?.length > 0) {
        setIsMore(true);
      } else {
        setIsMore(false);
      }
    } else {
      const now = new Date();
      const res = await getNotification(now);
      if (res && res?.length > 0) {
        setIsMore(true);
      } else {
        setIsMore(false);
      }
    }
    setIsFetching(false);
  }, 500);

  return (
    <>
      <MobileInnerNavbar pageTitle="消息中心" />
      <div className="container">
        <BorderLine />
        {notifications && (
          <>
            {notifications.length <= 0 && notificationLoading ? (
              <></>
            ) : (
              <>
                {notifications.length <= 0 && !notificationLoading ? (
                  <>暂无消息</>
                ) : (
                  <>
                    <div className="my"></div>
                    {notifications.map((notification) => (
                      <PassiveEventCardContainer key={notification._id}>
                        <EventCardHeader>
                          <EventCardHeaderImage>
                            <Avatar
                              size={40}
                              src={notification.avatar}
                              to={`/pet-profile/${notification?.fromUser?.pet?._id}`}
                              alt={''}
                            />
                          </EventCardHeaderImage>

                          <EventCardHeaderTitle
                            to={
                              notification?.type === 'post'
                                ? `/post/${notification?.redirectUrl}`
                                : notification?.type === 'pet'
                                ? `/pet-profile/${notification?.redirectUrl}`
                                : notification?.type === 'friend'
                                ? `/pet-profiles#right`
                                : notification?.type === 'longstory'
                                ? `/longstory/${notification?.redirectUrl}`
                                : notification?.type === 'adventure'
                                ? `/adventure/${notification?.redirectUrl}`
                                : notification?.type === 'event'
                                ? `/stories`
                                : ``
                            }
                          >
                            {notification?.message.slice(0, 40)}
                            {notification?.message.length > 40 ? '...' : ''}
                          </EventCardHeaderTitle>
                          <EventCardHeaderDate>
                            {timeSince(notification.createdAt)}
                          </EventCardHeaderDate>
                        </EventCardHeader>
                      </PassiveEventCardContainer>
                    ))}
                    {isMore && (
                      <>
                        <SpinnerObserver
                          isFetching={isFetching}
                          onIntersect={handleLoadmoreEvents}
                        />
                      </>
                    )}
                    <div className="my-2"></div>
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

PostHome.propTypes = {
  notification: PropTypes.object.isRequired,
  getNotification: PropTypes.func.isRequired,
  clearNotification: PropTypes.func.isRequired,
  ReadAllNotification: PropTypes.func.isRequired,
  regetNotification: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  notification: state.notification,
});

export default connect(mapStateToProps, {
  getNotification,
  clearNotification,
  ReadAllNotification,
  regetNotification,
})(PostHome);
