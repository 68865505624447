import {
  GET_EVENTS,
  GET_LONG_STORY,
  GET_ADVENTURE,
  GET_SHORT_STORY,
  GET_INTERACTION,
  CLEAR_EVENT,
  GET_PASSIVE_EVENTS,
  GET_PAST_EVENTS,
  CLEAR_EVENTS,
} from '../actions/types';

const initialState = {
  events: [],
  pastEvents: [],
  passiveEvents: [],
  event: null,
  adventure: null,
  longStory: null,
  shortStory: null,
  interaction: null,
  loading: true,
  eventsLoading: true,
  pastEventsLoading: true,
  date: new Date(),
};

export default function event(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_EVENTS:
      return {
        ...state,
        events: payload,
        loading: false,
        eventsLoading: false,
      };
    case GET_PASSIVE_EVENTS:
      return {
        ...state,
        passiveEvents: payload,
        loading: false,
      };
    case GET_LONG_STORY:
      return {
        ...state,
        longStory: payload,
        loading: false,
      };
    case GET_ADVENTURE:
      return {
        ...state,
        adventure: payload,
        loading: false,
      };
    case GET_SHORT_STORY:
      return {
        ...state,
        shortStory: payload,
        loading: false,
      };
    case GET_INTERACTION:
      return {
        ...state,
        interaction: payload,
        loading: false,
      };
    case CLEAR_EVENT:
      return {
        ...state,
        event: null,
        longStory: null,
        loading: false,
      };
    case GET_PAST_EVENTS:
      return {
        ...state,
        pastEvents: [...state.pastEvents, ...payload.events],
        loading: false,
        pastEventsLoading: false,
        date: payload.date,
      };
    case CLEAR_EVENTS:
      return {
        ...state,
        pastEvents: [],
        pastEventsLoading: true,
        date: new Date(),
      };
    default:
      return state;
  }
}
