import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { setLongStoryUser2Feedback, getLongStoryById } from '../../../actions/event';
import MobileHeader from '../../general/navbar/MobileHeader';
import FormLoaderSpinner from '../../general/loading-spinner/FormLoaderSpinner';

const EventFeedbackTextArea = styled.textarea`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 300px;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: bold;
  color: #333;
  background-color: #eee;
  margin-top: 10px;
`;

const EventFeedbackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  height: 40px;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: bold;
  color: white;
  background-color: #ff5d33;
  margin-top: 10px;
  width: 95%;
  border: none;
`;

const EventUser2FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  padding: 5px 5px;
  background-color: #fff;
  border-radius: 5px;
  background-color: #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  color: #333;
`;

const EventUser2FeedbackHighlight = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ff5d33;
  font-size: 1em;
`;

const InputBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;

const EventFeedback = ({ setLongStoryUser2Feedback, getLongStoryById, event: { longStory } }) => {
  const [feedback, setFeedback] = useState('');
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getLongStoryById(id);
      setIsLoading(false);
    };
    fetchData();
  }, [getLongStoryById, id]);

  useEffect(() => {
    if (longStory) {
      setIsSubmitting(longStory?.user2Feedback !== null);
    }
  }, [longStory]);

  const handleSubmit = async () => {
    setIsLoading(true);
    const res = await setLongStoryUser2Feedback(id, feedback);
    if (res) {
      setIsLoading(false);
      setIsSubmitting(true);
    }
  };
  return (
    <>
      {longStory && !isSubmitting && (
        <>
          <MobileHeader backLink={true} pageTitle={`回应${longStory?.user1?.pet?.name}的故事`} />

          <FormLoaderSpinner loading={isLoading} text={'正在加载回应...'} />
          <section className="container">
            <InputBoxContainer>
              <p>
                对方用户邀请你一起讨论，希望和你共同影响故事的走向，记得查看并回应相关QQ消息，并对自己的崽崽做出指令回复哦~
              </p>
            </InputBoxContainer>
            <EventFeedbackTextArea
              type="text"
              placeholder="输入对崽崽的回应"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
            />
            <EventFeedbackButton onClick={handleSubmit}>提交回应</EventFeedbackButton>
          </section>
        </>
      )}
      {longStory && isSubmitting && (
        <>
          <MobileHeader backLink={true} pageTitle={`回应${longStory?.user1?.pet?.name}的故事`} />
          <section className="container">
            <EventUser2FeedbackContainer>
              <p>你已经回应过了，期待对方主人的回复吧</p>
              <p>你的回应：</p>
              <EventUser2FeedbackHighlight>{longStory?.user2Feedback}</EventUser2FeedbackHighlight>
            </EventUser2FeedbackContainer>
          </section>
        </>
      )}
    </>
  );
};

EventFeedback.propTypes = {
  event: PropTypes.object.isRequired,
  setLongStoryUser2Feedback: PropTypes.func.isRequired,
  getLongStoryById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  event: state.event,
});

export default connect(mapStateToProps, { setLongStoryUser2Feedback, getLongStoryById })(
  EventFeedback,
);
