import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CommentForm from '../general/comment/CommentForm';
import CommentItem from '../general/comment/CommentItem';
import { getComments } from '../../actions/comment';
import { Pagination } from 'antd';
import Spinner from '../layout/Spinner';

const CommentList = ({
  entityId,
  getComments,
  comment: { comments, totalComments, loading },
  commentType,
}) => {
  const handlePageChange = async (page, pageSize) => {
    if (entityId) {
      getComments(entityId, page, pageSize);
    }
  };
  useEffect(() => {
    if (entityId) {
      getComments(entityId, 1, 10);
    }
  }, [getComments, entityId]);
  return (
    <div style={{ width: '100%' }}>
      {/* 这里可以添加评论列表的内容 */}
      <CommentForm entityId={entityId} commentType={commentType} />
      {loading ? (
        <Spinner />
      ) : (
        <>
          {comments && comments.length > 0 ? (
            comments.map((comment) => (
              <CommentItem
                key={comment._id}
                comment={comment}
                entityId={entityId}
                commentType={commentType}
              />
            ))
          ) : (
            <p>暂无评论</p>
          )}
        </>
      )}

      <div className="m-2">
        <Pagination
          showSizeChanger
          defaultCurrent={1}
          defaultPageSize={10}
          pageSizeOptions={[1, 5, 10, 20]}
          onChange={handlePageChange}
          total={totalComments}
        />
      </div>
    </div>
  );
};

CommentList.propTypes = {
  getComments: PropTypes.func.isRequired,
  type: PropTypes.string,
};
const mapStateToProps = (state) => ({
  comment: state.comment,
});

export default connect(mapStateToProps, { getComments })(CommentList);
