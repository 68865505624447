import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import styled from 'styled-components';
import { getPost, clearPost } from '../../actions/post';
import MobileHeader from '../general/navbar/MobileHeader';
import store from '../../store';
import PostCard from './PostCard';
import CommentList from '../profile/CommentList';
import { clearComments } from '../../actions/comment';

// A banner for the top of the page, notifying the user that they have friend requests

const Container = styled.section`
  padding: 20px;
  width: 100%;
`;

const Profiles = ({ post: { post }, getPost }) => {
  const { id } = useParams();
  useEffect(() => {
    getPost(id);
    return () => {
      store.dispatch(clearPost());
      store.dispatch(clearComments());
    };
  }, [getPost, id]);
  return (
    <>
      <MobileHeader backLink={true} pageTitle={post?.pet?.name} />
      <section className="container">
        {post === null ? (
          <Spinner />
        ) : (
          <Fragment>
            <Container>
              <PostCard
                key={post._id}
                post={post}
                displayFullText={true}
                isPetPost={post.type === 'pet'}
              />
              <CommentList entityId={post._id} commentType={'post'} />
            </Container>
          </Fragment>
        )}
        <div className="my-2"></div>
      </section>
    </>
  );
};

Profiles.propTypes = {
  post: PropTypes.object.isRequired,
  getPost: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
});

export default connect(mapStateToProps, { getPost })(Profiles);
