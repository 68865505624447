import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import styled from 'styled-components';
import { loadMoreUserPosts } from '../../actions/post';
import PostCard from '../posts/PostCard';
import { useThrottle } from '../../utils/debounce';
import SpinnerObserver from '../general/loading-spinner/SpinnerObserver';

const Container = styled.section`
  padding: 0 20px;
  width: 100%;
`;

const Profiles = ({ loadMoreUserPosts, userId }) => {
  const [posts, setPosts] = useState([]);
  const [lastPost, setLastPost] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const handleLoadmorePosts = useThrottle(async () => {
    if (isFetching) return;
    setIsFetching(true);
    let res;
    if (lastPost) {
      res = await loadMoreUserPosts('User', lastPost.createdAt, userId);
    } else {
      res = await loadMoreUserPosts('User', null, userId);
    }
    setPosts([...posts, ...res]);
    setLastPost(res[res.length - 1]);
    setIsFetching(false);
  }, 500);

  return (
    <>
      {posts === null ? (
        <Spinner />
      ) : (
        <Fragment>
          <Container>
            {posts.map((post) => (
              <PostCard key={post._id} post={post} isPetPost={post?.type === 'pet'} />
            ))}
            <div className="my-2"></div>
            <div className="my-2"></div>
            <SpinnerObserver onIntersect={handleLoadmorePosts} isFetching={isFetching} />
          </Container>
          <div className="my-2"></div>
          <div className="my-2"></div>
        </Fragment>
      )}
    </>
  );
};

Profiles.propTypes = {
  loadMoreUserPosts: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
});

export default connect(mapStateToProps, { loadMoreUserPosts })(Profiles);
