import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MobileHeader from '../general/navbar/MobileHeader';
import styled from 'styled-components';
import { logout } from '../../actions/auth';
import { updatePet } from '../../actions/pet';
import FormLoaderSpinner from '../general/loading-spinner/FormLoaderSpinner';
import AvatarEditPet from '../general/avatar/AvatarEditPet';
import Avatar from '../general/avatar/Avatar';

const SettingContainer = styled.section`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
`;

const EditButton = styled.button`
  width: 100%;
  height: 40px;
  padding: 2px 2px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  gap: 5px;
  color: #fff;
  font-size: 1.5em;
  cursor: pointer;
  background-color: #666;
  z-index: 1;
  span {
    font-size: 0.7em;
  }
`;

const FormWrapper = styled.div`
  position: relative;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const FormGroup = styled.div`
  display: flex;
  align-items: center;
`;

// 文字都在一行
const FormLabel = styled.label`
  width: 50px;
  font-weight: bold;
  margin-right: 10px;
`;

const FormInput = styled.input`
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
`;

const FormTextArea = styled.textarea`
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
`;

const UserInfoItem = styled.div`
  display: flex;
  align-items: flex-start;
`;

const UserInfoLabel = styled.strong`
  width: 50px;
  margin-right: 10px;
  white-space: nowrap;
`;

// 遇到换行要换行
const UserInfoValue = styled.span`
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
`;

const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Setting = ({ pet, logout, updatePet }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: pet.name,
    ownerNickname: pet.ownerNickname,
    personality: pet.personality,
    profession: pet.profession,
    avatar: pet.avatar,
    extraInfo: pet.extraInfo,
    description: pet.description,
    mbti: pet.mbti,
    species: pet.species,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    formData.petId = pet._id;
    const success = await updatePet(formData);
    setLoading(false);
    if (success) {
      setIsEditing(false);
    }
  };

  return (
    <div>
      <MobileHeader pageTitle="设置" backLink={true} />
      <section className="container">
        <SettingContainer>
          {isEditing ? (
            <FormWrapper>
              {loading && <FormLoaderSpinner loading={loading} />}
              <AvatarWrapper>
                <AvatarEditPet isUser={false} src={pet?.avatar} size={70} petId={pet._id} />
              </AvatarWrapper>
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <FormLabel>名字:</FormLabel>
                  <FormInput
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>崽崽对你的称呼:</FormLabel>
                  <FormInput
                    type="text"
                    name="ownerNickname"
                    value={formData.ownerNickname}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>物种:</FormLabel>
                  <FormInput
                    type="text"
                    name="species"
                    value={formData.species}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>性格:</FormLabel>
                  <FormInput
                    type="text"
                    name="personality"
                    value={formData.personality}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>职业:</FormLabel>
                  <FormInput
                    type="text"
                    name="profession"
                    value={formData.profession}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>MBTI:</FormLabel>
                  <FormInput
                    type="text"
                    name="mbti"
                    value={formData.mbti}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>额外信息:</FormLabel>
                  <FormTextArea
                    type="text"
                    name="extraInfo"
                    rows={20}
                    value={formData.extraInfo}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </FormGroup>
                <FormGroup>
                  <FormLabel>描述:</FormLabel>
                  <FormTextArea
                    name="description"
                    rows={10}
                    value={formData.description}
                    onChange={handleChange}
                    disabled={loading}
                  />
                </FormGroup>
                <div className="my"></div>
                <button type="submit" disabled={loading} className="btn btn-primary">
                  提交
                </button>
              </Form>
            </FormWrapper>
          ) : (
            <UserInfo>
              <UserInfoItem>
                <AvatarWrapper>
                  <Avatar src={pet.avatar} size={70} to="" alt={''} isUser={false} />
                </AvatarWrapper>
              </UserInfoItem>
              <UserInfoItem>
                <UserInfoLabel>名字:</UserInfoLabel>
                <UserInfoValue>{pet.name}</UserInfoValue>
              </UserInfoItem>
              <UserInfoItem>
                <UserInfoLabel>主人称呼:</UserInfoLabel>
                <UserInfoValue>{pet.ownerNickname}</UserInfoValue>
              </UserInfoItem>
              <UserInfoItem>
                <UserInfoLabel>物种:</UserInfoLabel>
                <UserInfoValue>{pet.species}</UserInfoValue>
              </UserInfoItem>
              <UserInfoItem>
                <UserInfoLabel>性格:</UserInfoLabel>
                <UserInfoValue>{pet.personality}</UserInfoValue>
              </UserInfoItem>
              <UserInfoItem>
                <UserInfoLabel>职业:</UserInfoLabel>
                <UserInfoValue>{pet.profession}</UserInfoValue>
              </UserInfoItem>
              <UserInfoItem>
                <UserInfoLabel>MBTI:</UserInfoLabel>
                <UserInfoValue>{pet.mbti}</UserInfoValue>
              </UserInfoItem>
              <UserInfoItem>
                <UserInfoLabel>额外信息:</UserInfoLabel>
                <UserInfoValue>{pet.extraInfo}</UserInfoValue>
              </UserInfoItem>
              <UserInfoItem>
                <UserInfoLabel>描述:</UserInfoLabel>
                <UserInfoValue>{pet.description}</UserInfoValue>
              </UserInfoItem>
            </UserInfo>
          )}
          <div className="my-2"></div>
          <EditButton onClick={() => setIsEditing(!isEditing)}>
            <i className="fa-solid fa-gear"></i> <span>{isEditing ? '取消' : '编辑'}</span>
          </EditButton>
        </SettingContainer>
      </section>
    </div>
  );
};

Setting.propTypes = {
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  updatePet: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout, updatePet })(Setting);
