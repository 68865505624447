import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { getInteraction, setInteractionRead } from '../../../actions/event';
import styled from 'styled-components';

import FormLoaderSpinner from '../../general/loading-spinner/FormLoaderSpinner';
import Avatar from '../../general/avatar/Avatar';
import MobileHeader from '../../general/navbar/MobileHeader';
import timeSince from '../../../utils/calculateDate';
import InteractionRequestButton from './InteractionRequestButton';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const EventCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 95%;
  padding: 5px 5px;
  background-color: #fff;
  border-radius: 5px;
  background-color: #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding-bottom: 30px;
`;

const EventCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
  width: 100%;
`;

const EventCardHeaderTitle = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
`;

const EventCardHeaderTitleHighlight = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ff5d33;
  font-size: 1em;
`;

const EventCardHeaderDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8em;
  color: #999;
  margin-bottom: 5px;
  margin-right: auto;
  margin-left: 30px;
`;

const EventContent = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  color: #333;
  font-size: 0.9em;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
`;
const InputBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;

const Profiles = ({
  auth: { user },
  event: { interaction },
  getInteraction,
  setInteractionRead,
}) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    document.title = '互动';
    return () => {
      document.title = 'Zaisland';
    };
  }, []);
  useEffect(() => {
    const handleGetAdventureById = async (id) => {
      setIsLoading(true);
      const response = await getInteraction(id);
      await setInteractionRead(id);
      if (response) {
        setIsLoading(false);
      }
    };
    handleGetAdventureById(id);
  }, [setInteractionRead, getInteraction, id]);

  if (!interaction) {
    return (
      <>
        <FormLoaderSpinner loading={isLoading} text={'正在加载最新互动...'} />
        <MobileHeader backLink={true} pageTitle={'互动'} />
      </>
    );
  }

  const { user1, letter1, letter2, createdAt } = interaction;
  const { pet: pet1 } = user1;

  const isUser1 = user1._id === user?._id;
  const pet = pet1;
  const userStory = isUser1 ? letter1 : letter2;

  return (
    <>
      <FormLoaderSpinner loading={isLoading} text={'正在更新最新互动...'} />
      <MobileHeader backLink={true} pageTitle={`当日小互动`} />
      <section className="container">
        <Fragment>
          <Container>
            <EventCardContainer>
              <EventCardHeader>
                <Avatar src={pet?.avatar} size={40} to={`/pet-profile/${pet?._id}`} />
                <EventCardHeaderTitle to={``}>
                  <EventCardHeaderTitleHighlight>
                    {!isUser1 ? pet?.name : '你'}
                  </EventCardHeaderTitleHighlight>{' '}
                  {` 发起的互动`}
                </EventCardHeaderTitle>
              </EventCardHeader>
              <EventCardHeaderDate>{timeSince(createdAt)}</EventCardHeaderDate>

              <EventContent>{userStory}</EventContent>
            </EventCardContainer>
          </Container>
        </Fragment>
        <div className="my-2"></div>
        {!isUser1 && (
          <InputBoxContainer>
            <h4>向对方也发起一个互动吧</h4>
            <div className="my"></div>
            <InteractionRequestButton receiverId={user1._id} />
          </InputBoxContainer>
        )}
      </section>
    </>
  );
};

Profiles.propTypes = {
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  getInteraction: PropTypes.func.isRequired,
  setInteractionRead: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  event: state.event,
});

export default connect(mapStateToProps, {
  setInteractionRead,
  getInteraction,
})(Profiles);
