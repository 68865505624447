import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import Spinner from '../../layout/Spinner';
import icon from '../../../img/logo/logo.png';

// Keyframe animation for the bouncing effect
const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const SpinnerContainer = styled.div`
  display: flex;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
`;

const FormOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  padding-top: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  z-index: 1000;
`;

const FormLoaderContainer = styled.div`
  position: fixed;
  top: 55%;
  display: flex;
  flex-direction: column;
  width: 300px;
  align-items: center;
  transform: translateY(10%);
  gap: 5px;
`;

// place the icon in the center of the screen
const Icon = styled.img`
  position: fixed;
  top: 10vh;
  left: 50%;
  transform: translateX(-50%);
  width: 150px; /* Adjust the width as needed */
  height: auto;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
  img {
    width: 100%;
    height: auto;
  }
`;

const FormLoaderText = styled.div`
  display: flex;
  width: 70%;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #666;
  span {
    display: inline-block;
    animation: ${bounce} 1s infinite;
  }
  // Add delay to each character for the bouncing effect
  span:nth-child(1) {
    animation-delay: 0.1s;
  }
  span:nth-child(2) {
    animation-delay: 0.2s;
  }
  span:nth-child(3) {
    animation-delay: 0.3s;
  }
  span:nth-child(4) {
    animation-delay: 0.4s;
  }
  span:nth-child(5) {
    animation-delay: 0.5s;
  }
  span:nth-child(6) {
    animation-delay: 0.6s;
  }
  span:nth-child(7) {
    animation-delay: 0.7s;
  }
  span:nth-child(8) {
    animation-delay: 0.8s;
  }
  span:nth-child(9) {
    animation-delay: 0.9s;
  }
  span:nth-child(10) {
    animation-delay: 1s;
  }
  // Add more as needed based on the length of the text
`;

const FormLoaderOptionalText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  color: #666;
`;

const FormLoaderSpinner = ({ loading, text, optionalTexts, image }) => {
  const renderTextWithBouncingEffect = (text) => {
    return text.split('').map((char, index) => <span key={index}>{char}</span>);
  };

  return (
    <>
      {loading && (
        <FormOverlay>
          <Icon src={icon} alt="Loader Icon" />
          <SpinnerContainer>
            <Spinner />
          </SpinnerContainer>
          <FormLoaderContainer>
            {text && <FormLoaderText>{renderTextWithBouncingEffect(text)}</FormLoaderText>}
            {optionalTexts &&
              optionalTexts.map((optionalText, index) => (
                <FormLoaderOptionalText key={index}>{optionalText}</FormLoaderOptionalText>
              ))}

            {image && (
              <ImageContainer>
                <img src={image} alt="Loader" />
              </ImageContainer>
            )}
          </FormLoaderContainer>
        </FormOverlay>
      )}
    </>
  );
};

FormLoaderSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  text: PropTypes.string,
  optionalTexts: PropTypes.array,
  image: PropTypes.string,
};

export default FormLoaderSpinner;
