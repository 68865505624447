import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isExistInteraction, generateInteraction } from '../../../actions/event';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useThrottle } from '../../../utils/debounce';
import FormLoaderSpinner from '../../general/loading-spinner/FormLoaderSpinner';
import Spinner from '../../layout/Spinner';

const DefaultRequestContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  width: 100%;
  background-color: #ff5d33;
  color: white;
  height: 40px;
  border-radius: 5px;
  justify-content: center;
`;

const RequestButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  user-select: none;
`;

const RequestTextare = styled.textarea`
  width: 100%;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #ddd;
  padding: 10px;
  margin-top: 20px;
`;

const RequestButton = ({
  RequestContainer = DefaultRequestContainer,
  isExistInteraction,
  generateInteraction,
  receiverId,
}) => {
  const [loading, setLoading] = useState(false);
  const [requestLoading, setRequestLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [code, setCode] = useState(0);
  const [user1Input, setUser1Input] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    const checkRequest = async () => {
      setLoading(true);
      const res = await isExistInteraction(receiverId);
      if (res) {
        setMsg(res?.msg);
        setCode(res?.code);
      }
      setLoading(false);
    };
    checkRequest();
  }, [isExistInteraction, receiverId]);
  const handleClick = useThrottle(async () => {
    setLoading(true);
    setRequestLoading(true);
    const res = await generateInteraction(receiverId, user1Input);
    if (res) {
      setMsg(res?.msg);
      setCode(res?.code);
      setIsModalOpen(false);
    }
    setRequestLoading(false);
    setLoading(false);
  }, 500);
  // according different code to show different message
  return (
    <RequestContainer>
      {loading ? (
        <Spinner width={20} />
      ) : (
        <>
          <Modal
            open={isModalOpen}
            onOk={handleClick}
            onCancel={() => setIsModalOpen(false)}
            okText={'发起互动'}
            cancelText="取消"
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { width: '100%', backgroundColor: 'var(--primary-color)' } }}
          >
            <FormLoaderSpinner loading={requestLoading} text={'正在发起互动哦'} />
            <RequestTextare
              placeholder="请输入你想要发起互动的内容吧，可以是捉弄对方，也可以和对方开个玩笑，不超过100字哦"
              value={user1Input}
              onChange={(e) => setUser1Input(e.target.value)}
            />
          </Modal>
          {code === 0 && (
            <RequestButtonContainer
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              <span>{msg}</span>
            </RequestButtonContainer>
          )}
          {code === 1 && (
            <RequestButtonContainer>
              <span>{msg}</span>
            </RequestButtonContainer>
          )}
          {code === 2 && (
            <RequestButtonContainer>
              <span>{msg}</span>
            </RequestButtonContainer>
          )}
          {code === 3 && (
            <RequestButtonContainer>
              <span>{msg}</span>
            </RequestButtonContainer>
          )}
          {code === 4 && (
            <RequestButtonContainer>
              <span>{msg}</span>
            </RequestButtonContainer>
          )}
          {code === 5 && (
            <RequestButtonContainer>
              <span>不能发起互动</span>
            </RequestButtonContainer>
          )}
          {code === 6 && (
            <RequestButtonContainer>
              <span>{msg}</span>
            </RequestButtonContainer>
          )}
        </>
      )}
    </RequestContainer>
  );
};

RequestButton.propTypes = {
  generateInteraction: PropTypes.func.isRequired,
  isExistInteraction: PropTypes.func.isRequired,
  receiverId: PropTypes.string.isRequired,
};

export default connect(null, {
  generateInteraction,
  isExistInteraction,
})(RequestButton);
