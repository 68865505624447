import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';

const SpinnerObserver = ({ onIntersect, isFetching }) => {
  const spinnerRef = useRef(null);

  useEffect(() => {
    const handleObserver = (entities) => {
      const [entry] = entities;
      if (entry.isIntersecting && !isFetching) {
        onIntersect();
      }
    };
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    });
    const spinnerRefCurrent = spinnerRef.current;
    if (spinnerRefCurrent) {
      observer.observe(spinnerRefCurrent);
    }
    return () => {
      if (spinnerRefCurrent) {
        observer.unobserve(spinnerRefCurrent);
      }
    };
  }, [spinnerRef, onIntersect, isFetching]);

  return (
    <div ref={spinnerRef}>
      <Spinner />
    </div>
  );
};

SpinnerObserver.propTypes = {
  onIntersect: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default SpinnerObserver;
