import React, { useState, useEffect } from 'react';
import { addPet } from '../../actions/pet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MobileInnerNavbar from '../general/navbar/MobileNavbar';
import styled from 'styled-components';
import { Modal } from 'antd';
import qrCode from './qrcode.png';
import FormLoaderSpinner from '../general/loading-spinner/FormLoaderSpinner';
import InstructionTutor from '../../listeners/MessageListener/InstructionTutor';
import QQgroupQRCode from '../../img/QRcode/QQgroupQRcode.jpg';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;

const FormContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
  padding: 20px;
`;

const StyledLabel = styled.label`
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: bold;
`;

const StyledHint = styled.p`
  margin-bottom: 10px;
  font-size: 0.875rem;
  color: #666;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 1rem;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 1rem;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 1rem;
`;

const StyledButton = styled.button`
  padding: 10px;
  margin-top: 10px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff867f;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  img {
    width: 80%;
    height: auto;
  }
`;

const WarningMessage = styled.div`
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  color: #856404;
  text-align: center;
`;

const AddPetForm = ({ addPet, pet: { myPet, loading } }) => {
  const [formData, setFormData] = useState({
    name: '',
    ownerNickname: '',
    species: '',
    profession: '',
    mbtiE: '',
    mbtiN: '',
    mbtiT: '',
    mbtiP: '',
    personality: '',
    referralName: '',
  });

  const {
    name,
    ownerNickname,
    species,
    profession,
    mbtiE,
    mbtiN,
    mbtiT,
    mbtiP,
    personality,
    referralName,
  } = formData;

  const navigate = useNavigate();
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isTutorVisible, setIsTutorVisible] = useState(false);

  useEffect(() => {
    if (isModalVisible) {
      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setIsButtonDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
  }, [isModalVisible]);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const mbti = mbtiE + mbtiN + mbtiT + mbtiP;
      const formDataWithMBTI = { ...formData, mbti };
      const res = await addPet(formDataWithMBTI);

      if (res) {
        setIsModalVisible(true);
      } else {
        setIsLoading(false);
      }
      return;
    } catch (error) {
      console.error('Error submitting the form', error);
    }
  };

  return (
    <>
      <Modal
        title="欢迎加入崽崽岛屿"
        open={isModalVisible}
        closable={false}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => {
          setIsModalVisible(false);
          setIsLoading(false);
          setIsTutorVisible(true);
        }}
        okText={isButtonDisabled ? `确认 (${countdown}s)` : '确认'}
        cancelText="取消"
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { width: '100%' }, disabled: isButtonDisabled }}
      >
        <StyledLabel>
          欢迎成为Zaisland有趣岛民的一员，你的专属崽崽将在24小时内孵化完毕!为开启完整的游戏功能，请加入zaisland
          QQ群 914886823，只有这样才可以找到其他崽崽一起开始冒险哦!
        </StyledLabel>
        <ImageContainer>
          <img src={qrCode} alt="QQ群二维码" />
        </ImageContainer>
      </Modal>
      <InstructionTutor
        forceOpen={isTutorVisible}
        onOkCallBack={() => {
          setIsTutorVisible(false);
          navigate('/dashboard');
        }}
        countDown={15}
        title="欢迎加入崽崽岛屿"
        text="欢迎成为Zaisland有趣岛民的一员，请详细观看下方教程视频，了解如何开始你的崽崽冒险之旅！"
        videoUrl="https://d1ijtmc6ifbh83.cloudfront.net/public/public_video/新手教程.mp4"
      />
      <MobileInnerNavbar pageTitle="来定制化你的崽崽吧！" />
      <Container>
        <FormContainer>
          <WarningMessage>已经注册崽崽请忽视该内容</WarningMessage>
          <StyledForm onSubmit={onSubmit}>
            <StyledLabel>崽崽名字</StyledLabel>
            <StyledHint>给你的崽崽取一个响亮的名字吧，（中文或者英文字母）</StyledHint>
            <StyledInput type="text" name="name" value={name} onChange={onChange} />

            <StyledLabel>崽崽物种</StyledLabel>
            <StyledHint>你希望自己的崽崽是什么动物呢？请填写一个现实中存在的动物物种</StyledHint>
            <StyledInput
              type="text"
              name="species"
              value={species}
              onChange={onChange}
              placeholder="布偶猫、金毛狗、树懒、绵羊......"
            />

            <StyledLabel>崽崽职业</StyledLabel>
            <StyledHint>
              你希望崽崽在ZaisLand中以什么身份存在呢？可以发挥想象为其选择一个职业！
            </StyledHint>
            <StyledInput
              type="text"
              name="profession"
              value={profession}
              onChange={onChange}
              placeholder="流浪厨师、调酒师、彩虹宇航员..."
            />

            <StyledLabel>崽崽MBTI</StyledLabel>
            <StyledSelect name="mbtiE" value={mbtiE} onChange={onChange}>
              <option value="">E/I</option>
              <option value="E">E</option>
              <option value="I">I</option>
            </StyledSelect>
            <StyledSelect name="mbtiN" value={mbtiN} onChange={onChange}>
              <option value="">N/S</option>
              <option value="N">N</option>
              <option value="S">S</option>
            </StyledSelect>
            <StyledSelect name="mbtiT" value={mbtiT} onChange={onChange}>
              <option value="">T/F</option>
              <option value="T">T</option>
              <option value="F">F</option>
            </StyledSelect>
            <StyledSelect name="mbtiP" value={mbtiP} onChange={onChange}>
              <option value="">P/J</option>
              <option value="P">P</option>
              <option value="J">J</option>
            </StyledSelect>

            <StyledLabel>你对专属崽崽的想象是?</StyledLabel>
            <StyledHint>
              除上述信息外，你还想让你的崽崽有什么独特之处，可以是性格、爱好、闪光点等等，发挥想象力！
            </StyledHint>
            <StyledTextArea
              name="personality"
              rows="5"
              value={personality}
              onChange={onChange}
              placeholder="任何想法都可以，可以是平行世界中的你/赛博朋友/你最爱的宠物.…"
            />

            <StyledLabel>崽崽对你的称呼</StyledLabel>
            <StyledInput
              type="text"
              name="ownerNickname"
              value={ownerNickname}
              onChange={onChange}
              placeholder="默认为“主人”"
            />

            <StyledLabel>你的推荐人昵称是?</StyledLabel>
            <StyledHint>
              如果你是通过好友推荐来的，请填写好友的Zaisland用户昵称
              (是用户昵称，不是崽崽昵称哦)，双方都将获得经验值奖励哦!
            </StyledHint>
            <StyledInput
              type="text"
              name="referralName"
              value={referralName}
              onChange={onChange}
              placeholder="正确填写小伙伴的Zaisland用户昵称"
            />
            <WarningMessage>提交之后不可修改，请谨慎填写</WarningMessage>
            <StyledButton type="submit">确认提交</StyledButton>
            <div className="my-2"></div>
          </StyledForm>
        </FormContainer>
        <FormLoaderSpinner
          loading={isLoading}
          optionalTexts={[
            '崽崽正在孵化中！请等待15-20秒左右...',
            '加入QQ群，和其他主人一起了解玩法，开启崽崽岛屿之旅吧！',
          ]}
          image={QQgroupQRCode}
        />
      </Container>
    </>
  );
};

AddPetForm.propTypes = {
  addPet: PropTypes.func.isRequired,
  pet: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  pet: state.pet,
});

export default connect(mapStateToProps, { addPet })(AddPetForm);
