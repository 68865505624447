import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Avatar from '../../general/avatar/Avatar';
import { getConversationId } from '../../../utils/message';

const FriendCardWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border-radius: 10px;
`;

const FriendName = styled(Link)`
  margin: 0;
  font-size: 14px;
  text-decoration: none;
  color: black;
  font-weight: bold;
`;

const MessageAndUnreadContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 95%;
  align-items: flex-end;
  background-color: #eee;
  border-radius: 10px;
  padding: 10px;
`;

const MessageContainer = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  align-items: center;
  text-decoration: none;
  gap: 5px;
`;

const Message = styled.div`
  font-size: 12px;
  color: #666;
`;

const UnreadContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--primary-color);
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
`;

const UnreadCount = styled.div`
  font-size: 12px;
  color: white;
`;

const FriendCard = ({ pet, message: { unreadCounts, messages }, auth }) => {
  let conversationId = getConversationId(auth.user._id, pet.user);
  let unreadCount = unreadCounts[conversationId] || 0;
  let userMessage = messages[conversationId] || [];
  let lastMessage =
    userMessage.length > 0 ? userMessage[userMessage.length - 1].content : '还没有消息';
  return (
    <MessageAndUnreadContainer>
      <FriendCardWrapper>
        <Avatar to={`/message/${pet.user}`} src={pet.avatar} size={45}></Avatar>
        <FriendName to={`/message/${pet.user}`}>{pet.name}</FriendName>
      </FriendCardWrapper>
      <MessageContainer to={`/message/${pet.user}`}>
        <Message>{lastMessage}</Message>
        {unreadCount > 0 && (
          <UnreadContainer>
            <UnreadCount>{unreadCount}</UnreadCount>
          </UnreadContainer>
        )}
      </MessageContainer>
    </MessageAndUnreadContainer>
  );
};

FriendCard.propTypes = {
  pet: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  message: state.message,
  auth: state.auth,
});

export default connect(mapStateToProps)(FriendCard);
