import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { setLongStoryUser1Feedback } from '../../../actions/event';

const EventFeedbackTextArea = styled.textarea`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: bold;
  color: #333;
  background-color: #eee;
  margin-top: 10px;
`;

const EventFeedbackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: bold;
  color: white;
  background-color: #ff5d33;
  margin-top: 10px;
  width: 100%;
  border: none;
`;

const LongstoryFeedback = ({ setLongStoryUser1Feedback, setIsLoading, setNotLoading, id }) => {
  const [feedback, setFeedback] = useState('');

  const handleSubmit = async () => {
    setIsLoading();
    await setLongStoryUser1Feedback(id, feedback);
    setNotLoading();
  };

  const handleChange = (e) => {
    setFeedback(e.target.value);
  };

  return (
    <>
      <EventFeedbackTextArea
        placeholder="和崽崽的旅程暂时告一段落，你有什么想要对崽崽说的话吗？"
        value={feedback}
        onChange={handleChange}
      />
      <EventFeedbackButton onClick={handleSubmit}>提交回信</EventFeedbackButton>
    </>
  );
};

LongstoryFeedback.propTypes = {
  setLongStoryUser1Feedback: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setNotLoading: PropTypes.func.isRequired,
};

export default connect(null, { setLongStoryUser1Feedback })(LongstoryFeedback);
