import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { sendAdventureRequest, checkAdventureRequest } from '../../../actions/event';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useThrottle } from '../../../utils/debounce';
import Spinner from '../../layout/Spinner';

const DefaultRequestContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  justify-content: center;
`;

const RequestButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
`;

const RequestButton = ({
  RequestContainer = DefaultRequestContainer,
  sendAdventureRequest,
  checkAdventureRequest,
  receiverId,
}) => {
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [code, setCode] = useState(0);
  useEffect(() => {
    const checkRequest = async () => {
      setLoading(true);
      const res = await checkAdventureRequest(receiverId);
      if (res) {
        setMsg(res?.msg);
        setCode(res?.code);
      }
      setLoading(false);
    };
    checkRequest();
  }, [checkAdventureRequest, receiverId]);
  const handleClick = useThrottle(async () => {
    setLoading(true);
    const res = await sendAdventureRequest(receiverId);
    if (res) {
      setMsg(res?.msg);
      setCode(res?.code);
    }
    setLoading(false);
  }, 500);
  // according different code to show different message
  return (
    <RequestContainer>
      {loading ? (
        <Spinner width={20} />
      ) : (
        <>
          {code === 0 && (
            <RequestButtonContainer onClick={handleClick}>
              <span>{msg}</span>
            </RequestButtonContainer>
          )}
          {code === 1 && (
            <RequestButtonContainer>
              <span>{msg}</span>
            </RequestButtonContainer>
          )}
          {code === 2 && (
            <RequestButtonContainer>
              <span>{msg}</span>
            </RequestButtonContainer>
          )}
          {code === 3 && (
            <RequestButtonContainer>
              <span>{msg}</span>
            </RequestButtonContainer>
          )}
          {code === 4 && (
            <RequestButtonContainer>
              <span>{msg}</span>
            </RequestButtonContainer>
          )}
          {code === 5 && (
            <RequestButtonContainer>
              <span>今日冒险已达上限</span>
            </RequestButtonContainer>
          )}
          {code === 6 && (
            <RequestButtonContainer>
              <span>用户还未创建宠物</span>
            </RequestButtonContainer>
          )}
        </>
      )}
    </RequestContainer>
  );
};

RequestButton.propTypes = {
  sendAdventureRequest: PropTypes.func.isRequired,
  checkAdventureRequest: PropTypes.func.isRequired,
  receiverId: PropTypes.string.isRequired,
};

export default connect(null, { sendAdventureRequest, checkAdventureRequest })(RequestButton);
