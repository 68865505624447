import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import profile from './profile';
import post from './post';
import pet from './pet';
import notification from './notification';
import follow from './follow';
import comment from './comment';
import friend from './friend';
import event from './event';
import message from './message';

export default combineReducers({
  alert,
  auth,
  profile,
  post,
  pet,
  notification,
  follow,
  comment,
  friend,
  event,
  message,
});
