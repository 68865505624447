import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, Navigate } from 'react-router-dom';
import styled from 'styled-components';
import MobileHeader from '../../general/navbar/MobileHeader';
import { getConversationId } from '../../../utils/message';
import { addMessage, clearUnreadCount, getMessage } from '../../../actions/message';
import Avatar from '../../general/avatar/Avatar';
import ClickToLoad from '../../general/loading-spinner/ClickToLoad';
import socket from '../../../utils/socket';

const MessageTextContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-evenly;
  bottom: 0px;
  width: 100%;
  background: #fff;
  padding: 10px;
  padding-bottom: 20px;
`;

const MessageTextArea = styled.textarea`
  width: 75%;
  resize: none;
  font-size: 1rem;
  justify-content: center;
  justify-items: center;
  border: 2px solid #ccc;
  border-radius: 5px;
`;

const MessageTextButton = styled.button`
  width: 20%;
  background: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
`;

const MessagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 100px;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
`;

const MessageRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${(props) => (props.$isSender ? 'flex-end' : 'flex-start')};
`;
// 自己发的消息靠右，别人发的消息靠左
const Message = styled.div`
  background: ${(props) => (props.$isSender ? 'var(--primary-color)' : '#f4f4f4')};
  color: ${(props) => (props.$isSender ? '#fff' : '#333')};
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  width: 85%;
  word-wrap: break-word;
  word-break: break-all;
  max-width: 85%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  transition: all 0.3s ease;
  &:before {
    content: '';
    display: ${(props) => (props.$isSender ? 'none' : 'block')};
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #f4f4f4;
    left: -10px;
    top: 25px;
    transform: translateY(-50%);
  }
  &:after {
    content: '';
    display: ${(props) => (props.$isSender ? 'block' : 'none')};
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid var(--primary-color);
    right: -10px;
    top: 25px;
    transform: translateY(-50%);
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;

const Profiles = ({
  message: { messages, unreadCounts, messageUserpairs },
  auth,
  addMessage,
  clearUnreadCount,
  getMessage,
}) => {
  const { id } = useParams();
  const [text, setText] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let conversationId = getConversationId(auth.user._id, id);
    clearUnreadCount(conversationId);
    return () => {
      clearUnreadCount(conversationId);
    };
  }, [clearUnreadCount, id, auth.user._id]);

  if (!auth.user) {
    return <Navigate to="/login" />;
  }
  if (!messages || !unreadCounts || !messageUserpairs) {
    return <Navigate to="/pet-profiles#right" />;
  }
  const conversationId = getConversationId(auth.user._id, id);
  const chatMessages = messages[conversationId] || [];
  // const unreadCount = unreadCounts[conversationId] || 0;
  const userpair = messageUserpairs[conversationId] || {};

  const fetchMessages = async () => {
    let conversationId = getConversationId(auth.user._id, id);
    let date = chatMessages[0]?.createdAt || new Date();
    setLoading(true);
    await getMessage(conversationId, date);
    setLoading(false);
  };

  const handleSendMessage = () => {
    if (text.trim() === '') return;
    const newMessage = {
      conversationId,
      content: text,
      senderId: auth.user._id,
      receiverId: id,
      createdAt: new Date(),
      updatedAt: new Date(),
      senderName: auth.user.username,
    };
    socket.emit('privateMessage', newMessage);
    addMessage(newMessage);
    setText('');
  };
  if (!userpair || !chatMessages) {
    return <Navigate to="/pet-profiles#right" />;
  }
  return (
    <>
      <MobileHeader backLink={true} pageTitle={`${userpair?.username}`} />
      <section className="container">
        <MessagesContainer>
          <ClickToLoad isLoading={loading} onClick={fetchMessages} size={0.8} text="加载更多" />
          {chatMessages.map((message) => (
            <MessageRow key={message.createdAt} $isSender={message.senderId === auth.user._id}>
              {message.senderId !== auth.user._id && (
                <AvatarContainer>
                  <Avatar
                    src={userpair?.avatar}
                    alt={userpair?.username}
                    isUser={true}
                    to=""
                    size={40}
                    style={{ position: 'absolute', top: '-40px', left: '10px' }}
                  />
                </AvatarContainer>
              )}
              <Message key={message.createdAt} $isSender={message.senderId === auth.user._id}>
                {message.content}
              </Message>
              {message.senderId === auth.user._id && (
                <AvatarContainer>
                  <Avatar
                    src={auth.user?.avatar}
                    alt={auth.user?.username}
                    isUser={true}
                    size={40}
                    to=""
                    style={{ position: 'absolute', top: '-40px', right: '10px' }}
                  />
                </AvatarContainer>
              )}
            </MessageRow>
          ))}
        </MessagesContainer>
        <MessageTextContainer>
          <MessageTextArea value={text} onChange={(e) => setText(e.target.value)} rows={2} />
          <MessageTextButton
            onClick={() => {
              handleSendMessage();
            }}
          >
            发送
          </MessageTextButton>
        </MessageTextContainer>
        <div className="my-2"></div>
      </section>
    </>
  );
};

Profiles.propTypes = {
  message: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  message: state.message,
  auth: state.auth,
});

export default connect(mapStateToProps, { addMessage, clearUnreadCount, getMessage })(Profiles);
