import React, { useState } from 'react';
import { addPetPost } from '../../actions/post';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import MobileHeader from '../general/navbar/MobileHeader';
import FormLoaderSpinner from '../general/loading-spinner/FormLoaderSpinner';

const Container = styled.section`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
`;

const FormContainer = styled.section`
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  border-radius: 15px;
`;

const StyledLabel = styled.label`
  margin-bottom: 10px;
  font-size: 1rem;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 1rem;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 1rem;
`;

const StyledButton = styled.button`
  width: 100%;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ff867f;
  }
`;

const ImagePreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
`;

const ImagePreview = styled.img`
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
  margin: 10px 0;
`;

const RemoveImageButton = styled.button`
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 10px;
  margin-top: 10px;

  &:hover {
    background-color: darkred;
  }
`;

const PostForm = ({ addPetPost }) => {
  const [formData, setFormData] = useState({
    title: '',
    text: '',
    image: null,
    petId: '',
  });

  const { title, text, image, petId } = formData;

  const navigate = useNavigate();
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const [loading, setLoading] = useState(false);

  const onImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setFormData({ ...formData, image: e.target.files[0] });
    }
  };

  const removeImage = () => {
    setFormData({ ...formData, image: null });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();
    data.append('title', title);
    data.append('text', text);
    data.append('petId', petId);
    if (image) {
      data.append('image', image);
    }

    try {
      setLoading(true);
      const res = await addPetPost(data);
      setLoading(false);
      if (res) {
        navigate(`/#right`);
      }
      return;
    } catch (error) {
      console.error('Error submitting the form', error);
      setLoading(false);
    }
  };

  return (
    <>
      <MobileHeader pageTitle="发布帖子" backLink={true} />
      <Container>
        <FormContainer>
          <StyledForm onSubmit={onSubmit}>
            <StyledLabel>
              宠物ID:
              <StyledInput type="text" name="petId" value={petId} onChange={onChange} />
            </StyledLabel>
            <StyledLabel>
              标题:
              <StyledInput type="text" name="title" value={title} onChange={onChange} />
            </StyledLabel>
            <StyledLabel>
              内容:
              <StyledTextarea
                name="text"
                value={text}
                onChange={onChange}
                placeholder="你想说点什么呢？"
              />
            </StyledLabel>
            <StyledLabel>
              图片:
              <StyledInput type="file" accept="image/*" onChange={onImageChange} />
            </StyledLabel>
            {image && (
              <ImagePreviewContainer>
                <ImagePreview src={URL.createObjectURL(image)} alt="Selected" />
                <RemoveImageButton type="button" onClick={removeImage}>
                  移除图片
                </RemoveImageButton>
              </ImagePreviewContainer>
            )}
            <StyledButton type="submit" className="btn">
              提交
            </StyledButton>
          </StyledForm>
        </FormContainer>
        <div className="my-2"></div>
        <div className="my-2"></div>
        <FormLoaderSpinner loading={loading} text={'正在发布帖子...'} />
      </Container>
    </>
  );
};

PostForm.propTypes = {
  addPetPost: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { addPetPost })(PostForm);
