import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Spinner from '../../layout/Spinner';

const ClickContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  font-size: ${({ $size }) => $size || 1.5}rem;
  color: #999;
`;

const ClickToLoad = ({ onClick, text, isLoading, size }) => {
  return (
    <>
      <ClickContainer onClick={onClick} $size={size}>
        {!isLoading && text}
        {isLoading && <Spinner width={30} />}
      </ClickContainer>
    </>
  );
};

ClickToLoad.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  size: PropTypes.number,
};

export default ClickToLoad;
