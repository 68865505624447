import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import timeSince from '../../utils/calculateDate';
import { acceptAdventureRequest } from '../../actions/event';
import { useThrottle } from '../../utils/debounce';
import Avatar from '../general/avatar/Avatar';
import Survey from '../../components/survey/Survey';

const EventCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 95%;
  padding: 5px 5px;
  background-color: #fff;
  border-radius: 5px;
  background-color: #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
`;

const PassiveEventCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 95%;
  padding: 5px 5px;
  border-radius: 5px;
  background-color: #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  margin-bottom: 10px;
`;

const PassiveEventCardButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 50vh;
  color: ${(props) => (props.$isNegative ? '#ff5d33' : 'white')};
  background-color: ${(props) => (props.$isNegative ? 'white' : '#ff5d33')};
  font-size: 0.8em;
  margin-left: auto;
  border: 1px solid #ff5d33;
`;

const EventCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
  width: 100%;
`;

const EventCardHeaderTitle = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
`;

const EventCardHeaderTitleHighlight = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ff5d33;
  font-size: 1em;
`;

const EventCardHeaderStatus = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 10px;
  border-radius: 50vh;
  color: white;
  font-size: 0.7em;
  background-color: ${(props) => (props.$isWaitingForResponse ? '#ff5d33' : '#999')};
  margin-bottom: 5px;
`;

const EventCardHeaderDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8em;
  color: #999;
  margin-bottom: 5px;
  margin-left: auto;
`;

const EventContent = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  color: #999;
  font-size: 0.9em;
`;

const LongStoryComponent = ({ event }) => {
  const { _id, user1, round, isWaitingForResponse, isContinued, userStory, createdAt } = event;
  const { pet: pet1 } = user1;
  const status = !isContinued
    ? '崽崽写信中~'
    : isWaitingForResponse
    ? '等你回信'
    : userStory?.length === 0
    ? '崽崽写信中~'
    : userStory?.length < round + 1
    ? '崽崽回信中'
    : round === 1 || userStory?.length === 2
    ? '崽崽回信中'
    : '崽崽回信中';

  const date = userStory?.length > 0 ? userStory[userStory.length - 1].createdAt : createdAt;
  // 只显示故事的前20个字符
  const lastStory =
    userStory?.length > 0
      ? userStory[userStory.length - 1]?.content?.slice(0, 30) + '...'
      : '还没有信件更新哦～';
  return (
    <>
      <EventCardHeader>
        <Avatar src={pet1?.avatar} size={40} to={`/pet-profile/${pet1?._id}`} />
        <EventCardHeaderTitle to={`/longstory/${_id}`}>
          <EventCardHeaderTitleHighlight>
            {pet1?.name.slice(0, 6) + (pet1?.name.length > 6 ? '...' : '')}
          </EventCardHeaderTitleHighlight>{' '}
          {` 的告别信 `}
        </EventCardHeaderTitle>
        <EventCardHeaderStatus
          $isWaitingForResponse={isWaitingForResponse}
          to={`/longstory/${_id}`}
        >
          {status}
        </EventCardHeaderStatus>
        <EventCardHeaderDate>{timeSince(date)}</EventCardHeaderDate>
      </EventCardHeader>
      <EventContent to={`/longstory/${_id}`}>
        <div>{lastStory}</div>
      </EventContent>
    </>
  );
};

const LongStoryPassiveComponent = ({ event }) => {
  const { _id, user1, user2Feedback } = event;
  const { pet: pet1 } = user1;
  const navigate = useNavigate();
  // 只显示故事的前20个字符

  return (
    <>
      <EventCardHeader>
        <Avatar src={pet1?.avatar} size={40} to={`/pet-profile/${pet1?._id}`} />
        <EventCardHeaderTitle to={`/longstory-passive-user-feedback/${_id}`}>
          <EventCardHeaderTitleHighlight>
            {pet1?.name.slice(0, 7) + (pet1?.name.length > 7 ? '...' : '')}
          </EventCardHeaderTitleHighlight>{' '}
          {` 的主人邀请你一起讨论 `}
        </EventCardHeaderTitle>
        <PassiveEventCardButton
          $isNegative={user2Feedback !== null}
          onClick={() => {
            navigate(`/longstory-passive-user-feedback/${_id}`);
          }}
        >
          {user2Feedback ? '查看回应' : '去回应'}
        </PassiveEventCardButton>
      </EventCardHeader>
    </>
  );
};

const AdventureComponent = ({ event, auth: { user } }) => {
  const {
    _id,
    user1,
    user2,
    round,
    isWaitingForResponse,
    isContinued,
    user1Story,
    user2Story,
    createdAt,
  } = event;
  const isUser1 = user1._id === user?._id;
  const userStory = isUser1 ? user1Story : user2Story;
  const { pet: pet1 } = user1;
  const { pet: pet2 } = user2;
  const status = !isContinued
    ? '已结束'
    : isWaitingForResponse
    ? round === 2
      ? '查看结局'
      : '等你回信'
    : userStory?.length === 0
    ? '冒险还未开始哦~'
    : userStory?.length === 3 && round === 2
    ? '查看结局'
    : '崽崽回信中';

  const date = userStory?.length > 0 ? userStory[userStory.length - 1].createdAt : createdAt;

  // 只显示故事的前20个字符
  const lastStory =
    userStory?.length > 0
      ? userStory[userStory.length - 1]?.content?.slice(0, 30) + '...'
      : '还没有故事更新哦~';
  const pet = isUser1 ? pet2 : pet1;
  return (
    <>
      <EventCardHeader>
        <Avatar src={pet?.avatar} size={40} to={`/pet-profile/${pet?._id}`} />
        <EventCardHeaderTitle to={`/adventure/${_id}?userId=${user._id}`}>
          {`和  `}
          {''}
          <EventCardHeaderTitleHighlight>
            {pet?.name.slice(0, 7) + (pet?.name.length > 7 ? '...' : '')}
          </EventCardHeaderTitleHighlight>{' '}
          {` 的冒险 `}
        </EventCardHeaderTitle>
        <EventCardHeaderStatus
          $isWaitingForResponse={isWaitingForResponse}
          to={`/adventure/${_id}?userId=${user._id}`}
        >
          {status}
        </EventCardHeaderStatus>
        <EventCardHeaderDate>{timeSince(date)}</EventCardHeaderDate>
      </EventCardHeader>
      <EventContent to={`/adventure/${_id}?userId=${user._id}`}>
        <div>{lastStory}</div>
      </EventContent>
    </>
  );
};

AdventureComponent.propTypes = {
  auth: PropTypes.object.isRequired,
};

const adventureMapToProp = (state) => ({
  auth: state.auth,
});

const AdventureComponentWithConnect = connect(adventureMapToProp)(AdventureComponent);

const AdventureSignupComponent = ({ event }) => {
  const navigate = useNavigate();
  const { isWaitingForResponse } = event;
  return (
    <>
      <EventCardHeader>
        <EventCardHeaderTitle to={'/adventure-signup'}>
          明日的冒险开始报名啦！！！
        </EventCardHeaderTitle>
        <PassiveEventCardButton
          $isNegative={!isWaitingForResponse}
          onClick={() => {
            navigate(`/adventure-signup`);
          }}
        >
          {isWaitingForResponse ? '参与冒险' : '查看冒险详情'}
        </PassiveEventCardButton>
      </EventCardHeader>
    </>
  );
};

const AdventureRequestComponent = ({ event, acceptAdventureRequest }) => {
  const { isWaitingForResponse, requester } = event;
  const { pet } = requester;
  const [loading, setLoading] = useState(false);
  const [isAccepted, setIsAccepted] = useState(!isWaitingForResponse);
  const handleClickOnAccept = useThrottle(async () => {
    setLoading(true);
    const res = await acceptAdventureRequest(event._id);
    if (res) {
      setIsAccepted(true);
    }
    setLoading(false);
  }, 1000);
  return (
    <EventCardHeader>
      <EventCardHeaderTitle to={''}>
        {`${pet?.name.slice(0, 7) + (pet?.name.length > 7 ? '...' : '')} 邀请你一起冒险`}
      </EventCardHeaderTitle>
      <PassiveEventCardButton
        $isNegative={isAccepted}
        onClick={() => {
          handleClickOnAccept();
        }}
      >
        {loading ? '处理中...' : isAccepted ? '已接受' : '接受邀请'}
      </PassiveEventCardButton>
    </EventCardHeader>
  );
};

AdventureRequestComponent.propTypes = {
  acceptAdventureRequest: PropTypes.func.isRequired,
};

const AdventureRequestComponentWithConnect = connect(null, { acceptAdventureRequest })(
  AdventureRequestComponent,
);

const ShortStoryComponent = ({ event }) => {
  const { _id, user, isWaitingForResponse, isRead, content, createdAt } = event;
  const { pet } = user;

  const status = isRead ? '已读' : '未读';

  return (
    <>
      <EventCardHeader>
        <Avatar src={pet?.avatar} size={40} to={`/pet-profile/${pet?._id}`} />
        <EventCardHeaderTitle to={`/short-story/${_id}`}>
          <EventCardHeaderTitleHighlight>
            {pet?.name.slice(0, 7) + (pet?.name.length > 7 ? '...' : '')}
          </EventCardHeaderTitleHighlight>{' '}
          {` 的当日小故事 `}
        </EventCardHeaderTitle>
        <EventCardHeaderStatus
          $isWaitingForResponse={isWaitingForResponse}
          to={`/short-story/${_id}`}
        >
          {status}
        </EventCardHeaderStatus>
        <EventCardHeaderDate>{timeSince(createdAt)}</EventCardHeaderDate>
      </EventCardHeader>
      <EventContent to={`/short-story/${_id}`}>
        <div>{content?.slice(0, 30) + '...'}</div>
      </EventContent>
    </>
  );
};

const InteractionComponent = ({ event, auth: { user } }) => {
  const { _id, user1, isWaitingForResponse, letter1, letter2, createdAt } = event;
  const { pet: pet1 } = user1;

  const isUser1 = user1._id === user?._id;
  const pet = pet1;
  const userStory = isUser1 ? letter1 : letter2;
  const status = isWaitingForResponse ? '未读' : '已读';
  return (
    <>
      <EventCardHeader>
        <Avatar src={pet?.avatar} size={40} to={`/pet-profile/${pet?._id}`} />
        <EventCardHeaderTitle to={`/interaction/${_id}`}>
          <EventCardHeaderTitleHighlight>
            {!isUser1 && pet?.name.slice(0, 7) + (pet?.name.length > 7 ? '...' : '')}
            {isUser1 && '你'}
          </EventCardHeaderTitleHighlight>{' '}
          {` 发起的互动 `}
        </EventCardHeaderTitle>
        <EventCardHeaderStatus
          $isWaitingForResponse={isWaitingForResponse}
          to={`/interaction/${_id}`}
        >
          {status}
        </EventCardHeaderStatus>
        <EventCardHeaderDate>{timeSince(createdAt)}</EventCardHeaderDate>
      </EventCardHeader>
      <EventContent to={`/interaction/${_id}`}>
        <div>{userStory?.slice(0, 30) + '...'}</div>
      </EventContent>
    </>
  );
};
InteractionComponent.prototype = {
  auth: PropTypes.object.isRequired,
};

const InteractionComponentWithConnect = connect(adventureMapToProp)(InteractionComponent);

const TutorialComponent = ({ event }) => {
  const [forceOpen, setForceOpen] = useState(false);
  return (
    <>
      <EventCardHeader>
        <Avatar src={'public/apple-touch-icon.png'} size={50} to={``} />
        <EventCardHeaderTitle to={``}>{event.title}</EventCardHeaderTitle>
        <PassiveEventCardButton
          $isNegative={false}
          onClick={() => {
            setForceOpen(true);
          }}
        >
          查看来信
        </PassiveEventCardButton>
      </EventCardHeader>
      <EventContent
        onClick={() => {
          setForceOpen(true);
        }}
      >
        <div>{event.text}</div>
      </EventContent>
      <Survey
        forceOpen={forceOpen}
        videoUrl={event.videoUrl}
        title={event.title}
        text={event.text}
        onOkCallBack={() => {
          setForceOpen(false);
        }}
        onCancelCallBack={() => {
          setForceOpen(false);
        }}
      />
    </>
  );
};

const renderEventType = (eventType, event) => {
  switch (eventType) {
    case 'LongStory':
      return (
        <EventCardContainer>
          <LongStoryComponent event={event} />
        </EventCardContainer>
      );
    case 'LongStoryPassive':
      return (
        <PassiveEventCardContainer>
          <LongStoryPassiveComponent event={event} />
        </PassiveEventCardContainer>
      );
    case 'Adventure':
      return (
        <EventCardContainer>
          <AdventureComponentWithConnect event={event} />
        </EventCardContainer>
      );
    case 'AdventureSignup':
      return (
        <PassiveEventCardContainer>
          <AdventureSignupComponent event={event} />
        </PassiveEventCardContainer>
      );
    case 'AdventureRequest':
      return (
        <PassiveEventCardContainer>
          <AdventureRequestComponentWithConnect event={event} />
        </PassiveEventCardContainer>
      );
    case 'ShortStory':
      return (
        <>
          <EventCardContainer>
            <ShortStoryComponent event={event} />
          </EventCardContainer>
        </>
      );
    case 'Interaction':
      return (
        <>
          <EventCardContainer>
            <InteractionComponentWithConnect event={event} />
          </EventCardContainer>
        </>
      );
    case 'Tutorial':
      return (
        <>
          <EventCardContainer>
            <TutorialComponent event={event} />
          </EventCardContainer>
        </>
      );
    default:
      return <div>Unknown Event Type</div>;
  }
};

const EventCard = ({ auth, event: { eventType }, event }) => {
  return <>{renderEventType(eventType, event)}</>;
};

EventCard.propTypes = {
  auth: PropTypes.object.isRequired,
  event: PropTypes.shape({
    eventType: PropTypes.oneOf([
      'LongStory',
      'Adventure',
      'ShortStory',
      'Interaction',
      'LongStoryPassive',
      'AdventureSignup',
      'AdventureRequest',
      'Tutorial',
    ]).isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(EventCard);
