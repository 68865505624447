import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Avatar from '../general/avatar/Avatar';

const FriendCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 1fr 1fr 2fr;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
`;

const FriendName = styled(Link)`
  margin: 0;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
  color: black;
  font-weight: bold;
`;

const FriendDetails = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

const Tag = styled(Link)`
  background: #f0f0f0;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  color: #333;
`;

const FriendCard = ({ pet }) => {
  return (
    <FriendCardWrapper>
      <Avatar to={`/pet-profile/${pet._id}`} src={pet.avatar} size={45}></Avatar>
      <FriendName to={`/pet-profile/${pet._id}`}>{pet.name}</FriendName>
      <Tag to={`/pet-profile/${pet._id}`}>
        {pet.species?.length >= 4 ? pet.species?.slice(0, 2) : pet.species}
        {pet.species?.length >= 4 ? '...' : ''}
      </Tag>
      <Tag to={`/pet-profile/${pet._id}`}>
        {pet.profession.length >= 4 ? pet.profession.slice(0, 2) : pet.profession}
        {pet.profession.length >= 4 ? '...' : ''}
      </Tag>
      {pet.tags.length > 0 ? (
        <FriendDetails>
          {pet.tags.slice(0, 1).map((tag, index) => (
            <Tag
              key={index}
              style={{ backgroundColor: 'rgba(200, 0, 0, 0.1)' }}
              to={`/pet-profile/${pet._id}`}
            >
              {tag.length >= 4 ? tag.slice(0, 3) : tag} {tag.length >= 4 ? '...' : ''}
            </Tag>
          ))}
          {pet.tags.length > 1 && <Tag>+{pet.tags.length - 1}</Tag>}
        </FriendDetails>
      ) : (
        <Tag to={`/pet-profile/${pet._id}`}>无</Tag>
      )}
    </FriendCardWrapper>
  );
};

FriendCard.propTypes = {
  pet: PropTypes.object.isRequired,
};

export default FriendCard;
