import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getFriend, sendFriendRequest } from '../../../actions/friend';
import { connect } from 'react-redux';
import Loader from '../loader/Loader';
import { useNavigate } from 'react-router-dom';

const FriendButton = ({
  auth,
  use2Id,
  getFriend,
  sendFriendRequest,
  friend: { friend },
  CustomButton,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.user && auth.user._id !== use2Id) {
      getFriend(use2Id);
    }
  }, [getFriend, use2Id, auth.user]);

  const handleClickRequestFriend = () => {
    if (friend === null) return;
    if (friend.isFriend) {
      navigate(`/message/${use2Id}`);
      return;
    }
    if (friend.requestSent) return;
    sendFriendRequest(use2Id);
  };

  const DefaultButton = (props) => (
    <button
      className="btn btn-primary"
      style={{ height: '40px', width: 'auto', fontSize: '1rem', fontWeight: 'bold' }}
      {...props}
    >
      {props.children}
    </button>
  );

  const ButtonComponent = CustomButton || DefaultButton;

  return (
    <div>
      {auth.user && auth.user._id !== use2Id && (
        <ButtonComponent onClick={handleClickRequestFriend}>
          {friend === null ? (
            <Loader />
          ) : (
            <>
              {friend.isFriend ? '发消息' : <>{!friend.requestSent ? '添加好友' : '请求已发送'}</>}
            </>
          )}
        </ButtonComponent>
      )}
    </div>
  );
};

FriendButton.propTypes = {
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  friend: PropTypes.object.isRequired,
  getFriend: PropTypes.func.isRequired,
  sendFriendRequest: PropTypes.func.isRequired,
  CustomButton: PropTypes.elementType,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  friend: state.friend,
});

export default connect(mapStateToProps, { getFriend, sendFriendRequest })(FriendButton);
