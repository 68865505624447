import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addComment } from '../../../actions/comment';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Styled-components for styling
const CommentFormWrapper = styled.div`
  position: relative;
`;

const TextArea = styled.textarea`
  width: 100%;
  border-radius: 5px;
  padding-right: 40px; /* Space for the upload button */
`;

const UploadButton = styled.label`
  position: relative;
  bottom: 30px;
  left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.5em;
`;

const HiddenInput = styled.input`
  display: none;
`;

const PreviewWrapper = styled.div`
  margin-top: 10px;
  position: relative;
  display: inline-block;
`;

const PreviewImage = styled.img`
  max-width: 100px;
  max-height: 100px;
  border-radius: 5px;
`;

const RemoveButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  background: red;
  width: 20px;
  height: 20px;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
`;

const SubmitButton = styled.input`
  width: 100%;
  cursor: pointer;
  background-color: ${(props) => (props.disabled ? '#ccc' : 'var(--primary-color)')};
  color: white;
`;

const CommentForm = ({ entityId, addComment, auth: { isAuthenticated, loading }, commentType }) => {
  const navigate = useNavigate();
  const [text, setText] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    setPreview(URL.createObjectURL(file));
  };

  const removeImage = () => {
    setSelectedImage(null);
    setPreview(null);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    setIsUploading(true);

    const formData = new FormData();
    formData.append('text', text);
    if (selectedImage) formData.append('image', selectedImage);

    const success = await addComment(entityId, formData, '', commentType, selectedImage);
    if (success) {
      setText('');
      removeImage();
    }

    setIsUploading(false);
  };

  return (
    <CommentFormWrapper className="post-form">
      <form className="form my-1" onSubmit={onSubmit}>
        <TextArea
          name="text"
          rows="4"
          placeholder={commentType === 'post' ? '想说点什么呢？' : '想对崽崽说点什么？'}
          value={text}
          onChange={(e) => setText(e.target.value)}
          required
        />
        {preview && (
          <PreviewWrapper>
            <PreviewImage src={preview} alt="Preview" />
            <RemoveButton type="button" onClick={removeImage}>
              <i className="fa-solid fa-xmark"></i>
            </RemoveButton>
          </PreviewWrapper>
        )}
        {!selectedImage && (
          <UploadButton htmlFor="image-upload">
            <i className="fa-regular fa-image"></i>
            <HiddenInput
              id="image-upload"
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
          </UploadButton>
        )}
        <SubmitButton
          type="submit"
          className="btn btn-dark"
          value={isUploading ? '上传中...' : '评论'}
          disabled={isUploading}
        />
      </form>
    </CommentFormWrapper>
  );
};

CommentForm.propTypes = {
  addComment: PropTypes.func.isRequired,
  entityId: PropTypes.string.isRequired,
  auth: PropTypes.object.isRequired,
  commentType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { addComment })(CommentForm);
