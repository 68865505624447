import { GET_MESSAGES, ADD_MESSAGE, CLEAR_UNREAD_COUNT, GET_MESSAGE } from '../actions/types';

const initialState = {
  messages: {}, // key: conversationId, value: array of messages
  unreadCounts: {}, // key: conversationId, value: number of unread messages
  messageUserpairs: {}, // key: conversationId, value: userpair
  error: {},
};

function messageReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_MESSAGES:
      return {
        ...state,
        messages: payload.messages,
        unreadCounts: payload.unreadCounts,
        messageUserpairs: payload.messageUserpairs,
      };
    case ADD_MESSAGE: {
      const { conversationId } = payload;
      const newMessages = state.messages[conversationId]
        ? [...state.messages[conversationId], payload]
        : [payload];
      // 如果是自己发的消息，不增加未读消息数
      let friend = state.messageUserpairs[conversationId];
      let newUnreadCounts = state.unreadCounts[conversationId];
      if (friend._id === payload.senderId) {
        newUnreadCounts = state.unreadCounts[conversationId]
          ? state.unreadCounts[conversationId] + 1
          : 1;
      }
      return {
        ...state,
        messages: {
          ...state.messages,
          [conversationId]: newMessages,
        },
        unreadCounts: {
          ...state.unreadCounts,
          [conversationId]: newUnreadCounts,
        },
      };
    }
    case CLEAR_UNREAD_COUNT:
      return {
        ...state,
        unreadCounts: {
          ...state.unreadCounts,
          [payload]: 0,
        },
      };
    case GET_MESSAGE: {
      const { conversationId } = payload;
      const newMessages = state.messages[conversationId]
        ? [...payload.messages, ...state.messages[conversationId]]
        : payload.messages;
      return {
        ...state,
        messages: {
          ...state.messages,
          [conversationId]: newMessages,
        },
      };
    }

    default:
      return state;
  }
}

export default messageReducer;
