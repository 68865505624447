import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { getShortStoryById, setShortStoryRead } from '../../../actions/event';
import styled from 'styled-components';

import FormLoaderSpinner from '../../general/loading-spinner/FormLoaderSpinner';
import Avatar from '../../general/avatar/Avatar';
import MobileHeader from '../../general/navbar/MobileHeader';
import timeSince from '../../../utils/calculateDate';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const EventCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 95%;
  padding: 5px 5px;
  background-color: #fff;
  border-radius: 5px;
  background-color: #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding-bottom: 30px;
`;

const EventCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
  width: 100%;
`;

const EventCardHeaderTitle = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
`;

const EventCardHeaderTitleHighlight = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ff5d33;
  font-size: 1em;
`;

const EventCardHeaderDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8em;
  color: #999;
  margin-bottom: 5px;
  margin-right: auto;
  margin-left: 30px;
`;

const EventContent = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  color: #333;
  font-size: 0.9em;
`;

const Profiles = ({ auth, event: { shortStory }, getShortStoryById, setShortStoryRead }) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    document.title = '当日故事';
    return () => {
      document.title = 'Zaisland';
    };
  }, []);
  useEffect(() => {
    const handleGetAdventureById = async (id) => {
      setIsLoading(true);
      const response = await getShortStoryById(id);
      setShortStoryRead(id);
      if (response) {
        setIsLoading(false);
      }
    };
    handleGetAdventureById(id);
  }, [setShortStoryRead, getShortStoryById, id]);

  if (!shortStory) {
    return (
      <>
        <FormLoaderSpinner loading={isLoading} text={'正在加载最新小故事...'} />
        <MobileHeader backLink={true} pageTitle={'当日故事'} />
      </>
    );
  }

  const { user, content, createdAt } = shortStory;
  const { pet } = user;

  return (
    <>
      <FormLoaderSpinner loading={isLoading} text={'正在更新最新小故事...'} />
      <MobileHeader
        backLink={true}
        pageTitle={`${pet?.name.slice(0, 4)}${pet?.name.length > 4 ? '...' : ''}的当日故事`}
      />
      <section className="container">
        <Fragment>
          <Container>
            <EventCardContainer>
              <EventCardHeader>
                <Avatar src={pet?.avatar} size={40} to={`/pet-profile/${pet?._id}`} />
                <EventCardHeaderTitle>
                  <EventCardHeaderTitleHighlight>{pet?.name}</EventCardHeaderTitleHighlight>{' '}
                  {` 的当日小故事`}
                </EventCardHeaderTitle>
              </EventCardHeader>
              <EventCardHeaderDate>{timeSince(createdAt)}</EventCardHeaderDate>

              <EventContent>{content}</EventContent>
            </EventCardContainer>
          </Container>
        </Fragment>
        <div className="my-2"></div>
      </section>
    </>
  );
};

Profiles.propTypes = {
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  getShortStoryById: PropTypes.func.isRequired,
  setShortStoryRead: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  event: state.event,
});

export default connect(mapStateToProps, {
  getShortStoryById,
  setShortStoryRead,
})(Profiles);
