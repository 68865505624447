import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addComment } from '../../../actions/comment';
import { Modal, message } from 'antd';

const CommentForm = ({ entityId, rootId, replyComment, addComment, callBack, commentType }) => {
  const [text, setText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    const created = await handleSubmit();
    if (!created) {
      messageApi.open({
        type: 'error',
        content: '评论提交失败',
      });
      return;
    }
    setIsModalOpen(false);
    setText('');
    messageApi.open({
      type: 'success',
      content: '评论提交成功',
    });
    if (callBack) {
      callBack();
    }
  };
  const handleCancel = () => {
    setText('');
    setIsModalOpen(false);
  };
  const handleSubmit = async () => {
    const formData = new FormData();

    formData.append('text', text);
    if (replyComment) {
      formData.append('reply', replyComment._id);
      formData.append('replyTo', replyComment.user);
      formData.append('replyToName', replyComment.name);
    }
    try {
      const created = await addComment(entityId, formData, rootId, commentType);
      return created;
    } catch (error) {
      console.error('Error uploading post:', error);
      return false;
    }
  };
  return (
    <Fragment>
      {contextHolder}
      <div
        className="icon-box"
        onClick={() => {
          showModal();
        }}
      >
        <p className="my-1 reply-icon">回复</p>
      </div>
      <Modal
        title={replyComment ? '回复 ' + replyComment.name : '回复'}
        okText="提交"
        cancelText="取消"
        okType="danger"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="post-form">
          <form className="form my-1">
            <textarea
              name="text"
              cols="30"
              rows="5"
              placeholder="回复评论"
              value={text}
              onChange={(e) => setText(e.target.value)}
              required
              style={{ borderRadius: '5px' }}
            />
          </form>
        </div>
      </Modal>
    </Fragment>
  );
};

CommentForm.propTypes = {
  addComment: PropTypes.func.isRequired,
  rootId: PropTypes.string.isRequired,
};

export default connect(null, { addComment })(CommentForm);
