import { useEffect } from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFriendRequests } from '../../actions/friend';
import { getMessages } from '../../actions/message';
import { getUnreadNotification } from '../../actions/notification';
import { getEventsContinued, getPassiveEvents } from '../../actions/event';
import { getActivityIndex } from '../../actions/pet';
import store from '../../store';

const MessageListener = ({ auth, friend: { fetchingRequest, friends } }) => {
  useEffect(() => {
    let intervalId;

    if (auth.user && fetchingRequest) {
      store.dispatch(getFriendRequests());

      intervalId = setInterval(() => {
        store.dispatch(getFriendRequests());
      }, 10000);
    }

    if ((!auth.user && intervalId) || !fetchingRequest) {
      clearInterval(intervalId);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [auth.user, fetchingRequest]);

  useEffect(() => {
    let intervalId;

    if (auth.user) {
      store.dispatch(getEventsContinued());
      store.dispatch(getPassiveEvents());
      store.dispatch(getUnreadNotification());
      store.dispatch(getActivityIndex());

      intervalId = setInterval(() => {
        store.dispatch(getEventsContinued());
        store.dispatch(getPassiveEvents());
        store.dispatch(getUnreadNotification());
        store.dispatch(getActivityIndex());
      }, 10000);
    }

    if (!auth.user && intervalId) {
      clearInterval(intervalId);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [auth.user]);

  useEffect(() => {
    if (auth.user && friends?.length > 0) {
      store.dispatch(getMessages());
    }
  }, [friends.length, auth.user]);

  return null;
};

MessageListener.propTypes = {
  auth: propTypes.object.isRequired,
  friend: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  friend: state.friend,
});

export default connect(mapStateToProps, {})(MessageListener);
