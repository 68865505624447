import { GET_MESSAGES, ADD_MESSAGE, CLEAR_UNREAD_COUNT, GET_MESSAGE } from '../actions/types';
import api from '../utils/api';
import socket from '../utils/socket';

export const connectSocket = () => async (dispatch) => {
  try {
    socket.connect();
  } catch (err) {
    console.log(err);
  }
};

export const disconnectSocket = async () => {
  try {
    socket.disconnect();
    console.log('Disconnected');
  } catch (err) {
    console.log(err);
  }
};

export const joinRoom = (userId) => async (dispatch) => {
  try {
    socket.emit('join', userId);
    console.log('Joined room');
  } catch (err) {
    console.log(err);
  }
};

export const sendMessage = (messageData) => async (dispatch) => {
  try {
    socket.emit('privateMessage', messageData);
  } catch (err) {
    console.log(err);
  }
};

export const getMessages = () => async (dispatch) => {
  try {
    const res = await api.get(`/messages`);
    dispatch({
      type: GET_MESSAGES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const listenForMessages = (userId) => async (dispatch) => {
  try {
    console.log('Listening for messages');
    socket.on('newMessage', (message) => {
      dispatch({
        type: ADD_MESSAGE,
        payload: message,
      });
    });
    socket.on('connect', () => {
      dispatch(joinRoom(userId));
      dispatch(getMessages());
    });
  } catch (err) {
    console.log(err);
  }
};

export const addMessage = (messageData) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_MESSAGE,
      payload: messageData,
    });
  } catch (err) {
    console.log(err);
  }
};

export const clearUnreadCount = (conversationId) => async (dispatch) => {
  try {
    if (!conversationId) {
      return;
    }
    await api.post(`/messages/clearUnreadCount/${conversationId}`);
    dispatch({
      type: CLEAR_UNREAD_COUNT,
      payload: conversationId,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getMessage = (conversationId, date) => async (dispatch) => {
  try {
    const res = await api.post(`/messages/get-conversation/${conversationId}`, { date });
    dispatch({
      type: GET_MESSAGE,
      payload: { conversationId, messages: res.data },
    });
  } catch (err) {
    console.log(err);
  }
};
