import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import timeSince from '../../../utils/calculateDate';
import LikeButton from '../like/LikeButton';
import SubCommentForm from './SubCommentForm';
import {
  deleteComment,
  addCommentLike,
  removeCommentLike,
  getSubcommentsByCommentId,
} from '../../../actions/comment';
import Spinner from '../../layout/Spinner';
import './Comment.css';
import styles from './Comment.module.css';
import subStyles from './SubComment.module.css';
import Avatar from '../avatar/Avatar';
import Picture from '../picture/Picture';

const CommentItem = ({
  entityId,
  comment: {
    _id,
    text,
    name,
    pet,
    avatar,
    user,
    createdAt,
    likes,
    likesCount,
    subcomments,
    subcommentsNum,
    isLiked,
  },
  comment,
  auth,
  auth: { isAuthenticated, loading },
  commentType,
  deleteComment,
  addCommentLike,
  removeCommentLike,
  getSubcommentsByCommentId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSubcommentsVisible, setIsSubcommentsVisible] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const commentId = query.get('comment');
    const rootId =
      query.get('rootComment') === 'null' || query.get('rootComment') === 'undefined'
        ? null
        : query.get('rootComment');
    if (commentId && !rootId && commentId === _id) {
      const commentElement = document.getElementById(`comment-${commentId}`);
      if (commentElement) {
        commentElement.scrollIntoView({ behavior: 'smooth' });
      }
      return;
    }
    if (commentId && rootId && rootId === _id) {
      setTimeout(async () => {
        await getSubcommentsByCommentId(_id);
        setIsSubcommentsVisible(true);
        const commentElement = document.getElementById(`comment-${commentId}`);
        if (commentElement) {
          commentElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 500);
      return;
    }
  }, [location, getSubcommentsByCommentId, _id]);
  const handleOnClickViewReplies = async () => {
    if (!isSubcommentsVisible) {
      await getSubcommentsByCommentId(_id);
    }
    setIsSubcommentsVisible(!isSubcommentsVisible);
  };
  const handleOnClickLikeComment = async (liked, commentId) => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }
    let rootId = '';
    if (commentId !== _id) {
      rootId = _id;
    }
    if (liked) {
      await removeCommentLike(commentId, rootId);
    } else {
      await addCommentLike(commentId, rootId);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const confirmDelete = window.confirm('你要删除这条评论吗?');
      if (confirmDelete) {
        let rootId = '';
        if (_id !== commentId) {
          rootId = _id;
        }
        const deleted = await deleteComment(entityId, commentId, rootId, commentType);
        if (deleted) {
        }
      } else {
      }
    } catch (error) {
      console.error('发生错误:', error);
    }
  };

  return comment === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <div className={styles.commentContainer} id={`comment-${_id}`}>
        <div className={styles.header}>
          <Avatar src={avatar} alt="头像" to={`/pet-profile/${pet._id}`} size={40} isUser={true} />
          <div className={styles.userName}>{name}</div>
          <div className={styles.date}>{timeSince(createdAt)}</div>
          {auth?.user?._id === user && (
            <button onClick={() => handleDeleteComment(_id)} className={styles.deleteButton}>
              <i className="fa-solid fa-trash"></i>
            </button>
          )}
        </div>
        <div className={styles.commentText}>{text}</div>
        {comment.picture && (
          <>
            <div style={{ width: '30%' }}>
              <Picture src={comment.picture} alt="评论图片" size={30} />
            </div>
          </>
        )}
        <div className={styles.actions}>
          <div className={styles.actionButtonList}>
            <button className={styles.likeButton}>
              <LikeButton
                isLiked={isLiked}
                likeNum={likesCount}
                fontSize="1"
                onLike={() => {
                  handleOnClickLikeComment(isLiked, _id);
                }}
                onUnlike={() => {
                  handleOnClickLikeComment(isLiked, _id);
                }}
              />
            </button>
            <SubCommentForm
              entityId={entityId}
              rootId={_id}
              callBack={() => {
                setIsSubcommentsVisible(true);
              }}
              commentType={commentType}
            />
          </div>
          {subcommentsNum > 0 && (
            <button className={styles.viewReplies} onClick={handleOnClickViewReplies}>
              {isSubcommentsVisible ? '隐藏回复' : `查看 ${subcommentsNum} 条回复`}
            </button>
          )}
        </div>
        <div
          className={`${styles.collapseTransition} ${
            isSubcommentsVisible ? styles.expand : styles.collapse
          }`}
        >
          {subcomments &&
            subcomments.length > 0 &&
            isSubcommentsVisible &&
            subcomments.map((sub) => (
              <div
                key={sub._id}
                id={`comment-${sub._id}`}
                className={subStyles.subcommentContainer}
              >
                <div className={subStyles.subcommentHeader}>
                  <Avatar
                    src={sub.avatar}
                    alt="头像"
                    to={`/pet-profile/${sub.pet}`}
                    size={30}
                    isUser={true}
                  />
                  <div className={subStyles.subcommentName}>
                    {sub.name}{' '}
                    {sub.replyTo && (
                      <>
                        <span className={subStyles.replyArrow}>
                          <i className="fa-solid fa-play"></i>
                        </span>{' '}
                        {sub.replyToName}
                      </>
                    )}
                  </div>
                  <div className={subStyles.subcommentDate}>{timeSince(sub.createdAt)}</div>
                  {auth?.user?._id === sub.user && (
                    <button
                      onClick={() => handleDeleteComment(sub._id, _id)}
                      className={styles.deleteButton}
                    >
                      <i className="fa-solid fa-trash"></i>
                    </button>
                  )}
                </div>
                <div className={subStyles.subcommentText}>{sub.text}</div>
                <div className={styles.actions}>
                  <div className={styles.actionButtonList}>
                    <button className={styles.likeButton}>
                      <LikeButton
                        isLiked={sub.isLiked}
                        likeNum={sub.likesCount}
                        fontSize="1"
                        onLike={() => {
                          handleOnClickLikeComment(sub.isLiked, sub._id);
                        }}
                        onUnlike={() => {
                          handleOnClickLikeComment(sub.isLiked, sub._id);
                        }}
                      />
                    </button>
                    <SubCommentForm
                      entityId={entityId}
                      rootId={_id}
                      replyComment={sub}
                      commentType={commentType}
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </Fragment>
  );
};

CommentItem.propTypes = {
  entityId: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteComment: PropTypes.func.isRequired,
  addCommentLike: PropTypes.func.isRequired,
  removeCommentLike: PropTypes.func.isRequired,
  getSubcommentsByCommentId: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  deleteComment,
  addCommentLike,
  removeCommentLike,
  getSubcommentsByCommentId,
})(CommentItem);
