import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { getAdventure } from '../../../actions/event';
// import store from '../../../store';
import styled from 'styled-components';

import FormLoaderSpinner from '../../general/loading-spinner/FormLoaderSpinner';
import Avatar from '../../general/avatar/Avatar';
import MobileHeader from '../../general/navbar/MobileHeader';
import timeSince from '../../../utils/calculateDate';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const EventCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 95%;
  padding: 5px 5px;
  background-color: #fff;
  border-radius: 5px;
  background-color: #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding-bottom: 30px;
`;

const EventCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
  width: 100%;
`;

const EventCardHeaderTitle = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
`;

const EventCardHeaderTitleHighlight = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ff5d33;
  font-size: 1em;
`;

const EventCardHeaderSelfAvtar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`;

const EventCardHeaderDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8em;
  color: #999;
  margin-bottom: 5px;
  margin-right: auto;
  margin-left: 30px;
`;

const EventContent = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  color: #333;
  font-size: 0.9em;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
`;

const Profiles = ({ auth, event: { adventure }, getAdventure }) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleGetLongStoryById = async (id) => {
      setIsLoading(true);
      const response = await getAdventure(id);
      if (response) {
        setIsLoading(false);
      }
    };
    handleGetLongStoryById(id);
  }, [getAdventure, id]);

  if (!adventure) {
    return (
      <>
        <FormLoaderSpinner loading={isLoading} text={'正在加载最新冒险...'} />
        <MobileHeader backLink={true} pageTitle={'冒险故事'} />
      </>
    );
  }

  const { user1, user2, user1Story, user2Story } = adventure;
  const { pet: pet1 } = user1;
  const { pet: pet2 } = user2;

  let isUser1 = auth?.user?._id === user1?._id;
  let userStory = isUser1 ? user1Story : user2Story;
  let pet = isUser1 ? pet1 : pet2;
  let anotherPet = isUser1 ? pet2 : pet1;

  return (
    <>
      <FormLoaderSpinner loading={isLoading} text={'正在加载冒险...'} />
      <MobileHeader backLink={true} pageTitle={`和${anotherPet?.name}的冒险`} />
      <section className="container">
        <Fragment>
          <Container>
            {userStory && userStory.length > 0 && (
              <>
                {userStory.map((story) => (
                  <EventCardContainer key={story._id}>
                    <EventCardHeader>
                      <Avatar src={pet.avatar} size={40} to={`/pet-profile/${pet._id}`} />
                      <EventCardHeaderTitle>
                        <EventCardHeaderTitleHighlight>{pet.name}</EventCardHeaderTitleHighlight>
                      </EventCardHeaderTitle>

                      <EventCardHeaderSelfAvtar>
                        <Avatar src={auth.user.avatar} size={50} to={`/pet-profile/${pet._id}`} />
                      </EventCardHeaderSelfAvtar>
                    </EventCardHeader>
                    <EventCardHeaderDate>{timeSince(story.createdAt)}</EventCardHeaderDate>
                    <EventContent>
                      <p>{story.content}</p>
                    </EventContent>
                  </EventCardContainer>
                ))}
              </>
            )}
          </Container>
        </Fragment>
        <div className="my-2"></div>
      </section>
    </>
  );
};

Profiles.propTypes = {
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  getAdventure: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  event: state.event,
});

export default connect(mapStateToProps, {
  getAdventure,
})(Profiles);
