import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import DefaultAvatar from '../../../img/defaultUserImage.jpeg';
import DefaultPetAvatar from '../../../img/defaultPetImage.jpg';

// a kind of yellow to show user is active border for user avatar
const PictureWrapper = styled.div`
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  ${(props) =>
    props.$isUser &&
    `
    border: 1px solid rgba(135, 135, 0, 0.8);
    img {
    padding: 2px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  `}
  ${(props) =>
    !props.$isUser &&
    `
    img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  `}
`;

const ZoomContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100; /* 确保在最前面 */
  background-color: rgba(0, 0, 0, 0.5); /* 背景遮罩 */
  cursor: zoom-out;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.5s;
    border-radius: 5px;
    cursor: zoom-out;
  }
`;

/**
 *
 * @param {*} param0
 * @param {*} param0.to
 * @param {*} param0.src
 *
 * @returns
 */
const AvatarZoom = ({ src, alt = 'picture', isUser = false, size = 50 }) => {
  const [zoom, setZoom] = useState(false);
  let imgSrc =
    src === null || src === undefined || src === ''
      ? isUser
        ? DefaultAvatar
        : DefaultPetAvatar
      : src.startsWith('http')
      ? src
      : process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + '/' + src;
  return (
    <>
      <PictureWrapper
        $size={size}
        $isUser={isUser}
        onClick={() => {
          setZoom(true);
        }}
      >
        <img src={imgSrc} alt={alt} />
      </PictureWrapper>
      {zoom && (
        <ZoomContainer
          onClick={() => {
            setZoom(false);
          }}
        >
          <img src={imgSrc} alt={alt} />
        </ZoomContainer>
      )}
    </>
  );
};

AvatarZoom.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  isUser: PropTypes.bool,
  size: PropTypes.number,
};

export default AvatarZoom;
