import api from '../utils/api';
import { setAlert } from './alert';

import {
  GET_FRIEND,
  GET_MY_FRIENDS,
  GET_MY_FRIENDS_REQUEST,
  FRIEND_ACCEPT,
  FRIEND_REQUEST,
  CLEAR_FRIEND,
  CLEAR_FRIENDS,
  STOP_FETCH_FRIEND_REQUEST,
  RESUME_FETCH_FRIEND_REQUEST,
} from './types';

// Get friend

export const getFriend = (userId) => async (dispatch) => {
  try {
    const res = await api.get(`/friends/is-friends/${userId}`);
    dispatch({
      type: GET_FRIEND,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return { friend: false };
  }
};

// Get friends
export const getFriends = () => async (dispatch) => {
  try {
    const res = await api.get('/friends');
    dispatch({
      type: GET_MY_FRIENDS,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return { friend: false };
  }
};

// Get friend requests
export const getFriendRequests = () => async (dispatch) => {
  try {
    const res = await api.get('/friends/requests');
    dispatch({
      type: GET_MY_FRIENDS_REQUEST,
      payload: res.data,
    });
    return res.data;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return { friend: false };
  }
};

// Send friend request
export const sendFriendRequest = (recipientId) => async (dispatch) => {
  try {
    const res = await api.post('/friends/request', { recipientId });
    dispatch({
      type: FRIEND_REQUEST,
      payload: true,
    });
    dispatch(setAlert(res.data.msg, 'success'));
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

// Accept friend request
export const acceptFriendRequest = (friendRequestId) => async (dispatch) => {
  try {
    const res = await api.post('/friends/accept', { friendRequestId });
    dispatch({
      type: FRIEND_ACCEPT,
      payload: { _id: friendRequestId, friend: res.data },
    });
    dispatch(setAlert('好友请求通过', 'success'));
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

// Clear friend
export const clearFriend = () => async (dispatch) => {
  dispatch({
    type: CLEAR_FRIEND,
  });
};

// Clear friends
export const clearFriends = () => async (dispatch) => {
  dispatch({
    type: CLEAR_FRIENDS,
  });
};

// Stop fetch friend request
export const stopFetchFriendRequest = () => async (dispatch) => {
  dispatch({
    type: STOP_FETCH_FRIEND_REQUEST,
  });
};

// Resume fetch friend request
export const resumeFetchFriendRequest = () => async (dispatch) => {
  dispatch({
    type: RESUME_FETCH_FRIEND_REQUEST,
  });
};
