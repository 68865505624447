import api from '../utils/api';
import { setAlert } from './alert';
import { getPreSignedUrl } from './auth';

import {
  ADD_COMMENT,
  REMOVE_COMMENT,
  ADD_PET_COMMENT,
  REMOVE_PET_COMMENT,
  GET_COMMENTS,
  GET_SUBCOMMENT,
  UPDATE_COMMENT_LIKES,
  CLEAR_COMMENTS,
} from './types';

// Add comment
/**
 *
 * @param {String} entityId
 * @param {FormData} formData
 * @param {String} rootId
 * @param {String} commentType
 * @returns
 */
export const addComment =
  (entityId, formData, rootId = '', commentType, image = null) =>
  async (dispatch) => {
    try {
      let res;
      if (image) {
        const imageKey = `public/comment/${entityId}/images/${image.name}`;
        const { key, signedUrl } = await getPreSignedUrl(imageKey, image.type);
        formData.append('imageUrl', imageKey);
        formData.append('key', key);
        // 允许跨域上传图片
        await api.put(signedUrl, image, {
          headers: {
            // 允许跨域上传图片
            'Access-Control-Allow-Origin': '*',
            'Content-Type': image.type,
          },
        });
      }
      switch (commentType) {
        case 'pet':
          res = await api.post(`/comments/pet-comment/${entityId}?root=${rootId}`, formData);
          dispatch({
            type: ADD_PET_COMMENT,
            payload: res.data,
          });
          dispatch(setAlert('评论成功', 'success'));
          return true;
        case 'post':
          res = await api.post(`/comments/post-comment/${entityId}?root=${rootId}`, formData);
          dispatch({
            type: ADD_COMMENT,
            payload: res.data,
          });
          dispatch(setAlert('评论成功', 'success'));
          return true;
        default:
          break;
      }
    } catch (err) {
      const errors = err?.response?.data?.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }
      return false;
    }
  };

// Delete comment
/**
 *
 * @param {String} postId
 * @param {String} commentId
 * @param {String} rootId
 * @param {String} commentType
 * @returns
 */
export const deleteComment = (postId, commentId, rootId, commentType) => async (dispatch) => {
  try {
    let res;
    switch (commentType) {
      case 'pet':
        res = await api.delete(`/comments/delete-comments-in-pet/${commentId}?root=${rootId}`);
        dispatch({
          type: REMOVE_PET_COMMENT,
          payload: {
            commentId, // Include commentId in the payload
            data: res.data, // Include res.data in the payload under a different key
          },
        });
        dispatch(setAlert('评论删除', 'success'));
        return true;
      case 'post':
        res = await api.delete(`/comments/delete-comments-in-post/${commentId}?root=${rootId}`);
        dispatch({
          type: REMOVE_COMMENT,
          payload: {
            commentId, // Include commentId in the payload
            data: res.data, // Include res.data in the payload under a different key
          },
        });
        dispatch(setAlert('评论删除', 'success'));
        return true;
      default:
        break;
    }
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

// Get post & Comment
export const getComments = (id, pageNum, pageSize) => async (dispatch) => {
  try {
    const resComment = await api.get(
      `/comments/comments-in-entity/${id}?pageNum=${pageNum}&pageSize=${pageSize}`,
    );

    dispatch({
      type: GET_COMMENTS,
      payload: resComment.data,
    });
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

export const getSubcommentsByCommentId = (id) => async (dispatch) => {
  try {
    const res = await api.get(`/comments/subcomments-in-comment/${id}`);
    dispatch({
      type: GET_SUBCOMMENT,
      payload: {
        commentId: id,
        subcomments: res.data,
      },
    });
    return res.data;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return [];
  }
};

// Add like
export const addCommentLike = (commentId, rootId) => async (dispatch) => {
  try {
    const res = await api.post(`/likes/comment/${commentId}`);
    dispatch({
      type: UPDATE_COMMENT_LIKES,
      payload: {
        commentId,
        rootId,
        isLiked: true,
        likes: res.data,
      },
    });
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

// Remove like
export const removeCommentLike = (commentId, rootId) => async (dispatch) => {
  try {
    const res = await api.delete(`/likes/comment/${commentId}`);

    dispatch({
      type: UPDATE_COMMENT_LIKES,
      payload: {
        commentId,
        rootId,
        isLiked: false,
        likes: res.data,
      },
    });
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
  }
};

export const clearComments = () => async (dispatch) => {
  dispatch({ type: CLEAR_COMMENTS });
};
