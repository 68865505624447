import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Spinner from '../layout/Spinner';
import Avatar from '../general/avatar/AvatarZoom';

const UserProfileContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 600px;
  padding: 0 20px;
`;

const UserProfileAvatarNameBiographyContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
`;

const UserProfileAvatarNameContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const UserProfileBiographyContainer = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 0.7em;
  color: #999;
`;

const UserProfileContainerBorder = styled.div`
  display: flex;
  align-items: flex-start;
  width: 90%;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
`;

const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const UserName = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
`;

const GenderIcon = styled.div`
  position: relative;
  top: -5px;
  margin-left: 5px;
  font-size: 1em;
  font-weight: bold;
  color: ${(props) => (props.$gender ? '#0077cc' : '#ff69b4')};
`;

const UserLocation = styled.div`
  font-size: 10px;
  color: #999;
  margin-top: 5px;
`;

const OwnerDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 10px;
  margin-right: 5px;
  min-width: 200px;
  font-size: 0.7em;
  color: #999;
`;

const OwnerDescriptionHeader = styled.span`
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-size: 1em;
  font-weight: bold;
  color: #666;
`;

const EditButton = styled.button`
  position: relative;
  top: 0;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  gap: 5px;
  color: #666;
  font-size: 1em;
  cursor: pointer;
  background-color: #fff;
  border: none;
  z-index: 1;
  span {
    font-size: 0.7em;
  }
`;

const UserProfile = ({ user, auth }) => {
  const navigate = useNavigate();
  return (
    <>
      {user === null ? (
        <Spinner />
      ) : (
        <>
          <UserProfileContainer>
            <UserProfileAvatarNameBiographyContainer>
              <UserProfileAvatarNameContainer>
                {' '}
                <Avatar
                  src={user.avatar}
                  alt={`${user.username}'s avatar`}
                  size={40}
                  to=""
                  isUser={true}
                />
                <UserInfo>
                  <UserName>
                    {user.username}
                    {user.gender && (
                      <GenderIcon $gender={user.gender === 'male' ? true : false}>
                        {user.gender === 'male' ? '♂️' : '♀️'}
                      </GenderIcon>
                    )}
                  </UserName>
                  {user.location && <UserLocation>{user.location}</UserLocation>}
                </UserInfo>{' '}
              </UserProfileAvatarNameContainer>
              <UserProfileBiographyContainer>
                {user.biography || '该用户没有自我介绍'}
              </UserProfileBiographyContainer>
            </UserProfileAvatarNameBiographyContainer>

            <OwnerDescription>
              <OwnerDescriptionHeader>主人的留言：</OwnerDescriptionHeader>
              {user.ownerDescription || '该用户没有给崽崽的留言'}
              <OwnerDescriptionHeader>主人的联系方式：</OwnerDescriptionHeader>
              {user?.contact ? user?.contact : 'QQ: ' + user.qqNumber}
            </OwnerDescription>
            {auth.user && auth.user._id === user._id && (
              <EditButton
                onClick={() => {
                  navigate('/setting');
                }}
              >
                <i className="fa-solid fa-gear"></i>
              </EditButton>
            )}
          </UserProfileContainer>
          <UserProfileContainerBorder />
        </>
      )}
    </>
  );
};

UserProfile.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string,
    gender: PropTypes.string,
    location: PropTypes.string,
    biography: PropTypes.string,
    avatar: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(UserProfile);
