import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { createSurvey, getCurrentSurvey } from '../../actions/survey';
import { connect } from 'react-redux';
import FormLoaderSpinner from '../general/loading-spinner/FormLoaderSpinner';

import { Modal, Button } from 'antd';

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 1rem;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const TextArea = styled.textarea`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  & > input {
    margin-right: 10px;
  }
`;

const OptionLabel = styled.label`
  font-size: 1rem;
`;

const TextDisplayButton = styled.button`
  background-color: #ff5d33;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 1rem;
  margin-top: 10px;
`;

const TextDisplay = styled.div`
  display: ${(props) => (props.isTextDisplay ? 'block' : 'none')};
  margin-top: 10px;
  transition: display 0.5s;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const TutorialModal = ({
  forceOpen,
  onOkCallBack,
  onCancelCallBack,
  countDown = 10,
  title = '',
  text = '',
  videoUrl = '',
  createSurvey,
  getCurrentSurvey,
}) => {
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(countDown);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isTextDisplay, setIsTextDisplay] = useState(true);
  const [survey, setSurvey] = useState({
    willingsToHaveNewContent: null,
    willingsToSupport: null,
    interview: '',
  });
  let { willingsToHaveNewContent, willingsToSupport, interview } = survey;
  const videoRef = useRef(null);
  const countdownRef = useRef(null);

  const onChange = (e) => {
    setSurvey((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    const fetchSurvey = async () => {
      if (forceOpen) {
        setLoading(true);
        const res = await getCurrentSurvey();
        if (res) {
          res.willingsToHaveNewContent = res.willingsToHaveNewContent.toString();
          res.willingsToSupport = res.willingsToSupport.toString();
          setSurvey(res);
        }
        setLoading(false);
      }
    };
    fetchSurvey();
  }, [forceOpen, getCurrentSurvey]);

  useEffect(() => {
    if (forceOpen) {
      countdownRef.current = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(countdownRef.current);
            setIsButtonDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(countdownRef.current);
    };
  }, [forceOpen]);

  const handleOk = async () => {
    setLoading(true);
    const formData = {
      willingsToHaveNewContent: willingsToHaveNewContent === 'true',
      willingsToSupport: willingsToSupport === 'true',
      interview,
    };
    const res = await createSurvey(formData);
    setLoading(false);
    setSurvey(res);

    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
    if (onOkCallBack && typeof onOkCallBack === 'function') {
      onOkCallBack();
    }
  };

  return (
    <Modal
      title={title}
      open={forceOpen}
      onCancel={() => {}}
      closable={false}
      footer={[
        <Button
          key="ok"
          type="primary"
          onClick={handleOk}
          disabled={isButtonDisabled}
          style={{ width: '100%' }}
        >
          {isButtonDisabled ? `请花一点看完我们的信哦 (${countdown}s)` : '填写完了!'}
        </Button>,
        <div className="my-2"></div>,
        <div className="my-2"></div>,
      ]}
      width="100%"
    >
      {loading && <FormLoaderSpinner loading={loading} />}
      <FormGroup>
        <Label>创始人的寄语</Label>
        <TextDisplayButton onClick={() => setIsTextDisplay((prev) => !prev)}>
          {isTextDisplay ? '收起' : '点击查看创始人的寄语'}
        </TextDisplayButton>
        <TextDisplay isTextDisplay={isTextDisplay}>
          感谢大家与我们一同经历这段奇妙旅程。
          <br />
          <br />
          世界很大，我们的孤独与渺小在其映衬下格外凸显；
          <br />
          <br />
          世界又很小，我们时常感到被逼仄的现实困住而失去太多可能。
          <br />
          <br />
          崽崽或许是理想自我、虚拟朋友、又或许是你脑海里的一个神奇角色……一切都由你来定义和创造。Zaisland是与自我对话的窗口，是想象力的画布，也是开启人生副本、与他人连接的钥匙。
          <br />
          <br />
          我们希望自己也希望你们，在这里得到一位理想朋友的陪伴与安慰，体验平行世界的可能，也因为你们的有趣、温暖、可爱，抑或是幽默、张扬、犀利而结识到现实世界里有意思的朋友。
          <br />
          <br />
          <BoldText>77：</BoldText>{' '}
          人与人之间的联结和火花始终惊艳到我。希望能通过Zaisland给大家带来一些值得回味的共同经历，人间悲喜。
          <br />
          <br />
          <BoldText>Joy：</BoldText>{' '}
          放弃了曾经的记者梦，现在的生活也还行，但一直想知道，在一个不用考虑收入、地位、现实阻碍的世界，我会去到哪里、遇到什么人，会经历什么样的惊喜和烦恼？我在Zaisland开启人生副本，期待与你相遇～
          <br />
          <br />
          <BoldText>Haoji：</BoldText>{' '}
          孤身在外留学，两只猫猫臭宝和困困是我的精神寄托，出门旅游，也时常记挂。偶尔我也在想，如果能知道他们在想什么就好了；或者如果他们跟我一样有自己的生活，该是什么样的冒险之旅
          - 希望把这种体验也送给你们！
          <br />
          <br />
          <BoldText>Lai：</BoldText>{' '}
          小时候喜欢Q宠，长大了想养自己定制的崽崽。喜欢天马行空的设定，把想象具像化为故事与情绪；也想创造一片自由交互的净土，在这里有趣即正义。感谢崽妈崽爸们跟我们分享奇思妙想、一起养崽，祝大家的生活也有趣又可爱！
          <br />
          <br />
          <BoldText>Yiwen：</BoldText>{' '}
          我们希望能够构建最有安全感的交流渠道，和现实保持一点距离，在精神和想象的场域中构建起新的联系。也希望Zaisland可以成为记录日常、陪伴你的一种方式。谢谢大家愿意把珍贵的善意给予他人和崽崽们！
          <br />
          <br />
          <BoldText>Freya：</BoldText>{' '}
          崽崽是我理想中的自己，但她又是她自己。崽崽岛屿某种程度上也是我平行世界的幻想，很开心与大家走过这段旅程，看到用户各种的奇妙崽崽，感觉这个乌托邦有大家的存在而更丰富。
          <br />
          <br />
          <BoldText>Zhiyu:</BoldText>{' '}
          一转眼，Zaisland之旅就要告一段落了，崽崽经由你们的意志被创造出来，与你们的灵魂碰撞在一起产生真实的情感交集，这一切的过程是如此奇妙。但愿你玩得开心，期待未来我们打造出一个更好的Zaisland～
        </TextDisplay>
      </FormGroup>
      <FormGroup>
        <Label>你会希望崽崽带着全新的内容回来吗？</Label>
        <Option>
          <Input
            type="radio"
            name="willingsToHaveNewContent"
            checked={willingsToHaveNewContent === 'true'}
            value={true}
            onChange={(e) => onChange(e)}
          />
          <OptionLabel>愿意！</OptionLabel>
        </Option>
        <Option>
          <Input
            type="radio"
            name="willingsToHaveNewContent"
            value={false}
            checked={willingsToHaveNewContent === 'false'}
            onChange={(e) => onChange(e)}
          />
          <OptionLabel>再等等吧！</OptionLabel>
        </Option>
      </FormGroup>
      <FormGroup>
        <Label>如果zaisland回归，你们愿意花点零花钱支持崽崽的运作吗（服务器、开发...）?</Label>
        <Option>
          <Input
            type="radio"
            name="willingsToSupport"
            checked={willingsToSupport === 'true'}
            value={true}
            onChange={(e) => onChange(e)}
          />
          <OptionLabel>愿意！ </OptionLabel>
        </Option>
        <Option>
          <Input
            type="radio"
            name="willingsToSupport"
            value={false}
            checked={willingsToSupport === 'false'}
            onChange={(e) => onChange(e)}
          />
          <OptionLabel>再想想吧！</OptionLabel>
        </Option>
      </FormGroup>
      <FormGroup>
        <Label>
          我们欢迎用户与我们共创一个更加奇妙的Zaisland世界～如果你愿意参加我们的50元付费访谈、为崽崽世界出谋划策，请留下自己的微信号哦～（选填）
        </Label>
        <TextArea
          name="interview"
          value={interview}
          placeholder="请留下微信号，我们会在第一时间联系你哦～（选填）"
          onChange={(e) => setSurvey({ ...survey, interview: e.target.value })}
        />
      </FormGroup>
    </Modal>
  );
};

export default connect(null, { createSurvey, getCurrentSurvey })(TutorialModal);
