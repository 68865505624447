import api from '../utils/api';
import { setAlert } from './alert';
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  RESET_POSTS,
  CLEAR_MY_PET,
} from './types';
import { disconnectSocket } from './message';

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    const res = await api.get('/auth');
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const register = (formData) => async (dispatch) => {
  try {
    const res = await api.post('/users', formData);

    dispatch({
      type: REGISTER_SUCCESS,
      payload: res.data,
    });
    dispatch(loadUser());
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    if (err?.response?.data?.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    dispatch({
      type: REGISTER_FAIL,
    });
    return false;
  }
};

// Login User
export const login = (email, password) => async (dispatch) => {
  const body = { email, password };

  try {
    const res = await api.post('/auth', body);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    if (err?.response?.data?.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    dispatch({
      type: LOGIN_FAIL,
    });
  }
};

// Send Email To Register
export const sendEmailToRegister = (email) => async (dispatch) => {
  try {
    await api.post('/users/register', { email });
    dispatch(setAlert('Sent confirmation code', 'success'));
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    if (err?.response?.data?.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    return false;
  }
};

// Verify Email
export const verifyEmail = (email, emailToken) => async (dispatch) => {
  try {
    const res = await api.post('/users/verify-email', { email, emailToken });
    dispatch(setAlert(res.data.msg, 'success'));
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    if (err?.response?.data?.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    return false;
  }
};

// Send Email To Reset Password
export const sendEmailToResetPassword = (email) => async (dispatch) => {
  try {
    await api.post('/users/reset-password-email', { email });
    dispatch(setAlert('请查看邮箱中的验证码', 'success'));
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    if (err?.response?.data?.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    return false;
  }
};

// Reset Password
export const resetPassword = (email, emailToken, password) => async (dispatch) => {
  try {
    await api.post('/users/reset-password', { email, password, emailToken });
    dispatch(setAlert('Password reset successfully', 'success'));
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    if (err?.response?.data?.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
    return false;
  }
};

export const getPreSignedUrl = async (key, contentType) => {
  try {
    const res = await api.post(`/upload/get-signed-url`, {
      key,
      contentType,
    });
    return res.data;
  } catch (err) {
    return false;
  }
};

// Logout
export const logout = () => async (dispatch) => {
  try {
    const res = await api.post('/auth/logout');
    dispatch(setAlert(res.data.msg, 'success'));
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    if (err?.response?.data?.msg) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    }
  }
  dispatch({ type: RESET_POSTS });
  dispatch({ type: LOGOUT });
  dispatch({ type: CLEAR_MY_PET });
  disconnectSocket();
};
