import React, { useState, useEffect } from 'react';
import MobileInnerNavbar from '../general/navbar/MobileNavbar';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserPosts from './UserPosts';
import PetPosts from './PetPosts';
const PostHome = ({ post }) => {
  const [activeLink, setActiveLink] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const hash = location.hash;
    if (hash === '') {
      setActiveLink('right');
    } else if (hash === '#right') {
      setActiveLink('right');
    } else {
      setActiveLink('left');
    }
  }, [location]);
  const onClickLeftTab = () => {
    navigate('/#left');
  };
  const onClickRightTab = () => {
    navigate('/#right');
  };
  return (
    <>
      <MobileInnerNavbar
        leftName="用户微博"
        rightName="崽崽微博"
        onClickLeftTab={onClickLeftTab}
        onClickRightTab={onClickRightTab}
        defaultActive={activeLink}
      />
      {activeLink === 'right' ? <PetPosts /> : <UserPosts />}
    </>
  );
};

PostHome.propTypes = {
  post: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
});

export default connect(mapStateToProps, {})(PostHome);
