import {
  GET_POSTS,
  POST_ERROR,
  UPDATE_POST_LIKES,
  DELETE_POST,
  ADD_POST,
  ADD_PET_POST,
  GET_POST,
  RESET_POSTS,
  GET_CONTINUED_POSTS,
  ADD_COMMENT,
  REMOVE_COMMENT,
  CLEAR_POST,
  GET_PET_POSTS,
  CLEAR_PET_POSTS,
  UPDATE_POST_VISIBILITY,
} from '../actions/types';

const initialState = {
  posts: [],
  continuePosts: [],
  petPosts: [],
  currentPages: 0,
  title: '',
  post: null,
  loading: true,
  loadedAll: false,
  lastPost: null,
  userPostsLoading: true,
  petPostsLoading: true,
  error: {},
};

function postReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_POSTS:
      return {
        ...state,
        posts: payload,
        continuePosts: payload,
        lastPost: payload[payload.length - 1],
        loading: false,
      };
    case GET_CONTINUED_POSTS: {
      // 把payload.posts加到posts里时确保post的id唯一
      const newPosts = payload.posts.filter((post) => !state.posts.find((p) => p._id === post._id));
      return {
        ...state,
        continuePosts: [...state.continuePosts, ...payload.posts],
        posts: [...state.posts, ...newPosts],
        lastPost: payload.posts[payload.posts.length - 1],
        userPostsLoading: false,
      };
    }
    case GET_PET_POSTS: {
      // 把payload.posts加到posts里时确保post的id唯一
      const newPosts = payload.posts.filter(
        (post) => !state.petPosts.find((p) => p._id === post._id),
      );
      return {
        ...state,
        petPosts: [...state.petPosts, ...newPosts],
        petPostsLoading: false,
      };
    }
    case GET_POST:
      return {
        ...state,
        post: payload,
        loading: false,
      };
    case UPDATE_POST_VISIBILITY:
      return {
        ...state,
        posts: state.posts.map((post) =>
          post._id === payload.id ? { ...post, visible: payload.visible } : post,
        ),
        continuePosts: state.continuePosts.map((post) =>
          post._id === payload.id ? { ...post, visible: payload.visible } : post,
        ),
        petPosts: state.petPosts.map((post) =>
          post._id === payload.id ? { ...post, visible: payload.visible } : post,
        ),
        loading: false,
      };
    case ADD_POST: {
      // 如果是在首页发布的帖子，需要把帖子加到posts里,确保id唯一
      if (state.petPosts.find((post) => post._id === payload._id)) {
        return {
          ...state,
          posts: state.posts.map((post) => (post._id === payload._id ? payload : post)),
          continuePosts: state.continuePosts.map((post) =>
            post._id === payload._id ? payload : post,
          ),
          loading: false,
        };
      }
      return {
        ...state,
        posts: [payload, ...state.posts],
        continuePosts: [payload, ...state.continuePosts],
        loading: false,
      };
    }
    case ADD_PET_POST: {
      // 如果是在首页发布的帖子，需要把帖子加到posts里,确保id唯一
      if (state.petPosts.find((post) => post._id === payload._id)) {
        return {
          ...state,
          petPosts: state.petPosts.map((post) => (post._id === payload._id ? payload : post)),
          continuePosts: state.continuePosts.map((post) =>
            post._id === payload._id ? payload : post,
          ),
          loading: false,
        };
      }
      return {
        ...state,
        petPosts: [payload, ...state.petPosts],
        continuePosts: [payload, ...state.continuePosts],
        loading: false,
      };
    }
    case DELETE_POST:
      return {
        ...state,
        posts: state.posts.filter((post) => post._id !== payload),
        petPosts: state.petPosts.filter((post) => post._id !== payload),
        continuePosts: state.continuePosts.filter((post) => post._id !== payload),
        loading: false,
      };
    case POST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case UPDATE_POST_LIKES: {
      return {
        ...state,
        continuePosts: state.continuePosts.map((post) =>
          post._id === payload.id
            ? {
                ...post,
                isLiked: payload.isLiked,
                likeCount: post.likeCount + (payload.isLiked ? 1 : -1),
              }
            : post,
        ),
        posts: state.posts.map((post) =>
          post._id === payload.id
            ? {
                ...post,
                isLiked: payload.isLiked,
                likeCount: post.likeCount + (payload.isLiked ? 1 : -1),
              }
            : post,
        ),
        petPosts: state.petPosts.map((post) =>
          post._id === payload.id
            ? {
                ...post,
                isLiked: payload.isLiked,
                likeCount: post.likeCount + (payload.isLiked ? 1 : -1),
              }
            : post,
        ),
        post: state.post
          ? {
              ...state.post,
              likeCount: (state.post.likeCount || 0) + (payload.isLiked ? 1 : -1),
              isLiked: payload.isLiked,
            }
          : state.post,
        loading: false,
      };
    }
    case ADD_COMMENT: {
      return {
        ...state,
        post: {
          ...state.post,
          commentCount: (state.post.commentCount || 0) + 1,
        },
        loading: false,
      };
    }
    case REMOVE_COMMENT: {
      return {
        ...state,
        post: {
          ...state.post,
          commentCount: (state.post.commentCount || 0) - 1,
        },
        loading: false,
      };
    }
    case RESET_POSTS: {
      return {
        ...state,
        posts: [],
        continuePosts: [],
        lastPost: null,
        post: null,
        loading: true,
        userPostsLoading: true,
        error: {},
      };
    }
    case CLEAR_POST:
      return {
        ...state,
        post: null,
        loading: true,
        error: {},
      };
    case CLEAR_PET_POSTS:
      return {
        ...state,
        petPosts: [],
        petPostsLoading: true,
        error: {},
      };
    default:
      return state;
  }
}

export default postReducer;
