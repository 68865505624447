import {
  GET_PET,
  GET_PETS,
  GET_MYPET,
  UPDATE_PET,
  PET_ERROR,
  CLEAR_PET,
  CLEAR_PETS,
  CLEAR_MY_PET,
  INCREMENT_PET_EXP,
  GET_INCREMENT_PET_EXP,
  UPDATE_PET_AVATAR,
  GET_ALL_PETS,
  UPDATE_PET_LIKES,
  ADD_PET_COMMENT,
  REMOVE_PET_COMMENT,
} from '../actions/types';

const initialState = {
  pet: null,
  pets: [],
  myPet: null,
  petLoading: true,
  petsLoading: true,
  myPetLoading: true,
  totalPets: 0,
  error: {},
};

function petReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_PET:
    case UPDATE_PET:
      return {
        ...state,
        pet: payload,
        petLoading: false,
        error: {},
      };
    case GET_PETS:
      return {
        ...state,
        pets: payload,
        petsLoading: false,
        error: {},
      };
    case GET_ALL_PETS: {
      return {
        ...state,
        pets: payload.pets,
        totalPets: payload.totalPets,
        petsLoading: false,
        error: {},
      };
    }
    case PET_ERROR:
      return {
        ...state,
        error: payload,
        petLoading: false,
        petsLoading: false,
        pet: null,
      };
    case CLEAR_PET:
      return {
        ...state,
        pet: null,
        petLoading: true,
        error: {},
      };
    case CLEAR_PETS:
      return {
        ...state,
        pets: [],
        totalPets: 0,
        petsLoading: true,
        error: {},
      };
    case CLEAR_MY_PET:
      return {
        ...state,
        myPet: null,
        myPetLoading: true,
        error: {},
      };
    case UPDATE_PET_AVATAR:
      return {
        ...state,
        mypets: state.pets.map((pet) =>
          pet._id === payload.petId ? { ...pet, avatar: payload.avatar.url } : pet,
        ),
        petLoading: false,
        error: {},
      };
    case GET_MYPET:
      return {
        ...state,
        myPet: payload,
        myPetLoading: false,
        error: {},
      };
    case INCREMENT_PET_EXP:
      if (!state.myPet) {
        return state;
      }
      return {
        ...state,
        myPet: { ...state.myPet, activityIndex: state.myPet.activityIndex + payload },
        error: {},
      };
    case GET_INCREMENT_PET_EXP:
      if (!state.myPet) {
        return state;
      }
      return {
        ...state,
        myPet: { ...state.myPet, activityIndex: payload },
        error: {},
      };
    case UPDATE_PET_LIKES:
      return {
        ...state,
        pet:
          state.pet && state.pet._id === payload.petId
            ? {
                ...state.pet,
                isLiked: payload.isLiked,
                profileLikes: state.pet.profileLikes + { true: 1, false: -1 }[payload.isLiked],
              }
            : state.pet,
        myPet:
          state.myPet && state.myPet._id === payload.petId
            ? {
                ...state.myPet,
                isLiked: payload.isLiked,
                profileLikes: state.myPet.profileLikes + { true: 1, false: -1 }[payload.isLiked],
              }
            : state.myPet,
        petLoading: false,
        error: {},
      };
    case ADD_PET_COMMENT: {
      const id = payload.referenceId;
      if (state.pet && state.pet._id === id) {
        return {
          ...state,
          pet: { ...state.pet, profileComments: state.pet.profileComments + 1 },
          petLoading: false,
          error: {},
        };
      }
      return {
        ...state,
        loading: false,
        error: {},
      };
    }
    case REMOVE_PET_COMMENT: {
      const id = payload.data.referenceId;
      if (state.pet && state.pet._id === id) {
        if (payload.data.rootId) {
          return {
            ...state,
            pet: { ...state.pet, profileComments: state.pet.profileComments - 1 },
            loading: false,
            error: {},
          };
        } else {
          return {
            ...state,
            pet: {
              ...state.pet,
              profileComments: state.pet.profileComments - (1 + payload.data.subcommentsNum),
            },
            petLoading: false,
            error: {},
          };
        }
      }
      return {
        ...state,
        petLoading: false,
        error: {},
      };
    }
    default:
      return state;
  }
}

export default petReducer;
