import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import styled from 'styled-components';
import {
  acceptFriendRequest,
  stopFetchFriendRequest,
  resumeFetchFriendRequest,
} from '../../actions/friend';
import MobileHeader from '../general/navbar/MobileHeader';
import { getFriendRequests } from '../../actions/friend';
import Avatar from '../general/avatar/Avatar';

// 样式组件
const Container = styled.section`
  margin-top: 100px;
  padding: 20px;
`;

// const Header = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   padding: 10px 20px;
//   background: #fff;
//   border-bottom: 1px solid #ddd;
// `;

// const HeaderItem = styled.div`
//   flex: 1;
//   text-align: center;
//   font-weight: bold;
// `;

const FriendCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 10px;
  // border: 1px solid #ccc;
  border-radius: 10px;
`;

const FriendInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const FriendName = styled.h4`
  margin: 0;
  font-size: 18px;
`;

const FriendDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 5px;
`;

const Tag = styled.span`
  background: #f0f0f0;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
`;

const ApproveButton = styled.button`
  padding: 5px 10px;
  background-color: #fff;
  color: #333;
  border: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Profiles = ({
  friend: { friendsRequest },
  getFriendRequests,
  acceptFriendRequest,
  stopFetchFriendRequest,
  resumeFetchFriendRequest,
}) => {
  useEffect(() => {
    document.title = '好友请求';
    return () => {
      document.title = 'Zaisland';
    };
  }, []);
  useEffect(() => {
    getFriendRequests();
  }, [getFriendRequests]);

  useEffect(() => {
    stopFetchFriendRequest();
    return () => {
      resumeFetchFriendRequest();
    };
  }, [stopFetchFriendRequest, resumeFetchFriendRequest]);

  const handleClikeOnApprove = (id) => {
    acceptFriendRequest(id);
  };

  return (
    <>
      <MobileHeader pageTitle="好友请求" backLink={true} />
      <Container>
        <div className="my"></div>
        {friendsRequest?.length > 0 ? (
          friendsRequest.map((friend) => {
            const {
              requester: { pet },
            } = friend;
            return (
              <FriendCard key={friend._id}>
                <Avatar to={`/pet-profile/${pet._id}`} src={pet?.avatar} isUser={true} />
                <FriendInfo>
                  <FriendName>{pet.name}</FriendName>
                  <FriendDetails>
                    <Tag>{pet.species}</Tag>
                    <Tag>{pet.profession}</Tag>
                    {pet.tags.length > 0 && (
                      <>
                        {pet.tags.slice(0, 1).map((tag, index) => (
                          <Tag key={index}>{tag}</Tag>
                        ))}
                        {pet.tags.length > 1 && <Tag>+{pet.tags.length - 1}</Tag>}
                      </>
                    )}
                  </FriendDetails>
                </FriendInfo>
                <ApproveButton
                  onClick={() => {
                    handleClikeOnApprove(friend._id);
                  }}
                >
                  通过
                </ApproveButton>
              </FriendCard>
            );
          })
        ) : (
          <Spinner />
        )}
      </Container>
    </>
  );
};

Profiles.propTypes = {
  friend: PropTypes.object.isRequired,
  getFriendRequests: PropTypes.func.isRequired,
  acceptFriendRequest: PropTypes.func.isRequired,
  stopFetchFriendRequest: PropTypes.func.isRequired,
  resumeFetchFriendRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  friend: state.friend,
});

export default connect(mapStateToProps, {
  getFriendRequests,
  acceptFriendRequest,
  stopFetchFriendRequest,
  resumeFetchFriendRequest,
})(Profiles);
