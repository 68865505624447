import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCurrentProfile } from '../actions/profile';
import { getMyPet } from '../actions/pet';
import { joinRoom, getMessages, listenForMessages } from '../actions/message';

const BasicInfoInitializer = ({
  getCurrentProfile,
  auth: { user, isAuthenticated },
  profile: { myProfile },
  pet: { myPet, myPetLoading },
  getMyPet,
  joinRoom,
  getMessages,
  listenForMessages,
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      getCurrentProfile();
      getMyPet();
      joinRoom(user._id);
      listenForMessages(user._id);
      if (window.gtag) {
        window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
          user_id: user._id, // 假设user对象有一个id属性
        });
      }
    }
  }, [getCurrentProfile, joinRoom, getMyPet, listenForMessages, user]);

  useEffect(() => {
    const testMyPet = async () => {
      if (isAuthenticated && !myPetLoading) {
        // after 1s check if myPet is still null
        setTimeout(() => {
          if (!myPet) {
            navigate('/create-pet-profile');
          }
        }, 15);
      }
    };
    testMyPet();
  }, [isAuthenticated, myPetLoading, myPet, navigate]);
  return null;
};

BasicInfoInitializer.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  pet: PropTypes.object.isRequired,
  getMyPet: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  pet: state.pet,
});

export default connect(mapStateToProps, {
  getCurrentProfile,
  getMyPet,
  joinRoom,
  getMessages,
  listenForMessages,
})(BasicInfoInitializer);
