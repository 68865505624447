// Desc: This file contains all the action types that are used in the appliPETion.
// alert
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
// auth
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
// profile
export const GET_PROFILE = 'GET_PROFILE';
export const GET_MY_PROFILE = 'GET_MY_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const GET_REPOS = 'GET_REPOS';
export const NO_REPOS = 'NO_REPOS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
// post
export const GET_POSTS = 'GET_POSTS';
export const GET_CONTINUED_POSTS = 'GET_COUNTINUED_POSTS';
export const GET_POST = 'GET_POST';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_POST_LIKES = 'UPDATE_POST_LIKES';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';
export const ADD_PET_POST = 'ADD_PET_POST';
export const RESET_POSTS = 'RESET_POSTS';
export const VISIBLE_POST = 'VISIBLE_POST';
export const CLEAR_POST = 'CLEAR_POST';
export const GET_PET_POSTS = 'GET_PET_POSTS';
export const CLEAR_PET_POSTS = 'CLEAR_PET_POSTS';
export const UPDATE_POST_VISIBILITY = 'UPDATE_POST_VISIBILITY';
//Comment
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const ADD_PET_COMMENT = 'ADD_PET_COMMENT';
export const REMOVE_PET_COMMENT = 'REMOVE_PET_COMMENT';
export const GET_SUBCOMMENT = 'GET_SUBCOMMENT';
export const GET_COMMENTS = 'GET_COMMENTS';
export const UPDATE_COMMENT_LIKES = 'UPDATE_COMMENT_LIKES';
export const ADD_SUB_COMMENT = 'ADD_SUB_COMMENT';
export const COMMENT_ERROR = 'COMMENT_ERROR';
export const CLEAR_COMMENTS = 'CLEAR_COMMENTS';
// PET
export const GET_PET = 'GET_PET';
export const GET_PETS = 'GET_PETS';
export const GET_ALL_PETS = 'GET_ALL_PETS';
export const GET_MYPET = 'GET_MYPET';
export const UPDATE_PET = 'UPDATE_PET';
export const INCREMENT_PET_EXP = 'INCREMENT_PET_EXP';
export const GET_INCREMENT_PET_EXP = 'GET_INCREMENT_PET_EXP';
export const PET_ERROR = 'PET_ERROR';
export const UPDATE_AVATAR = 'UPDATE_AVATAR';
export const UPDATE_PET_AVATAR = 'UPDATE_PET_AVATAR';
export const UPDATE_PET_LIKES = 'UPDATE_PET_LIKES';
export const CLEAR_PETS = 'CLEAR_PETS';
export const CLEAR_PET = 'CLEAR_PET';
export const CLEAR_MY_PET = 'CLEAR_MY_PET';
// inbox
export const GET_UNREAD_INBOX = 'GET_UNREAD_INBOX';
export const GET_READ_INBOX = 'GET_READ_INBOX';
export const GET_INBOXS = 'GET_INBOXS';
export const REGET_INBOXS = 'REGET_INBOXS';
export const GET_INBOX_BY_TYPE = 'GET_INBOX_BY_TYPE';
export const INBOX_ERROR = 'INBOX_ERROR';
export const READ_ONE_INBOX = 'READ_ONE_INBOX';
export const READ_ALL_INBOX = 'READ_ALL_INBOX';
export const CLEAR_INBOXS = 'CLEAR_INBOXS';
// follow
export const GET_FOLLOWERS = 'GET_FOLLOWERS';
export const GET_FOLLOWINGS = 'GET_FOLLOWINGS';
export const GET_MY_FOLLOWERS = 'GET_MY_FOLLOWERS';
export const GET_MY_FOLLOWINGS = 'GET_MY_FOLLOWINGS';
export const UPDATE_FOLLOWERS = 'UPDATE_FOLLOWERS';
export const ADD_FOLLOWINGS = 'ADD_FOLLOWINGS';
export const REMOVE_FOLLOWINGS = 'REMOVE_FOLLOWINGS';
export const FOLLOW_ERROR = 'FOLLOW_ERROR';
// friend
export const GET_FRIEND = 'GET_FRIEND';
export const GET_MY_FRIENDS = 'GET_MY_FRIENDS';
export const GET_MY_FRIENDS_REQUEST = 'GET_MY_FRIENDS_REQUEST';
export const FRIEND_REQUEST = 'FRIEND_REQUEST';
export const FRIEND_ACCEPT = 'FRIEND_ACCEPT';
export const CLEAR_FRIENDS = 'CLEAR_FRIENDS';
export const CLEAR_FRIEND = 'CLEAR_FRIEND';
export const STOP_FETCH_FRIEND_REQUEST = 'STOP_FETCH_FRIEND_REQUEST';
export const RESUME_FETCH_FRIEND_REQUEST = 'RESUME_FETCH_FRIEND_REQUEST';
// event
export const GET_EVENTS = 'GET_EVENTS';
export const GET_PAST_EVENTS = 'GET_PAST_EVENTS';
export const GET_PASSIVE_EVENTS = 'GET_PASSIVE_EVENTS';
export const GET_LONG_STORY = 'GET_LONG_STORY';
export const GET_ADVENTURE = 'GET_ADVENTURE';
export const GET_SHORT_STORY = 'GET_SHORT_STORY';
export const GET_INTERACTION = 'GET_INTERACTION';
export const CLEAR_EVENT = 'CLEAR_EVENT';
export const CLEAR_EVENTS = 'CLEAR_EVENTS';
// message
export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGE = 'GET_MESSAGE';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const CLEAR_UNREAD_COUNT = 'CLEAR_UNREAD_COUNT';
export const MESSAGE_ERROR = 'MESSAGE_ERROR';
