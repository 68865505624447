import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CommentIcon } from '../icon/Icon';
const CommentContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 1.5rem;
  color: #666;
`;

const CommentCount = styled.span`
  margin-left: 8px;
`;

const CommentButton = ({ CommentNum, text, fontSize = '1.5', iconSize = '24' }) => {
  return (
    <CommentContainer style={{ fontSize: `${fontSize}rem` }}>
      <CommentIcon width={iconSize} height={iconSize} />
      <CommentCount>{CommentNum}</CommentCount>
    </CommentContainer>
  );
};

CommentButton.propTypes = {
  CommentNum: PropTypes.number.isRequired,
};

export default CommentButton;
