import React, { useState } from 'react';
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import styles from './auth.module.css'; // Ensure this path is correct
import FormLoaderSpinner from '../general/loading-spinner/FormLoaderSpinner';
import icon from '../../img/logo/logo.png'; // Ensure this path is correct

const Register = ({ setAlert, register, isAuthenticated }) => {
  const location = useLocation();
  const navigate = useNavigate();

  // Function to parse query parameters
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();

  const [formData, setFormData] = useState({
    username: '',
    email: query.get('email') || '',
    mobile: '',
    gender: '',
    password: '',
    password2: '',
    emailToken: query.get('emailToken') || '',
    agreedToTerms: false,
  });

  const { username, email, mobile, gender, password, password2, agreedToTerms, emailToken } =
    formData;
  const [showPassword, setShowPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isEmailDisabled = query.get('email') ? true : false;

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    validateForm({ ...formData, [name]: value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await register({ username, email, mobile, gender, password, emailToken });
    setIsLoading(false);
    if (res) {
      navigate('/create-pet-profile');
    }
  };

  const validateForm = (currentFormData) => {
    const { email, password, password2, agreedToTerms, username, gender } = currentFormData;
    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && email.length > 0;
    const isPasswordMatch = password === password2 && password.length >= 0;
    setIsValid(username.length > 0 && isEmailValid && isPasswordMatch && agreedToTerms && gender);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <FormLoaderSpinner loading={isLoading} text={'正在注册...'} />
      <section className="container">
        <section className={styles.authContainer}>
          <h1 className={styles.welcomeText}>欢迎来到崽崽岛屿</h1>
          <img className={styles.icon} src={icon} alt=""></img>
          <p className={styles.reminderText}>
            注册成为新用户
            <br />
            请使用你的常用QQ号进行注册，邮箱格式为xx@qq.com
          </p>
          <h1 className={styles.largeText}>注册</h1>
          <form className={styles.form} onSubmit={onSubmit}>
            <div className={styles.formGroup}>
              <input
                type="text"
                placeholder="用户昵称"
                name="username"
                className={styles.inputField}
                value={username}
                onChange={onChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type="email"
                placeholder="邮箱地址"
                name="email"
                className={styles.inputField}
                value={email}
                onChange={onChange}
                required
                disabled={isEmailDisabled} // Disable this input if isEmailDisabled is true
              />
              <div
                className={`${styles.errorMessage} ${
                  !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && email.length > 0
                    ? styles.visible
                    : ''
                }`}
              >
                无效的邮箱格式
              </div>
            </div>
            <div className={styles.formGroup}>
              <input
                type="text"
                placeholder="手机号(便于通知内测信息，不会打扰您)"
                name="mobile"
                className={styles.inputField}
                value={mobile}
                onChange={onChange}
              />
            </div>
            <div className={styles.formGroup}>
              <input
                type={showPassword ? 'text' : 'password'}
                placeholder="密码"
                name="password"
                className={styles.inputField}
                value={password}
                onChange={onChange}
                minLength="6"
                required
              />
              <div
                className={`${styles.errorMessage} ${
                  (password !== password2 && password2.length > 0) ||
                  (password.length < 6 && password.length > 0)
                    ? styles.visible
                    : ''
                }`}
              >
                {password !== password2 && password2.length > 0 && <>密码不匹配</>}
                {password.length < 6 && password.length > 0 && <>密码长度至少为6个字符</>}
              </div>
              <i
                onClick={togglePasswordVisibility}
                className={`${styles.registerPasswordIcon} ${
                  showPassword ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'
                }`}
              ></i>
            </div>
            <div className={styles.formGroup}>
              <input
                type="password"
                placeholder="确认密码"
                name="password2"
                className={styles.inputField}
                value={password2}
                onChange={onChange}
                required
              />
            </div>
            <div className={styles.formGroup}>
              <div className={styles.genderGroup}>
                <label className={styles.genderLabel}>性别:</label>
                <label className={styles.genderOption}>
                  <input
                    type="radio"
                    name="gender"
                    value="male"
                    checked={gender === 'male'}
                    onChange={onChange}
                  />{' '}
                  男
                </label>
                <label className={styles.genderOption}>
                  <input
                    type="radio"
                    name="gender"
                    value="female"
                    checked={gender === 'female'}
                    onChange={onChange}
                  />{' '}
                  女
                </label>
              </div>
            </div>
            <div className={styles.formGroup}>
              <input
                type="checkbox"
                id="terms"
                name="terms"
                checked={agreedToTerms}
                onChange={(e) => {
                  setFormData((prev) => ({ ...prev, agreedToTerms: e.target.checked }));
                  validateForm({ ...formData, agreedToTerms: e.target.checked });
                }}
              />
              <label htmlFor="terms" className={styles.labelTerms}>
                {' '}
                我同意{' '}
                <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                  条款和条件
                </a>
              </label>
            </div>
            <input
              type="submit"
              className={`${styles.loginButton} ${!isValid ? styles.disabledButton : ''}`}
              value="注册"
              disabled={!isValid}
            />
          </form>
          <p className={styles.bottomText}>
            已有账户? <Link to="/login">登录</Link>
          </p>
        </section>
        <div className="my-2"> </div>
        <div className="my-2"> </div>
      </section>
    </>
  );
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, register })(Register);
