import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'antd';
import LevelImage from './modalImage.jpg';

const experienceTable = [
  { level: 1, exp: 0 },
  { level: 2, exp: 5 },
  { level: 3, exp: 10 },
  { level: 4, exp: 20 },
  { level: 5, exp: 30 },
  { level: 6, exp: 50 },
  { level: 7, exp: 70 },
  { level: 8, exp: 100 },
  { level: 9, exp: 150 },
  { level: 10, exp: 200 },
  { level: 11, exp: 300 },
  // { level: 12, exp: 400 },
  // { level: 13, exp: 500 },
];

const Container = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  font-family: Arial, sans-serif;
`;

const LevelInfo = styled.div`
  font-size: 14px;
  display: flex;
  color: #999;
  white-space: nowrap;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
`;

const ProgressBar = styled.div`
  width: ${(props) => props.$progress}%;
  background-color: ${(props) => (props.$progress < 80 ? '#76c7c0' : '#4caf50')};
  height: 20px;
  transition: width 0.3s ease-in-out;
  position: relative;
`;

const ProgressText = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #555;
  line-height: 20px;
`;

const LevelImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  height: auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
`;

const LevelQuestionMark = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  cursor: pointer;
  border-radius: 50%;
  min-width: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  max-width: 100%;
  background: #000;
  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const LevelComponent = ({ currentExp, isOwner }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const sanitizedExp = Math.max(0, currentExp);

  let currentLevelData = experienceTable[0];
  for (let i = experienceTable.length - 1; i >= 0; i--) {
    if (sanitizedExp >= experienceTable[i].exp) {
      currentLevelData = experienceTable[i];
      break;
    }
  }

  const currentLevel = currentLevelData.level;
  const nextLevelData = experienceTable.find((level) => level.exp > sanitizedExp);
  const previousLevelExp = currentLevelData.exp;
  const nextLevelExp = nextLevelData ? nextLevelData.exp : previousLevelExp;
  const maxLevelExp = experienceTable[experienceTable.length - 1].exp;
  const preMaxLevelExp = experienceTable[experienceTable.length - 2].exp;
  const isMaxLevel = !nextLevelData;
  const progress = isMaxLevel
    ? 100
    : ((sanitizedExp - previousLevelExp) / (nextLevelExp - previousLevelExp)) * 100;

  return (
    <>
      <Modal
        onCancel={() => setModalOpen(false)}
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        okText="确认"
        cancelText="取消"
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { width: '100%' } }}
      >
        <LevelImageContainer>
          <img src={LevelImage} alt="Level" />
        </LevelImageContainer>

        <VideoWrapper>
          <video controls>
            <source
              src="https://d1ijtmc6ifbh83.cloudfront.net/public/public_video/奖励机制.mp4"
              type="video/mp4"
            />
          </video>
        </VideoWrapper>
        <p>每日活跃：每日签到*3</p>
        <p>社交媒体互动：点赞（每天5个上限） + 评论+2（每天上限5个） + 发帖+3 （每天上限5个）</p>
        <p>好友 + 4</p>
        <p>故事互动：发起互动+3 报名冒险+2 发起冒险+3 完成冒险+6 临阵脱逃-3 回复崽崽来信+3</p>
        <p>打开订阅消息+10，关闭-10</p>
        <p>邀请好友一起玩+3</p>
      </Modal>
      <Container>
        <LevelQuestionMark onClick={() => setModalOpen(true)}>?</LevelQuestionMark>
        <LevelInfo>LV: {currentLevel}</LevelInfo>
        <ProgressBarContainer>
          {isOwner && (
            <>
              <ProgressBar $progress={progress}></ProgressBar>
              <ProgressText>
                {isMaxLevel ? maxLevelExp - preMaxLevelExp : sanitizedExp - previousLevelExp} /{' '}
                {isMaxLevel ? maxLevelExp - preMaxLevelExp : nextLevelExp - previousLevelExp}
              </ProgressText>
            </>
          )}
        </ProgressBarContainer>
      </Container>
    </>
  );
};

export default LevelComponent;
