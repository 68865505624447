import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Modal, Slider, message } from 'antd';
import AvatarEditor from 'react-avatar-editor';
import { uploadAvatar } from '../../../actions/profile';
import DefaultAvatar from '../../../img/defaultUserImage.jpeg';
import FormLoaderSpinner from '../loading-spinner/FormLoaderSpinner';
import DefaultPetAvatar from '../../../img/defaultPetImage.jpg';

// a kind of yellow to show user is active border for user avatar
const AvatarWrapper = styled.div`
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  cursor: pointer;
  ${(props) =>
    props.$isUser &&
    `
    border: 1px solid rgba(135, 135, 0, 0.8);
    img {
    padding: 2px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  `}
  ${(props) =>
    !props.$isUser &&
    `
    img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  `}
`;

/**
 *
 * @param {*} param0
 * @param {*} param0.to
 * @param {*} param0.src
 *
 * @returns
 */
const Avatar = ({ src, alt = 'avatar', isUser = false, size = 50, uploadAvatar }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editor, setEditor] = useState(null);
  const [scale, setScale] = useState(100);
  const [loading, setLoading] = useState(false);
  let imgSrc =
    src === null || src === undefined || src === ''
      ? isUser
        ? DefaultAvatar
        : DefaultPetAvatar
      : src.startsWith('http')
      ? src
      : process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + '/' + src;
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    await handleSave();
    setSelectedFile(null);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setSelectedFile(null);
    setIsModalOpen(false);
  };

  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        e.target.value = null;
        messageApi.open({
          type: 'error',
          content: '文件大小不能超过10MB',
        });
      } else {
        setSelectedFile(URL.createObjectURL(e.target.files[0]));
      }
    }
  };

  function handleEditorRef(ref) {
    setEditor(ref);
  }

  const handleSave = async () => {
    if (!editor) {
      return;
    }
    const canvas = editor.getImage();
    // const dataUrl = canvas.toDataURL();
    // console.log(dataUrl);
    // await uploadAvatar(dataUrl);
    canvas.toBlob(async (blob) => {
      const file = new File([blob], 'edited-image.jpg', { type: 'image/jpeg' });
      setLoading(true);
      await uploadAvatar(file);
      setLoading(false);
    }, 'image/jpeg');
  };

  return (
    <>
      {loading && <FormLoaderSpinner loading={loading} text={'上传中...'} />}
      {contextHolder}
      <AvatarWrapper
        $isUser={isUser}
        $size={size}
        onClick={(e) => {
          showModal();
        }}
      >
        <img src={imgSrc} alt={alt} />
      </AvatarWrapper>
      <Modal
        title="更换用户头像"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={'上传'}
        cancelText="取消"
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { width: '100%', backgroundColor: 'var(--primary-color)' } }}
      >
        {selectedFile ? (
          <div>
            <AvatarEditor
              ref={handleEditorRef}
              image={selectedFile}
              width={250}
              height={250}
              border={50}
              scale={scale / 100}
            />
            <Slider defaultValue={100} min={100} max={300} onChange={setScale} />
          </div>
        ) : (
          <input type="file" accept="image/*" onChange={handleFileSelect} />
        )}
      </Modal>
    </>
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  isUser: PropTypes.bool,
  size: PropTypes.number,
  uploadAvatar: PropTypes.func.isRequired,
};

export default connect(null, { uploadAvatar })(Avatar);
