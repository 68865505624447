import {
  GET_FRIEND,
  FRIEND_REQUEST,
  FRIEND_ACCEPT,
  CLEAR_FRIEND,
  CLEAR_FRIENDS,
  GET_MY_FRIENDS,
  GET_MY_FRIENDS_REQUEST,
  STOP_FETCH_FRIEND_REQUEST,
  RESUME_FETCH_FRIEND_REQUEST,
} from '../actions/types';

const initialState = {
  friend: {},
  friends: [],
  friendsRequest: [],
  loading: true,
  fetchingRequest: true,
  error: {},
};

function friendReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_FRIEND:
      return {
        ...state,
        friend: payload,
        loading: false,
        error: {},
      };
    case GET_MY_FRIENDS:
      return {
        ...state,
        friends: payload,
        loading: false,
        error: {},
      };
    case GET_MY_FRIENDS_REQUEST:
      return {
        ...state,
        friendsRequest: payload,
        loading: false,
        error: {},
      };
    case FRIEND_REQUEST: {
      return {
        ...state,
        friend: { ...state.friend, isFriend: false, requestSent: true },
        loading: false,
        error: {},
      };
    }
    case FRIEND_ACCEPT:
      return {
        ...state,
        // add the new friend to the list
        // remove the friend request from the list
        friendsRequest: state.friendsRequest.filter((request) => request._id !== payload._id),
        loading: false,
        error: {},
      };
    case CLEAR_FRIEND:
      return {
        ...state,
        friend: null,
        loading: true,
        error: {},
      };
    case CLEAR_FRIENDS:
      return {
        ...state,
        friends: [],
        loading: true,
        error: {},
      };
    case STOP_FETCH_FRIEND_REQUEST:
      return {
        ...state,
        fetchingRequest: false,
      };
    case RESUME_FETCH_FRIEND_REQUEST:
      return {
        ...state,
        fetchingRequest: true,
      };
    default:
      return state;
  }
}

export default friendReducer;
