import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import DefaultAvatar from '../../../img/defaultUserImage.jpeg';
import DefaultPetAvatar from '../../../img/defaultPetImage.jpg';

// a kind of yellow to show user is active border for user avatar
const AvatarWrapper = styled(Link)`
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  ${(props) =>
    props.$isUser &&
    `
    border: 1px solid rgba(135, 135, 0, 0.8);
    img {
    padding: 2px;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  `}
  ${(props) =>
    !props.$isUser &&
    `
    img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
  `}
`;

/**
 *
 * @param {*} param0
 * @param {*} param0.to
 * @param {*} param0.src
 *
 * @returns
 */
const Avatar = ({ to, src, alt = 'avatar', isUser = false, size = 50 }) => {
  let imgSrc =
    src === null || src === undefined || src === ''
      ? isUser
        ? DefaultAvatar
        : DefaultPetAvatar
      : src.startsWith('http')
      ? src
      : process.env.REACT_APP_FILE_DOWNLOAD_APIENDPOINT + '/' + src;

  return (
    <AvatarWrapper to={to} $isUser={isUser} $size={size}>
      <img src={imgSrc} alt={alt} />
    </AvatarWrapper>
  );
};

Avatar.propTypes = {
  to: PropTypes.string.isRequired,
  src: PropTypes.string,
  alt: PropTypes.string,
  isUser: PropTypes.bool,
  size: PropTypes.number,
};

export default Avatar;
