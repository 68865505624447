import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
  getLongStoryById,
  setLongStoryUser1Interest,
  setLongstoryIfWaitingForUser2Feedback,
  setLongStoryUser1Feedback,
} from '../../../actions/event';
import styled from 'styled-components';
import { Modal } from 'antd';
import FormLoaderSpinner from '../../general/loading-spinner/FormLoaderSpinner';
import Avatar from '../../general/avatar/Avatar';
import MobileHeader from '../../general/navbar/MobileHeader';
import timeSince from '../../../utils/calculateDate';
import LongstoryFeedback from './LongstoryFeedback';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const EventCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 95%;
  padding: 5px 5px;
  background-color: #fff;
  border-radius: 5px;
  background-color: #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding-bottom: 30px;
`;

const EventCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
  width: 100%;
`;

const EventCardHeaderTitle = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
`;

const EventCardHeaderTitleHighlight = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ff5d33;
  font-size: 1em;
`;

const EventCardHeaderSelfAvtar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
`;

const EventCardHeaderDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8em;
  color: #999;
  margin-bottom: 5px;
  margin-right: auto;
  margin-left: 30px;
`;

const EventContent = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  color: #333;
  font-size: 0.9em;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
`;

const EventRetrievedCompleteStory = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 5px;
  font-size: 0.8em;
  padding: 3px 5px;
  border: 1px solid #ddd;
  background-color: #ccc;
  margin-left: 30px;
  margin-bottom: 10px;
`;

const InputBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
`;

const EventSelectionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
`;

const EventSelectionButton = styled.button`
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: bold;
  color: ${(props) => (props.$isNegative ? '#ff5d33' : 'white')};
  background-color: ${(props) => (props.$isNegative ? 'white' : '#ff5d33')};
  border: 1px solid #ff5d33;
  margin-top: 10px;
`;

const Profiles = ({
  auth,
  event: { longStory },
  getLongStoryById,
  setLongStoryUser1Interest,
  setLongstoryIfWaitingForUser2Feedback,
  setLongStoryUser1Feedback,
}) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  useEffect(() => {
    document.title = '崽崽来信';
    return () => {
      document.title = 'Zaisland';
    };
  }, []);
  const handleInterest = async (id, interest) => {
    setIsLoading(true);
    const response = await setLongStoryUser1Interest(id, interest);
    if (response) {
      setIsLoading(false);
    }
  };

  const handleSetIfWaitingForUser2Feedback = async (id, isWaitingForUser2Feedback) => {
    setIsLoading(true);
    const response = await setLongstoryIfWaitingForUser2Feedback(id, isWaitingForUser2Feedback);
    if (response) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleGetLongStoryById = async (id) => {
      setIsLoading(true);
      const response = await getLongStoryById(id);
      if (response) {
        setIsLoading(false);
      }
    };
    handleGetLongStoryById(id);
  }, [getLongStoryById, id]);

  if (!longStory) {
    return (
      <>
        <FormLoaderSpinner loading={isLoading} text={'正在加载最新故事...'} />
        <MobileHeader backLink={true} pageTitle={'崽崽来信'} />
      </>
    );
  }

  const {
    _id,
    user1,
    user2,
    round,
    typeCode,
    isContinued,
    userStory,
    user1Interest,
    user1Feedback,
    isWaitingForUser2Feedback,
    createdAt,
  } = longStory;

  const { pet: pet1 } = user1;
  const { pet: pet2 } = user2;

  let isWaitingForInterest = round + 1 > user1Interest.length;
  let isNotInterested = !isWaitingForInterest && !user1Interest[round];
  let isSetUser2WaitingForFeedback = isWaitingForUser2Feedback === null;
  let isWaitingForFeedback = round + 1 > user1Feedback.length;
  let isDuringGeneratingStory = false;
  let isFirstStory = typeCode === 0;

  const status = !isContinued
    ? '故事已结束'
    : isDuringGeneratingStory
    ? '正在生成故事'
    : isNotInterested
    ? '不感兴趣'
    : isWaitingForFeedback
    ? '是否回应'
    : !isFirstStory && isSetUser2WaitingForFeedback && round === 1
    ? '是否需要对方回应'
    : '已回应';

  const date = userStory?.length > 0 ? userStory[userStory.length - 1].createdAt : createdAt;
  const lastStory =
    userStory?.length > 0 ? userStory[userStory.length - 1]?.content : '崽崽还没有写完来信哦~';

  return (
    <>
      <FormLoaderSpinner loading={isLoading} text={'正在更新来信...'} />
      <MobileHeader
        backLink={true}
        pageTitle={`${pet1?.name.slice(0, 4)}${pet1?.name.length > 4 ? '...' : ''}的告别信`}
      />
      <section className="container">
        <Fragment>
          <Modal
            title="感谢你的回应"
            centered
            open={isModalVisible}
            onOk={() => setIsModalVisible(false)}
            okText="确认"
            closeIcon={null}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{
              style: {
                width: '100%',
                color: 'white',
                border: '1px solid #ff5d33',
                backgroundColor: '#ff5d33',
              },
            }}
          >
            <p>
              对方已成功收到你的打招呼啦！请在对方崽崽主页找到主人QQ号，或直接在QQ群里搜索
              {user2?.username}
              找到对方主人聊天吧～
            </p>
          </Modal>
          <Container>
            <EventCardContainer>
              <EventCardHeader>
                <Avatar src={pet1?.avatar} size={40} to={`/pet-profile/${pet1?._id}`} />
                <EventCardHeaderTitle to={`/longstory/${_id}`}>
                  <EventCardHeaderTitleHighlight>{pet1?.name}</EventCardHeaderTitleHighlight>{' '}
                  {isDuringGeneratingStory ? '的昨日日来信' : '的告别信'}
                </EventCardHeaderTitle>
                <EventCardHeaderSelfAvtar>
                  {!isFirstStory && (
                    <Avatar src={pet2?.avatar} size={50} to={`/pet-profile/${pet2?._id}`} />
                  )}
                </EventCardHeaderSelfAvtar>
              </EventCardHeader>
              <EventCardHeaderDate>{timeSince(date)}</EventCardHeaderDate>
              <EventRetrievedCompleteStory to={`/longstory-complete/${_id}`}>
                查看完整来信
              </EventRetrievedCompleteStory>
              <EventContent to={`/longstory/${_id}`}>{lastStory}</EventContent>
            </EventCardContainer>
            <InputBoxContainer>
              {status === '故事已结束' ? (
                <>
                  {' '}
                  故事未完待续，请静候崽崽新的来信哦～
                  <p>
                    <EventCardHeaderTitleHighlight>
                      你的上一次来信的回复：
                    </EventCardHeaderTitleHighlight>
                  </p>
                  <p>
                    {userStory && userStory.length > 0 && userStory[userStory.length - 1].feedback}
                  </p>
                </>
              ) : status === '正在生成故事' ? (
                <>
                  故事未完待续，请静候崽崽新的来信哦～
                  <p>
                    <EventCardHeaderTitleHighlight>
                      你的上一次来信的回复：
                    </EventCardHeaderTitleHighlight>
                  </p>
                  <p>
                    {userStory && userStory.length > 0 && userStory[userStory.length - 1].feedback}
                  </p>
                </>
              ) : status === '是否感兴趣' ? (
                <>
                  <p>是否对这个故事感兴趣？</p>
                  <EventSelectionButtonContainer>
                    <EventSelectionButton
                      onClick={() => {
                        handleInterest(_id, true);
                      }}
                    >
                      感兴趣
                    </EventSelectionButton>
                    <EventSelectionButton
                      onClick={() => {
                        handleInterest(_id, false);
                      }}
                      $isNegative={true}
                    >
                      没兴趣
                    </EventSelectionButton>
                  </EventSelectionButtonContainer>
                </>
              ) : status === '不感兴趣' ? (
                <>你选择了不感兴趣，明天不会收到该故事来信了</>
              ) : status === '是否需要对方回应' ? (
                <>
                  <p>对方主人还不知道两个崽崽的故事哦，你是否想和TA分享呢</p>
                  <EventSelectionButtonContainer>
                    <EventSelectionButton
                      onClick={() => {
                        handleSetIfWaitingForUser2Feedback(_id, true);
                        setIsModalVisible(true);
                      }}
                    >
                      是
                    </EventSelectionButton>
                    <EventSelectionButton
                      onClick={() => {
                        handleSetIfWaitingForUser2Feedback(_id, false);
                      }}
                      $isNegative={true}
                    >
                      否
                    </EventSelectionButton>
                  </EventSelectionButtonContainer>
                </>
              ) : status === '是否回应' ? (
                <>
                  <p>和崽崽的旅程暂时告一段落，你有什么想要对崽崽说的话吗？</p>
                  <LongstoryFeedback
                    id={_id}
                    setIsLoading={() => {
                      setIsLoading(true);
                    }}
                    setNotLoading={() => {
                      setIsLoading(false);
                    }}
                  />
                </>
              ) : status === '已回应' ? (
                <>
                  崽崽回信中～
                  <p>
                    <EventCardHeaderTitleHighlight>你的的回复：</EventCardHeaderTitleHighlight>
                  </p>
                  <p>
                    {user1Feedback &&
                      user1Feedback.length > 0 &&
                      user1Feedback[user1Feedback.length - 1]}
                  </p>
                </>
              ) : (
                <>目前没有什么可以做的了</>
              )}
            </InputBoxContainer>
          </Container>
        </Fragment>
        <div className="my-2"></div>
      </section>
    </>
  );
};

Profiles.propTypes = {
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  getLongStoryById: PropTypes.func.isRequired,
  setLongStoryUser1Interest: PropTypes.func.isRequired,
  setLongstoryIfWaitingForUser2Feedback: PropTypes.func.isRequired,
  setLongStoryUser1Feedback: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  event: state.event,
});

export default connect(mapStateToProps, {
  getLongStoryById,
  setLongStoryUser1Interest,
  setLongstoryIfWaitingForUser2Feedback,
  setLongStoryUser1Feedback,
})(Profiles);
