function timeSince(dateString) {
  const now = new Date();
  const date = new Date(dateString);

  // 获取时间差（以毫秒为单位）
  const diffMs = now - date;

  // 将时间差转换为各种单位
  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));

  // 当前年份
  const diffYears = now.getFullYear() - date.getFullYear();
  // 日期年份
  const dateYear = date.getFullYear();

  // 检查是否是昨天
  const isYesterday = () => {
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    return (
      date.getFullYear() === yesterday.getFullYear() &&
      date.getMonth() === yesterday.getMonth() &&
      date.getDate() === yesterday.getDate()
    );
  };

  // 以所需格式返回时间差
  if (diffYears > 0) {
    return `${dateYear}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(
      date.getDate(),
    ).padStart(2, '0')}`;
  } else if (diffDays > 1) {
    return `${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(
      2,
      '0',
    )}`;
  } else if (isYesterday()) {
    return '昨天';
  } else {
    // 显示hh:mm
    return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(
      2,
      '0',
    )}`;
  }
}

export default timeSince;
