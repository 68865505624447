import api from '../utils/api';
import { setAlert } from './alert';

// create a survey
export const createSurvey = (formData) => async (dispatch) => {
  try {
    const res = await api.post('/survey', formData);

    dispatch(setAlert('问卷提交', 'success'));
    return res.data;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

// get current user's survey
export const getCurrentSurvey = () => async (dispatch) => {
  try {
    const res = await api.get('/survey/me');

    return res.data;
  } catch (err) {
    return false;
  }
};
