import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { adventureSignup, isAdventureSignup } from '../../../actions/event';
import MobileHeader from '../../general/navbar/MobileHeader';
import FormLoaderSpinner from '../../general/loading-spinner/FormLoaderSpinner';
import PetProfiles from './PetProfiles';

const SignUpTitle = styled.h1`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  margin-top: 20px;
  width: 100%;
`;

const EventFeedbackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  height: 40px;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: bold;
  color: white;
  background-color: #ff5d33;
  margin-top: 10px;
  width: 95%;
  border: none;
`;

const EventUser2FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  padding: 5px 5px;
  background-color: #fff;
  border-radius: 5px;
  background-color: #eee;
  margin-bottom: 10px;
  color: #333;
`;

const EventUser2FeedbackHighlight = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ff5d33;
  font-size: 1em;
`;

const InputBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  margin-top: 20px;
`;

const PetsContainer = styled.div`
  display: flex;
  width: 100%;
  min-width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: -100px;
`;

const EventFeedback = ({ adventureSignup, isAdventureSignup }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSignedUp, setIsSubmitting] = useState(false);
  const [isStoryEnd, setIsStoryEnd] = useState(false);
  const now = new Date();
  const currentHour = now.getHours();
  // 0 点到7点之间不允许报名
  const isDisabled = currentHour >= 7 && currentHour < 7;
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const res = await isAdventureSignup();
      setIsSubmitting(res.isSignedUp);
      setIsStoryEnd(res.isStoryEnd);
      setIsLoading(false);
    };
    fetchData();
  }, [isAdventureSignup]);

  const handleSubmit = async () => {
    setIsLoading(true);
    const res = await adventureSignup();
    if (res) {
      setIsLoading(false);
      setIsSubmitting(true);
    }
    setIsLoading(false);
  };
  return (
    <>
      <MobileHeader pageTitle="报名冒险" backLink={true} />
      <FormLoaderSpinner loading={isLoading} />
      <section className="container">
        <InputBoxContainer>
          <SignUpTitle># 欢迎来到冒险发起基地！</SignUpTitle>
          <div className="my" />

          <div>
            你可以直接报名参与明日的冒险，系统将随机匹配一位神秘朋友，与你的崽崽一同探索世界～
          </div>
          <div className="my" />
          <div>
            你也可以选择邀请特定用户发起邀请，用户同意邀请后，你们的崽崽将于次日一起参与冒险！每天仅有一次向非好友发起冒险邀请的机会哦~
          </div>
          <div className="my" />
          <div style={{ textAlign: 'left', width: '100%' }}>明日冒险需要在当日24:00前确认哦！</div>
          <div className="my" />
          {isSignedUp ? (
            <EventUser2FeedbackContainer>
              <EventUser2FeedbackHighlight>已报名明日冒险</EventUser2FeedbackHighlight>
            </EventUser2FeedbackContainer>
          ) : !isStoryEnd ? (
            <EventUser2FeedbackContainer>
              <EventUser2FeedbackHighlight>
                你已有一个正在进行的系统匹配的冒险
              </EventUser2FeedbackHighlight>
            </EventUser2FeedbackContainer>
          ) : isDisabled ? (
            <EventUser2FeedbackContainer>
              <EventUser2FeedbackHighlight>请在7-24点报名冒险</EventUser2FeedbackHighlight>
            </EventUser2FeedbackContainer>
          ) : (
            <EventFeedbackButton onClick={handleSubmit}>直接报名明日冒险</EventFeedbackButton>
          )}
        </InputBoxContainer>
        <div className="my"></div>
        <PetsContainer>
          <h3>选择你的冒险小伙伴吧</h3>
          <div className="my"></div>
          <PetProfiles />
        </PetsContainer>
      </section>
    </>
  );
};

EventFeedback.propTypes = {
  adventureSignup: PropTypes.func.isRequired,
  isAdventureSignup: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { adventureSignup, isAdventureSignup })(EventFeedback);
