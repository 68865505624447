import api from '../utils/api';
import { setAlert } from './alert';

import {
  GET_PET,
  GET_PETS,
  PET_ERROR,
  GET_MYPET,
  CLEAR_PET,
  GET_ALL_PETS,
  UPDATE_PET_LIKES,
  CLEAR_PETS,
  INCREMENT_PET_EXP,
  GET_INCREMENT_PET_EXP,
} from './types';

// Create pet
export const addPet =
  (formData, edit = false) =>
  async (dispatch) => {
    try {
      const res = await api.post('/pets', formData);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      dispatch({
        type: GET_MYPET,
        payload: res.data,
      });
      // await dispatch(setAlert('添加宠物成功', 'success'));
      return true;
    } catch (err) {
      const errors = err?.response?.data?.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }
      dispatch({
        type: PET_ERROR,
        payload: {},
      });
      return false;
    }
  };

// Get current user pets
export const getMyPet = () => async (dispatch) => {
  try {
    const res = await api.get('/pets/me');

    dispatch({
      type: GET_MYPET,
      payload: res.data,
    });
    return true;
  } catch (err) {
    // const errors = err?.response?.data?.errors;
    // if (errors) {
    //   errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    // }
    dispatch({
      type: GET_MYPET,
      payload: null,
    });
    dispatch({
      type: PET_ERROR,
      payload: {},
    });
    return false;
  }
};

// Delete pet
export const deletePet = (id) => async (dispatch) => {
  try {
    const res = await api.delete(`/pets/${id}`);

    dispatch({
      type: CLEAR_PET,
      payload: res.data,
    });

    dispatch(setAlert('Pet Removed', 'success'));
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: PET_ERROR,
      payload: {},
    });
    return false;
  }
};

// update pet
export const updatePet =
  (formData, id, edit = false) =>
  async (dispatch) => {
    try {
      const res = await api.post(`/pets/edit-pet`, formData);
      dispatch({
        type: GET_PET,
        payload: res.data,
      });

      dispatch(setAlert('更新成功', 'success'));
      return true;
    } catch (err) {
      const errors = err?.response?.data?.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }
      dispatch({
        type: PET_ERROR,
        payload: {},
      });
      return false;
    }
  };

// Get pet by ID
export const getPetById = (petId) => async (dispatch) => {
  try {
    const res = await api.get(`/pets/pet/${petId}`);

    dispatch({
      type: GET_PET,
      payload: res.data,
    });
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: PET_ERROR,
      payload: {},
    });
    return false;
  }
};

// Get pet by ID
export const getPetsByUserId = (user_id) => async (dispatch) => {
  try {
    const res = await api.get(`/pets/user/${user_id}`);

    dispatch({
      type: GET_PETS,
      payload: res.data,
    });
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: PET_ERROR,
      payload: {},
    });
    return false;
  }
};

// Get pet by ID
export const getAllPets =
  (pageNum = 1, pageSize = 10, name = '') =>
  async (dispatch) => {
    try {
      const res = await api.get(`/pets/all?pageNum=${pageNum}&pageSize=${pageSize}&name=${name}`);
      dispatch({
        type: GET_ALL_PETS,
        payload: res.data,
      });
      return true;
    } catch (err) {
      const errors = err?.response?.data?.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
      }
      dispatch({
        type: PET_ERROR,
        payload: {},
      });
      return false;
    }
  };

// upload pet avatar
export const uploadPetAvatar = (image, petId) => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append('petId', petId);
    formData.append('avatar', image);
    const res = await api.post(`/pets/avatar`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    dispatch({
      type: GET_PET,
      payload: res.data,
    });
    dispatch(setAlert('上传成功', 'success'));
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: PET_ERROR,
      payload: {},
    });
    return false;
  }
};

// Like a pet
export const likePet = (petId) => async (dispatch) => {
  try {
    await api.post(`/likes/pet/${petId}`);
    dispatch({
      type: UPDATE_PET_LIKES,
      payload: { petId, isLiked: true },
    });
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

// Unlike a pet
export const unlikePet = (petId) => async (dispatch) => {
  try {
    await api.delete(`/likes/pet/${petId}`);
    dispatch({
      type: UPDATE_PET_LIKES,
      payload: { petId, isLiked: false },
    });
    return true;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

// check check in status
export const checkCheckinStatus = () => async (dispatch) => {
  try {
    const res = await api.post('/pets/is-checkin');
    return res.data;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

// check in
export const checkin = () => async (dispatch) => {
  try {
    const res = await api.post('/pets/checkin');
    dispatch(setAlert('签到成功，经验值+3', 'success'));
    dispatch({
      type: INCREMENT_PET_EXP,
      payload: 3,
    });
    return res.data;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

// increment-activity-index
export const incrementActivityIndex = (index) => async (dispatch) => {
  try {
    const res = await api.post(`/pets/increment-activity-index`, {
      index,
    });
    dispatch({
      type: INCREMENT_PET_EXP,
      payload: index,
    });
    return res.data;
  } catch (err) {
    const errors = err?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, 'danger')));
    }
    return false;
  }
};

// get increment-activity-index
export const getActivityIndex = () => async (dispatch) => {
  try {
    const res = await api.get(`/pets/activity-index`);
    dispatch({
      type: GET_INCREMENT_PET_EXP,
      payload: res.data.activityIndex,
    });
    return res.data;
  } catch (err) {
    return false;
  }
};

// increment pet exp
export const incrementPetExp = (exp) => async (dispatch) => {
  try {
    dispatch({
      type: INCREMENT_PET_EXP,
      payload: exp,
    });
    return true;
  } catch (err) {
    return false;
  }
};

// clear pet
export const clearPet = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PET,
  });
};

// clear pets
export const clearPets = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PETS,
  });
};
