import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getPastEvents, clearEvents } from '../../actions/event';
import EventCard from './EventCard';
import styled from 'styled-components';
import SpinnerObserver from '../general/loading-spinner/SpinnerObserver';
import ClickToLoad from '../general/loading-spinner/ClickToLoad';
import { useThrottle } from '../../utils/debounce';

const Container = styled.section`
  padding: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Profiles = ({
  getPastEvents,
  clearEvents,
  event: { pastEvents, pastEventsLoading, date },
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isMore, setIsMore] = useState(true);

  useEffect(() => {
    document.title = '历史故事';
    return () => {
      document.title = 'Zaisland';
    };
  }, []);

  const fetchMoreEvents = async (lastDate) => {
    const res = await getPastEvents(lastDate);
    if (res && res.length > 0) {
      setIsMore(true);
    } else if (lastDate > new Date('2022-01-01')) {
      // 假设这个日期是你设定的最早日期限制
      lastDate.setDate(lastDate.getDate() - 1);
      await fetchMoreEvents(lastDate); // 递归调用以继续获取前一天的事件
    } else {
      setIsMore(false);
    }
  };

  const handleLoadmoreEvents = useThrottle(async () => {
    if (isFetching) return;
    setIsFetching(true);
    if (date) {
      let lastDate = new Date(date);
      lastDate.setDate(lastDate.getDate() - 1);
      await fetchMoreEvents(lastDate);
    } else {
      const now = new Date();
      await fetchMoreEvents(now);
    }
    setIsFetching(false);
  }, 500);

  const handleRefresh = async () => {
    setIsRefreshing(true);
    await clearEvents();
    setIsRefreshing(false);
  };

  return (
    <>
      <section className="container">
        <div className="my"></div>
        <Container>
          {pastEvents && (
            <>
              {pastEvents.length <= 0 && pastEventsLoading ? (
                <SpinnerObserver isFetching={isRefreshing} onIntersect={handleLoadmoreEvents} />
              ) : (
                <>
                  {pastEvents.length <= 0 && !pastEventsLoading ? (
                    <>
                      {' '}
                      <h4>加载历史故事中～</h4> <div className="my"></div>
                    </>
                  ) : (
                    <>
                      <ClickToLoad
                        isFetching={isRefreshing}
                        isLoading={pastEventsLoading}
                        onClick={handleRefresh}
                        text="加载新的历史故事"
                        size={0.8}
                      />
                      <div className="my"></div>
                      {pastEvents.map((event) => (
                        <EventCard key={event._id} event={event} />
                      ))}
                      {isMore && (
                        <>
                          <SpinnerObserver
                            isFetching={isFetching}
                            onIntersect={handleLoadmoreEvents}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Container>
        <div className="my-2"></div>
      </section>
    </>
  );
};

Profiles.propTypes = {
  event: PropTypes.object.isRequired,
  getPastEvents: PropTypes.func.isRequired,
  clearEvents: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  event: state.event,
});

export default connect(mapStateToProps, { getPastEvents, clearEvents })(Profiles);
