import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { updateAdventureUserFeedback } from '../../../actions/event';

const EventFeedbackTextArea = styled.textarea`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: bold;
  color: #333;
  background-color: #eee;
  margin-top: 10px;
`;

const EventFeedbackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.8em;
  font-weight: bold;
  color: white;
  background-color: #ff5d33;
  margin-top: 10px;
  width: 100%;
  border: none;
`;

const EventFeedback = ({
  updateAdventureUserFeedback,
  setIsLoading,
  setNotLoading,
  id,
  feedbackPlaceholder,
}) => {
  const [feedback, setFeedback] = useState('');
  const [userSecret, setUserSecret] = useState('');
  const handleSubmit = async () => {
    setIsLoading();
    await updateAdventureUserFeedback(id, feedback, userSecret);
    setNotLoading();
  };
  return (
    <Fragment>
      <h4>给崽崽的指令</h4>

      <EventFeedbackTextArea
        type="text"
        rows={4}
        placeholder={feedbackPlaceholder}
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
      />
      <div className="my"></div>
      <h4>和你的小伙伴约定个暗号吧！</h4>
      <EventFeedbackTextArea
        type="text"
        rows={2}
        placeholder="和对方崽崽的主人约定个暗号吧！暗号必须一致，两只崽崽才能继续冒险哦。需要是2-5个纯简体汉字哦！比如“仙女下班”"
        value={userSecret}
        onChange={(e) => setUserSecret(e.target.value)}
      />
      <EventFeedbackButton onClick={handleSubmit}>提交回应</EventFeedbackButton>
    </Fragment>
  );
};

EventFeedback.propTypes = {
  updateAdventureUserFeedback: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setNotLoading: PropTypes.func.isRequired,
};

export default connect(null, { updateAdventureUserFeedback })(EventFeedback);
