import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import { getAllPets } from '../../../actions/pet';
import styled from 'styled-components';
import PetCard from '../../profiles/PetCard';
import { Input } from 'antd';

const { Search } = Input;
const StyledSearch = styled(Search)`
  width: 90%;
  &:hover,
  &:focus {
    background: #f9f9f9;
  }
`;

const Container = styled.section`
  padding: 0px;
  width: 100%;
`;
const Header = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 1fr 1fr 2fr;
  padding: 10px 20px;
  background: #fff;
  color: #999;
  & > div {
    border-bottom: 1px solid #ddd;
  }
`;

const HeaderItem = styled.div`
  text-align: center;
  font-weight: bold;
`;

const Profiles = ({ pet: { pets, totalpets, petsLoading }, getAllPets }) => {
  const [filteredPets, setFilteredPets] = useState([]);

  useEffect(() => {
    getAllPets();
  }, [getAllPets]);

  useEffect(() => {
    setFilteredPets(pets);
  }, [pets]);

  const handleSearch = (value) => {
    const filtered = pets.filter((pet) => pet.name.toLowerCase().includes(value.toLowerCase()));
    setFilteredPets(filtered);
  };

  return (
    <>
      <StyledSearch placeholder="输入崽崽名字" allowClear onSearch={handleSearch} />
      <div className="my"></div>
      <Container>
        {filteredPets?.length > 0 && (
          <Header>
            <HeaderItem></HeaderItem>
            <HeaderItem>名字</HeaderItem>
            <HeaderItem>物种</HeaderItem>
            <HeaderItem>职业</HeaderItem>
            <HeaderItem>标签</HeaderItem>
          </Header>
        )}
        {filteredPets === null ? (
          <>
            <Spinner />
          </>
        ) : (
          <Fragment>
            <div className="profiles">
              {filteredPets.length > 0 ? (
                filteredPets.map((pet) => <PetCard key={pet._id} pet={pet} />)
              ) : (
                <>
                  <Spinner />
                </>
              )}
            </div>
          </Fragment>
        )}
      </Container>
      <div className="my-2"></div>
    </>
  );
};

Profiles.propTypes = {
  getAllPets: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  pet: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  pet: state.pet,
});

export default connect(mapStateToProps, { getAllPets })(Profiles);
