import React, { useEffect, useState } from 'react';
import { incrementActivityIndex } from '../../actions/pet';
import store from '../../store';

const ToggleSubscription = ({ user }) => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isSupported, setIsSupported] = useState(false);

  const styles = {
    toggleSwitch: {
      position: 'relative',
      width: '50px',
      height: '25px',
    },
    toggleInput: {
      opacity: 0,
      width: 0,
      height: 0,
    },
    toggleLabel: {
      position: 'absolute',
      cursor: 'pointer',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#ccc',
      transition: '0.4s',
      borderRadius: '25px',
    },
    toggleLabelChecked: {
      backgroundColor: '#2196F3',
    },
    toggleLabelBefore: {
      position: 'absolute',
      content: '""',
      height: '21px',
      width: '21px',
      left: '2px',
      bottom: '2px',
      backgroundColor: 'white',
      transition: '0.4s',
      borderRadius: '50%',
    },
    toggleLabelCheckedBefore: {
      transform: 'translateX(24px)',
    },
  };

  useEffect(() => {
    const OneSignalDeferred = window.OneSignalDeferred || [];
    try {
      OneSignalDeferred.push(function (OneSignal) {
        if (OneSignal.User.PushSubscription.optedIn && OneSignal.User.PushSubscription.id) {
          setIsSubscribed(true);
        } else {
          setIsSubscribed(false);
        }
      });
      OneSignalDeferred.push(function (OneSignal) {
        const isSupported = OneSignal.Notifications.isPushSupported();
        setIsSupported(isSupported);
      });
    } catch (error) {
      console.error('OneSignal could not be initialized:', error);
    }
  }, []);

  const handleToggleSubscription = async () => {
    const OneSignalDeferred = window.OneSignalDeferred || [];
    try {
      if (isSubscribed) {
        OneSignalDeferred.push(function (OneSignal) {
          OneSignal.User.PushSubscription.optOut();
        });
        setIsSubscribed(false);
        store.dispatch(incrementActivityIndex(-10));
      } else {
        if (!user) {
          return;
        }
        OneSignalDeferred.push(function (OneSignal) {
          OneSignal.login(user._id);
        });

        OneSignalDeferred.push(function (OneSignal) {
          OneSignal.Notifications.requestPermission();
        });

        OneSignalDeferred.push(function (OneSignal) {
          OneSignal.User.PushSubscription.optIn();
        });
        store.dispatch(incrementActivityIndex(10));
        setIsSubscribed(true);
      }
    } catch (error) {
      console.error('OneSignal could not be initialized:', error);
    }
  };

  return (
    <div>
      {isSupported ? <p>订阅我们的最新消息</p> : <p>此平台不支持订阅</p>}
      <div style={styles.toggleSwitch}>
        <input
          type="checkbox"
          id="subscription-toggle"
          style={styles.toggleInput}
          checked={isSubscribed}
          onChange={handleToggleSubscription}
        />
        <label
          htmlFor="subscription-toggle"
          style={{
            ...styles.toggleLabel,
            ...(isSubscribed && styles.toggleLabelChecked),
          }}
        >
          <span
            style={{
              ...styles.toggleLabelBefore,
              ...(isSubscribed && styles.toggleLabelCheckedBefore),
            }}
          ></span>
        </label>
      </div>
    </div>
  );
};

export default ToggleSubscription;
