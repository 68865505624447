import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'antd';

const STORAGE_KEY = 'tutorialModalLastClosed';
const DAYS_TO_SKIP = 0.01;

const TutorialModal = ({ forceOpen, onOkCallBack }) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const videoRef = useRef(null);
  const countdownRef = useRef(null);

  useEffect(() => {
    const lastClosed = localStorage.getItem(STORAGE_KEY);
    const now = new Date().getTime();

    if (forceOpen || !lastClosed || now - lastClosed > DAYS_TO_SKIP * 24 * 60 * 60 * 1000) {
      setIsModalVisible(true);
    }
  }, [forceOpen]);

  useEffect(() => {
    if (isModalVisible) {
      countdownRef.current = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(countdownRef.current);
            setIsButtonDisabled(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(countdownRef.current);
    };
  }, [isModalVisible]);

  const handleOk = () => {
    setIsModalVisible(false);
    localStorage.setItem(STORAGE_KEY, new Date().getTime());
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
    if (onOkCallBack && typeof onOkCallBack === 'function') {
      onOkCallBack();
    }
    navigate('/stories');
  };

  return (
    <Modal
      title="崽崽特急来信！"
      open={isModalVisible}
      closable={false}
      footer={[
        <Button
          key="ok"
          type="primary"
          onClick={handleOk}
          disabled={isButtonDisabled}
          style={{ width: '100%' }}
        >
          {isButtonDisabled ? `请耐心看完通知哦 (${countdown}s)` : '去看崽崽的来信吧!'}
        </Button>,
      ]}
      width="100%"
    >
      <p>崽崽发来一封特急信件，据说与崽崽世界和人类世界的信息传输密道有关，快去看看！</p>
    </Modal>
  );
};

export default TutorialModal;
