import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getEventsContinued, getPassiveEvents } from '../../actions/event';
import EventCard from './EventCard';
import styled from 'styled-components';

const Container = styled.section`
  padding: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Profiles = ({
  getEventsContinued,
  getPassiveEvents,
  event: { events, passiveEvents, eventsLoading },
}) => {
  useEffect(() => {
    getEventsContinued();
    getPassiveEvents();
    document.title = '今日故事';
    return () => {
      document.title = 'Zaisland';
    };
  }, [getEventsContinued, getPassiveEvents]);
  return (
    <>
      <section className="container">
        <div className="my"></div>
        <Container>
          {events && (
            <>
              {events.length <= 0 && eventsLoading ? (
                <Spinner />
              ) : (
                <>
                  {events.length <= 0 && !eventsLoading ? (
                    <>
                      <h4>今日来信送达中，请耐心等待～</h4>
                      <div className="my-2"></div>
                    </>
                  ) : (
                    <>
                      {events.map((event) => (
                        <EventCard key={event._id} event={event} />
                      ))}
                    </>
                  )}
                </>
              )}
              <EventCard
                event={{
                  eventType: 'Tutorial',
                  title: '创始人的来信',
                  text: '感谢大家与我们一同经历这段奇妙旅程。世界很大，我们的孤独与渺小在其映衬下格外凸显；世界又很小，我们时常感到被逼仄的现实困住而失去太多可能....',
                  videoUrl:
                    'https://d1ijtmc6ifbh83.cloudfront.net/public/public_video/新手教程.mp4',
                }}
              />
            </>
          )}
        </Container>
        <div className="my-2"></div>
      </section>
    </>
  );
};

Profiles.propTypes = {
  event: PropTypes.object.isRequired,
  getEventsContinued: PropTypes.func.isRequired,
  getPassiveEvents: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  event: state.event,
});

export default connect(mapStateToProps, { getEventsContinued, getPassiveEvents })(Profiles);
