import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import { getMyPet } from '../../actions/pet';
import { clearComments } from '../../actions/comment';
import { logout } from '../../actions/auth';
import Pet from '../profile/Pet';
import Spinner from '../layout/Spinner';
import UserProfile from '../profile/UserProfile';
import CommentList from '../profile/CommentList';
import PostList from '../profile/PostList';
import MobileHeader from '../general/navbar/MobileHeader';
import LikeCommnetSwitch from '../general/toggleButton/LikeCommentSwitch';
import store from '../../store';

const Dashboard = ({
  getCurrentProfile,
  deleteAccount,
  auth: { user },
  profile: { myProfile },
  pet: { myPet, myPetLoading },
  logout,
  getMyPet,
}) => {
  const [showPosts, setShowPosts] = useState(false);
  useEffect(() => {
    document.title = 'Zaisland | 我的崽崽主页';
    return () => {
      document.title = 'Zaisland';
    };
  }, []);
  useEffect(() => {
    getCurrentProfile();
    getMyPet();
    return () => {
      store.dispatch(clearComments());
    };
  }, [getCurrentProfile, getMyPet]);

  return (
    <>
      <MobileHeader pageTitle="我的崽崽主页" backLink={false} />
      <section className="container">
        {myPetLoading && myPet === null ? (
          <Spinner />
        ) : (
          <>
            {!myPetLoading && myPet === null ? (
              <>
                <h2 className="text-light">
                  <i className="fa-solid fa-triangle-exclamation"></i>
                </h2>
                <h2 className="text-light">还没有崽崽，请添加信息</h2>
                <Link className="btn btn-primary my-1" to="/create-pet-profile">
                  添加崽崽信息
                </Link>
                <div className="my-2"></div>
              </>
            ) : (
              <>
                <Pet pet={myPet} userProfile={myProfile} />
              </>
            )}
          </>
        )}

        <UserProfile user={user} />
        <LikeCommnetSwitch
          onSetComments={() => setShowPosts(false)}
          onSetPosts={() => setShowPosts(true)}
          showPosts={showPosts}
        />
        {showPosts ? (
          <>{myPet?.user?._id ? <PostList userId={myPet.user._id} /> : <></>}</>
        ) : (
          <>
            <div style={{ width: '95%' }}>
              {myPet?._id && <CommentList entityId={myPet._id} commentType={'pet'} />}
            </div>

            <div className="my-2"></div>
          </>
        )}
        <div className="my-2" />
        <div className="my-2" />
        <div className="my-2" />
      </section>
    </>
  );
};

Dashboard.propTypes = {
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  pet: PropTypes.object.isRequired,
  getMyPet: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  profile: state.profile,
  pet: state.pet,
});

export default connect(mapStateToProps, { getMyPet, getCurrentProfile, deleteAccount, logout })(
  Dashboard,
);
