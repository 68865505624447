import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Navbar from './components/layout/Navbar';
import MobileNavbar from './components/layout/MobileNavbar';
import Landing from './components/layout/Landing';
import EmailSubmission from './components/auth/EmailVerify/EmailSubmission';
import VerifyEmail from './components/auth/EmailVerify/VerifyEmail';
import Register from './components/auth/Register';
import Login from './components/auth/Login';
import AuthHandler from './components/auth/AuthHandler';
import ResetPwdEmailSubmission from './components/auth/PasswordRest/ResetPwdEmailSubmission';
import ResetPwdVerifyEmail from './components/auth/PasswordRest/ResetPwdVerifyEmail';
import ResetPassword from './components/auth/PasswordRest/ResetPassword';
import TermsAndConditions from './components/auth/Terms/TermsAndConditions';
import Alert from './components/layout/Alert';
import Dashboard from './components/dashboard/Dashboard';
import PetProfileForm from './components/profile-forms/PetProfileForm';
import NotFound from './components/layout/NotFound';
import PrivateRoute from './components/routing/PrivateRoute';
import AdminRoute from './components/routing/AdminRoute';
import { LOGOUT } from './actions/types';
// post
import PostHome from './components/posts/PostHome';
import PostPage from './components/posts/PostPage';
import PostForm from './components/posts/Postform';
import PetPostform from './components/posts/PetPostform';
// PetProfiles
import PetProfilesHome from './components/profiles/PetProfilesHome';
import PetProfile from './components/profile/PetProfile';
import FriendRequestList from './components/friend/FriendRequestList';
// Story
import StoryMailbox from './components/mailbox/EventMailbox';
import Longstory from './components/mailbox/Longstory/Longstory';
import LongstoryComplete from './components/mailbox/Longstory/LongstoryComplete';
import LongstoryPassiveUserFeedback from './components/mailbox/Longstory/LongstoryPassiveUserFeedback';
import AdventureSignup from './components/mailbox/Adventure/AdventureSignup';
import Adventure from './components/mailbox/Adventure/Adventure';
import AdventureComplete from './components/mailbox/Adventure/AdventureComplete';
import ShortStory from './components/mailbox/ShortStory/ShortStory';
import Interaction from './components/mailbox/Interaction/Interaction';
// Mailbox
import SystemMailbox from './components/mailbox/SystemMailbox';
// Message
import Message from './components/friend/message/Message';
// Setttings
import Setting from './components/dashboard/Setting';
// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

//OneSignal
// import runOneSignal from './utils/onesignal';
//init basic info
import BasicInfoInitializer from './init/BasicInfoInitializer';
// Listener
import ScrollToTop from './listeners/ScrollToTop/ScrollToTop';
import MessageListener from './listeners/MessageListener/MessageListener';
import InstructionListener from './listeners/MessageListener/InstructionListener';
// import StateRefresher from './listeners/StateRefresher/StateRefresher';

import './App.css';

const App = () => {
  useEffect(() => {
    // check for token in LS when app first runs
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API
    store.dispatch(loadUser());
    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  // useEffect(() => {
  //   runOneSignal();
  // });

  return (
    <Provider store={store}>
      <Router>
        <Alert />
        <BasicInfoInitializer />
        <ScrollToTop />
        <MessageListener />
        <InstructionListener />
        {/* <StateRefresher /> */}
        <MobileNavbar />
        <Routes>
          {/* auth */}
          <Route path="/landing" element={<Landing />} />
          <Route path="/email-submission" element={<EmailSubmission />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="register" element={<Register />} />
          <Route path="login" element={<Login />} />
          <Route path="auth-handler" element={<AuthHandler />} />
          <Route path="reset-password-email-submission" element={<ResetPwdEmailSubmission />} />
          <Route path="reset-password-verify-email" element={<ResetPwdVerifyEmail />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
          <Route path="/setting" element={<PrivateRoute component={Setting} />} />
          {/* post */}
          <Route path="/" element={<PrivateRoute component={PostHome} />} />
          <Route path="/post/:id" element={<PrivateRoute component={PostPage} />} />
          <Route path="/create-post" element={<PrivateRoute component={PostForm} />} />
          <Route path="/create-pet-post" element={<AdminRoute component={PetPostform} />} />
          {/* PetProfiles */}
          <Route path="/create-pet-profile" element={<PrivateRoute component={PetProfileForm} />} />
          <Route path="/pet-profiles" element={<PrivateRoute component={PetProfilesHome} />} />
          <Route path="/pet-profile/:id" element={<PrivateRoute component={PetProfile} />} />
          <Route path="/friend-requests" element={<PrivateRoute component={FriendRequestList} />} />
          {/* story */}
          <Route path="/stories" element={<PrivateRoute component={StoryMailbox} />} />
          <Route path="/longstory/:id" element={<PrivateRoute component={Longstory} />} />
          <Route
            path="/longstory-passive-user-feedback/:id"
            element={<PrivateRoute component={LongstoryPassiveUserFeedback} />}
          />
          <Route
            path="/longstory-complete/:id"
            element={<PrivateRoute component={LongstoryComplete} />}
          />
          <Route path="/adventure-signup" element={<PrivateRoute component={AdventureSignup} />} />
          <Route path="/adventure/:id" element={<PrivateRoute component={Adventure} />} />
          <Route
            path="/adventure-complete/:id"
            element={<PrivateRoute component={AdventureComplete} />}
          />
          <Route path="/short-story/:id" element={<PrivateRoute component={ShortStory} />} />
          <Route path="/interaction/:id" element={<PrivateRoute component={Interaction} />} />
          {/* mailbox */}
          <Route path="/mailbox" element={<PrivateRoute component={SystemMailbox} />} />
          <Route path="/*" element={<NotFound />} />
          {/* message */}
          <Route path="/message/:id" element={<PrivateRoute component={Message} />} />
        </Routes>

        {/* <MobileNavbar /> */}
      </Router>
    </Provider>
  );
};

export default App;
