import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { getLongStoryById, clearEvent } from '../../../actions/event';
import store from '../../../store';
import styled from 'styled-components';
import FormLoaderSpinner from '../../general/loading-spinner/FormLoaderSpinner';
import Avatar from '../../general/avatar/Avatar';
import MobileHeader from '../../general/navbar/MobileHeader';
import timeSince from '../../../utils/calculateDate';
const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
const EventCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 95%;
  padding: 5px 5px;
  background-color: #fff;
  border-radius: 5px;
  background-color: #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding-bottom: 30px;
`;

const EventCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px;
  width: 100%;
`;

const EventCardHeaderTitle = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9em;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
`;

const EventCardHeaderTitleHighlight = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #ff5d33;
  font-size: 1em;
`;

const EventCardHeaderDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 0.8em;
  color: #999;
  margin-bottom: 5px;
  margin-right: auto;
  margin-left: 30px;
`;

const EventContent = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  color: #333;
  font-size: 0.9em;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  overflow-wrap: break-word;
`;

const Profiles = ({ auth, event: { longStory }, getLongStoryById }) => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleGetLongStoryById = async (id) => {
      setIsLoading(true);
      const response = await getLongStoryById(id);
      if (response) {
        setIsLoading(false);
      }
    };
    handleGetLongStoryById(id);
    return () => {
      store.dispatch(clearEvent());
    };
  }, [getLongStoryById, id]);

  if (!longStory) {
    return (
      <>
        <FormLoaderSpinner loading={isLoading} text={'正在加载最新故事...'} />
        <MobileHeader backLink={true} pageTitle={'崽崽来信'} />
      </>
    );
  }

  const { user1, userStory, createdAt } = longStory;
  const { pet: pet1 } = user1;

  const date = userStory?.length > 0 ? userStory[userStory.length - 1].createdAt : createdAt;

  return (
    <>
      <FormLoaderSpinner loading={isLoading} text={'正在更新故事...'} />
      <MobileHeader
        backLink={true}
        pageTitle={`${pet1?.name.slice(0, 4)}${pet1?.name.length > 4 ? '...' : ''}的完整来信`}
      />
      <section className="container">
        <Fragment>
          <Container>
            {userStory && userStory.length > 0 && (
              <>
                {userStory.map((story) => (
                  <Fragment key={story._id}>
                    <EventCardContainer>
                      <EventCardHeader>
                        <Avatar src={pet1?.avatar} size={40} to={`/pet-profile/${pet1?._id}`} />
                        <EventCardHeaderTitle to={``}>
                          <EventCardHeaderTitleHighlight>
                            {pet1?.name}
                          </EventCardHeaderTitleHighlight>{' '}
                          {` 的来信`}
                        </EventCardHeaderTitle>
                      </EventCardHeader>
                      <EventCardHeaderDate>{timeSince(date)}</EventCardHeaderDate>
                      <EventContent to={``}>
                        <div>{story.content}</div>
                      </EventContent>
                      <EventCardHeader>
                        <Avatar src={user1.avatar} size={40} to={`/pet-profile/${pet1?._id}`} />
                        <EventCardHeaderTitle to={``}>
                          <EventCardHeaderTitleHighlight>你的回复</EventCardHeaderTitleHighlight>{' '}
                        </EventCardHeaderTitle>
                      </EventCardHeader>
                      <EventContent to={``}>
                        <div>{story.feedback ? story.feedback : '还没回复'}</div>
                      </EventContent>
                    </EventCardContainer>
                  </Fragment>
                ))}
              </>
            )}
          </Container>
        </Fragment>
        <div className="my-2"></div>
      </section>
    </>
  );
};

Profiles.propTypes = {
  auth: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  getLongStoryById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  event: state.event,
});

export default connect(mapStateToProps, { getLongStoryById })(Profiles);
