import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import icon from '../../img/logo/logo.png';
// import qqIcon from '../../img/qq_icon.png';

import styles from './auth.module.css';

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { email, password } = formData;
  const [showPassword, setShowPassword] = useState(false);
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  const togglePasswordVisiblity = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  if (isAuthenticated) {
    return <Navigate to="/stories" />;
  }

  return (
    <section className="container">
      <section className={styles.authContainer}>
        <img className={styles.icon} src={icon} alt=""></img>
        <h1 className={styles.largeText}>登录</h1>
        <form className={styles.form} onSubmit={onSubmit}>
          <div className={styles.formGroup}>
            <input
              type="email"
              placeholder="邮箱地址"
              name="email"
              className={styles.inputField}
              value={email}
              onChange={onChange}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <input
              type={showPassword ? 'text' : 'password'}
              placeholder="密码"
              name="password"
              className={styles.inputField}
              value={password}
              onChange={onChange}
              minLength="6"
              required
            />
            <i
              onClick={togglePasswordVisiblity}
              className={`${styles.passwordIcon} ${
                showPassword ? 'fa-regular fa-eye' : 'fa-regular fa-eye-slash'
              }`}
            ></i>
          </div>
          <input type="submit" className={styles.loginButton} value="登录" />
        </form>
        <p className={styles.bottomText}>
          <Link to="/reset-password-email-submission" className={styles.forgetPasswordLink}>
            忘记密码?
          </Link>
        </p>
        <p className={styles.newAccountText}>如果您还没有账户，请创建一个。</p>
        <Link to="/register" className={styles.registerButton}>
          创建账号
        </Link>
        <div className={styles.divider}>
          <div className={styles.dividerLine} />
          <div className={styles.dividerLine} />
        </div>
        <div className={styles.socialLogin}>
          {/* <a
            href={`${process.env.REACT_APP_BACKEND_APIENDPOINT}/users/auth/google`}
            className={styles.googleLoginButton}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={qqIcon} alt="QQ" className={styles.googleIcon} />
            <span className={styles.googleText}>使用 QQ 登录</span>
          </a> */}
        </div>
      </section>
    </section>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
