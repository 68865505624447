import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { likePet, unlikePet } from '../../actions/pet';
import LikeButton from '../general/like/LikeButton';
import AvatarZoom from '../general/avatar/AvatarZoom';
import FriendButton from '../general/friend/FriendButton';
import AdventureRequestButton from '../mailbox/Adventure/AdventureRequestButton';
import InteractionRequestButton from '../mailbox/Interaction/InteractionRequestButton';
import CheckinButton from '../profile/level/CheckinButton';
import Level from '../profile/level/Level';
import InstructionTutor from '../../listeners/MessageListener/InstructionTutor';

const PetContainer = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const PetContainerBorder = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: 1px solid #ddd;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 33.33%;
`;

const PetInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const PetName = styled.h2`
  margin: 0;
  font-size: 1.3rem;
  white-space: nowrap;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
`;

const PetDetails = styled.div`
  display: flex;
  margin-top: 5px;
  font-size: 0.7rem;
  color: #999;

  & > div {
    margin-right: 20px;
  }
`;

const ButtonsContainer = styled.div`
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-end;
`;

const ProfileButton = styled.button`
  background-color: #f0f0f0;
  border: none;
  padding: 5px 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.8rem;
  height: 50px;
  transition: background-color 0.3s;
  width: 80px;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const InfoSection = styled.div`
  margin-top: 20px;
`;

const TagsContainer = styled.div`
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const colors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5'];

const Tag = styled.span`
  background-color: ${(props) => colors[props.$index % colors.length]};
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.7rem;
  color: #fff;
  opacity: 0.8;
`;

const UserDescription = styled.p`
  margin-top: 10px;
  font-size: 0.8rem;
  color: #666;
  white-space: pre-wrap;
`;

const Pet = ({ pet, auth: { user }, likePet, unlikePet, userProfile }) => {
  const isOwner = pet?.user?._id === user?._id;
  const [instructionVisible, setInstructionVisible] = useState(false);
  const handleOnLike = () => {
    if (pet === null) return;
    if (pet.isLiked) {
      unlikePet(pet._id);
    } else {
      likePet(pet._id);
    }
  };
  return (
    <>
      <InstructionTutor
        forceOpen={instructionVisible}
        onOkCallBack={() => setInstructionVisible(false)}
        title={'崽崽的小互动'}
        text={'这里是崽崽的小互动，可以和其他崽崽互动哦~'}
        videoUrl="https://d1ijtmc6ifbh83.cloudfront.net/public/public_video/小互动.mp4"
      />
      {pet === null ? (
        <PetContainer>
          <AvatarZoom src={null} alt={'avatar is loading'} size={100} />
          <Spinner />
        </PetContainer>
      ) : (
        <PetContainer>
          <Header>
            <AvatarContainer>
              <AvatarZoom src={pet.avatar} isUser={false} alt={'pet avatar'} size={100} />
            </AvatarContainer>
            <ButtonsContainer>
              {isOwner ? (
                <>
                  <LikeButton
                    isLiked={pet.isLiked}
                    likeNum={pet.profileLikes}
                    onLike={handleOnLike}
                    onUnlike={handleOnLike}
                    LikeContainerComponent={ProfileButton}
                    fontSize="0.8"
                    text={'蹭蹭  '}
                  />
                  <ProfileButton>好友数量 {userProfile?.friendCount}</ProfileButton>
                  <ProfileButton onClick={() => setInstructionVisible(true)}>
                    小互动教程
                  </ProfileButton>
                  <CheckinButton RequestContainer={ProfileButton} />
                </>
              ) : (
                <>
                  <LikeButton
                    isLiked={pet.isLiked}
                    likeNum={pet.profileLikes}
                    onLike={handleOnLike}
                    onUnlike={handleOnLike}
                    LikeContainerComponent={ProfileButton}
                    fontSize="0.8"
                    text={'蹭蹭  '}
                  />
                  <FriendButton use2Id={pet.user._id} CustomButton={ProfileButton} />
                  <InteractionRequestButton
                    receiverId={pet?.user?._id}
                    RequestContainer={ProfileButton}
                  />
                  <AdventureRequestButton
                    receiverId={pet?.user?._id}
                    RequestContainer={ProfileButton}
                  />
                </>
              )}
            </ButtonsContainer>
          </Header>
          <InfoSection>
            <PetInfo>
              <PetName>
                {pet.name}{' '}
                {!isOwner && (
                  <>
                    <Level currentExp={pet.activityIndex} isOwner={!isOwner} />
                  </>
                )}{' '}
              </PetName>
              {isOwner && (
                <>
                  <Level currentExp={pet.activityIndex} isOwner={isOwner} />
                </>
              )}
              <PetDetails>
                <div>物种: {pet.species}</div>
                <div>职业: {pet.profession}</div>
                <div>MBTI: {pet.mbti}</div>
              </PetDetails>
            </PetInfo>
            <TagsContainer>
              {pet.tags.map((tag, index) => (
                <Tag key={index} $index={index}>
                  {tag}
                </Tag>
              ))}
            </TagsContainer>
            {isOwner ? (
              <>
                {/**崽崽的创建时间不到24小时不显示 description*/}
                <UserDescription>
                  {new Date() - new Date(pet.createdAt) > 86400000
                    ? pet.description
                    : '正在初始化崽崽的的介绍哦~'}
                </UserDescription>
              </>
            ) : (
              <UserDescription>
                {new Date() - new Date(pet.createdAt) > 86400000
                  ? pet.description
                  : '正在初始化崽崽的的介绍哦~'}
              </UserDescription>
            )}
          </InfoSection>
          <PetContainerBorder />
        </PetContainer>
      )}
    </>
  );
};

Pet.propTypes = {
  pet: PropTypes.object,
  auth: PropTypes.object.isRequired,
  likePet: PropTypes.func.isRequired,
  unlikePet: PropTypes.func.isRequired,
  userProfile: PropTypes.object,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { likePet, unlikePet })(Pet);
