import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// A switch to toggle between posts and comments
// border on left side of the active button comment list
const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  position: relative;
  border-bottom: 2px solid #ddd;
  width: 80%;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ToggleButton = styled.button`
  padding: 10px 20px;
  border: none;
  background: transparent;
  cursor: pointer;
  color: ${(props) => (props.$active ? '#000' : '#999')};
  font-weight: bold;
  outline: none;
  font-size: 1rem;
  transition: color 0.3s ease-in-out;

  &:hover {
    color: #000;
  }
  &:focus {
    color: #000;
  }
`;

const Slider = styled.div`
  width: 50%;
  height: 2px;
  background-color: #000;
  transition: transform 0.3s ease-in-out;
  transform: ${(props) => (props.$showPosts ? 'translateX(50%)' : 'translateX(-50%)')};
`;

const Switch = ({ showPosts, onSetPosts, onSetComments }) => {
  return (
    <>
      <ToggleContainer>
        <ButtonsContainer>
          <ToggleButton
            $active={!showPosts ? true : false}
            className={!showPosts ? 'active' : ''}
            onClick={onSetComments}
          >
            评论列表
          </ToggleButton>
          <ToggleButton
            $active={showPosts ? true : false}
            className={showPosts ? 'active' : ''}
            onClick={onSetPosts}
          >
            微博列表
          </ToggleButton>
        </ButtonsContainer>
        <Slider $showPosts={showPosts} />
      </ToggleContainer>
    </>
  );
};

Switch.propTypes = {
  showPosts: PropTypes.bool.isRequired,
  onSetPosts: PropTypes.func.isRequired,
  onSetComments: PropTypes.func.isRequired,
};

export default Switch;
